import React from "react";
import queryString from "querystring";
import htmlParse from "html-react-parser";
import mergeImages from "merge-images";
import Canvas from "canvas";
import Resizer from "react-image-file-resizer";
import { LogTypesEnum } from "../common/constants";
import { toast } from "react-toastify";
import { asyncActionError } from "../../modules/App/actions";
import publicIp from "public-ip";
import { DEFAULT_VIDEO_CONSTRAINTS } from "../../components/Devices";
import { v4 as uuidv4 } from "uuid";
import { differenceInCalendarDays } from "date-fns";
// import { addAnalyticsEvent, addAnalyticsEventInner } from "../../modules/Events/actions";
// import { renderToStaticMarkup } from "react-dom/server";

export function parser(html, className = "", options) {
  return htmlParse(`<div ${className ? `class="${className}"` : ""}>${html}</div>`, options);
}

export function getFileName(fileName) {
  return fileName.substring(0, fileName.lastIndexOf("."));
}

export function getFileType(fileName) {
  return getFileExtension(fileName);
}

export function getFileExtension(filename) {
  return filename.slice(((filename.lastIndexOf(".") - 1) >>> 0) + 2);
}

export const getUrlParams = () => {
  const search = window.location.href.split("?")[1];
  let params = queryString.parse(search);
  // for (let key in params) {
  //   params[key] = decodeURIComponent(params[key]);
  // }
  return params;
};

export const getVideoSnapshot = (url, videoFile) => {
  return new Promise((resolve) => {
    let video = document.createElement("video");
    let _CANVAS = document.createElement("canvas");
    let _CANVAS_CTX = _CANVAS.getContext("2d");
    let vidUrl = URL.createObjectURL(videoFile);

    video.addEventListener(
      "loadedmetadata",
      function () {
        // retrieve dimensions
        let height = this.videoHeight;
        let width = this.videoWidth;
        // send back result
        _CANVAS.width = width;
        _CANVAS.height = height;

        let img = new Image();

        img.onload = () => {
          _CANVAS_CTX.drawImage(img, 0, 0, width, height);
          _CANVAS.toBlob(
            (blob) => {
              resolve(blob);
              URL.revokeObjectURL(vidUrl);
            },
            "image/png",
            0.6
          );
        };

        img.src = url;
      },
      false
    );

    // start download meta-datas
    video.src = vidUrl;
  });
};

export const base64toBlob = (base64, contentType = "", sliceSize = 512) => {
  const byteCharacters = atob(base64);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export const createEventFileStructureByUrl = (url, eventId, tags = [], boothId = "") => {
  let fileName = url.split("?").shift();
  fileName = decodeURIComponent(fileName);
  fileName = fileName.split("/").pop();
  let file = {
    url,
    fileName,
    title: getFileName(fileName),
    fileType: getFileType(fileName),
    tags,
  };
  if (fileName.includes(".mp4") || fileName.includes(".webm") || fileName.includes(".m4v")) {
    let _urls = url.split("?");
    let first = decodeURIComponent(_urls[0]);
    let last = _urls[1];

    let newFileName = `${fileName.split(".").shift()}-thumb.jpg`;
    first = first.replace(fileName, newFileName);
    first = first.replace(`public/events/${eventId}/files/${newFileName}`, encodeURIComponent(`public/events/${eventId}/files/${newFileName}`));
    file.thumbnail = `${first}?${last}`;
  }
  if (boothId) file.booth = boothId;

  return file;
};

export const createUserFileStructureByUrl = (user, url, eventId, tags = [], documents = true) => {
  let fileName = url.split("?").shift();
  fileName = decodeURIComponent(fileName);
  fileName = fileName.split("/").pop();
  let file = {
    url,
    fileName,
    title: getFileName(fileName),
    fileType: getFileType(fileName),
    tags,
  };
  if (fileName.includes(".mp4") || fileName.includes(".webm") || fileName.includes(".m4v")) {
    let _urls = url.split("?");
    let first = decodeURIComponent(_urls[0]);
    let last = _urls[1];

    let newFileName = `${fileName.split(".").shift()}-thumb.jpg`;
    first = first.replace(fileName, newFileName);
    first = first.replace(
      `user_files/${user.uid}/${documents ? "documents" : "images"}/${fileName}`,
      encodeURIComponent(`public/events/${eventId}/files/${newFileName}`)
    );
    file.thumbnail = `${first}?${last}`;
  }

  return file;
};

export const splitCamelCase = (word) => {
  if (typeof word !== "string") {
    throw new Error('The "word" parameter must be a string.');
  }
  let words = word.split(/([A-Z][a-z]+)/).filter(function (e) {
    return e;
  });

  return words.join(" ");
  // let output,
  //   i,
  //   l,
  //   capRe = /[A-Z]/;
  // if (typeof word !== "string") {
  //   throw new Error('The "word" parameter must be a string.');
  // }
  // output = [];
  // for (i = 0, l = word.length; i < l; i += 1) {
  //   if (i === 0) {
  //     output.push(word[i].toUpperCase());
  //   } else {
  //     if (i > 0 && capRe.test(word[i])) {
  //       output.push(" ");
  //     }
  //     output.push(word[i]);
  //   }
  // }
  // return output.join("");
};

export const getAvatarName = (name) =>
  name
    .split(" ")
    .map((a) => a.substring(0, 1))
    .join("")
    .substring(0, 2);

export const download = (url, fileName, onFinish) => {
  // return fetch(url, {
  //   method: "GET",
  //   headers: {},
  // })
  //   .then((response) => {
  //     response.arrayBuffer().then(function (buffer) {
  //       const url = window.URL.createObjectURL(new Blob([buffer]));
  //       const link = document.createElement("a");
  //       link.href = url;
  //       link.setAttribute("download", fileName); //or any other extension
  //       document.body.appendChild(link);
  //       link.click();
  //     });
  //   })
  //   .catch((err) => {
  //     console.log(err);
  //   });
  return async (dispatch) => {
    try {
      let response = await fetch(url);

      const reader = response.body.getReader();

      // Step 2: get total length
      const contentLength = +response.headers.get("Content-Length");

      // Step 3: read the data
      let receivedLength = 0; // received that many bytes at the moment
      let chunks = []; // array of received binary chunks (comprises the body)

      let toastId = Date.now().toString();
      toast(
        <div>
          Downloading {fileName}
          <br />
          {getSizeInMb(receivedLength).toFixed(2)} of {getSizeInMb(contentLength).toFixed(2)} MB
        </div>,
        {
          position: "top-right",
          autoClose: false,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          toastId,
        }
      );
      while (true) {
        const { done, value } = await reader.read();

        if (done) {
          break;
        }

        chunks.push(value);
        receivedLength += value.length;

        toast.update(toastId, {
          render: (
            <div>
              Downloading {fileName}
              <br />
              {getSizeInMb(receivedLength).toFixed(2)} of {getSizeInMb(contentLength).toFixed(2)} MB
            </div>
          ),
        });
      }
      toast.update(toastId, {
        render: (
          <div>
            File: {fileName}
            <br />
            Download done.
          </div>
        ),
        type: toast.TYPE.INFO,
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      if (onFinish) {
        onFinish();
      }

      // Step 4: concatenate chunks into single Uint8Array
      let chunksAll = new Uint8Array(receivedLength); // (4.1)
      let position = 0;

      for (let chunk of chunks) {
        chunksAll.set(chunk, position); // (4.2)
        position += chunk.length;
      }

      const downloadUrl = window.URL.createObjectURL(new Blob([chunksAll]));
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", fileName); //or any other extension
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      dispatch(asyncActionError(error, "download"));
    }
  };
};

export const numberWithCommas = (x = 0, fixed = 2) => {
  // return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return Number(toNumberString(x))
    .toFixed(fixed)
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const toNumberString = (val) => {
  // return val.toString().replace(/,/g, '');
  return val.toString().replace(/[^\d.-]*/g, "");
};

export const getDomain = (url, subdomain) => {
  subdomain = subdomain || false;

  url = url.replace(/(https?:\/\/)?(www.)?/i, "");

  if (!subdomain) {
    url = url.split(".");

    url = url.slice(url.length - 2).join(".");
  }

  if (url.indexOf("/") !== -1) {
    if (subdomain) {
      if (url.indexOf(".") > -1) {
        return url.split(".")[0];
      }
    }
    return url.split("/")[0];
  }

  return url;
};

export const getOrigin = () => {
  let origin = window.location.origin;
  if (origin.includes("localhost")) {
    origin = "https://events.360logix.com/";
  }
  return origin;
};

export const getSizeInMb = (val) => {
  return val / 1048576;
};

export const isFirefox = () => {
  let mediaSourceSupport = !!navigator.mediaDevices.getSupportedConstraints().mediaSource;
  let matchData = navigator.userAgent.match(/Mozilla\/(?=.*\d)/);
  let firefoxVersion = 0;
  if (matchData && matchData[1]) {
    firefoxVersion = parseInt(matchData[1], 10);
  }
  return mediaSourceSupport && firefoxVersion >= 52;
};

export const isChrome = () => {
  if (window["chrome"]) return true;
  else return false;
};

export const canScreenShare = () => {
  return isFirefox() || isChrome();
};

export const getDeviceInfo = async () => {
  // await navigator.mediaDevices.getUserMedia({ audio: true, video: true });
  try {
    await navigator.mediaDevices.getUserMedia({ audio: true, video: DEFAULT_VIDEO_CONSTRAINTS });
    let devices = await navigator.mediaDevices.enumerateDevices();
    console.log("devices", devices);

    return {
      audioInputDevices: devices.filter((device) => device.kind === "audioinput"),
      videoInputDevices: devices.filter((device) => device.kind === "videoinput"),
      audioOutputDevices: devices.filter((device) => device.kind === "audiooutput"),
      hasAudioInputDevices: devices.some((device) => device.kind === "audioinput"),
      hasVideoInputDevices: devices.some((device) => device.kind === "videoinput"),
    };
  } catch (error) {
    console.log("getDeviceInfo error", error);

    return {
      audioInputDevices: [],
      videoInputDevices: [],
      audioOutputDevices: [],
      hasAudioInputDevices: [],
      hasVideoInputDevices: [],
    };
  }
};

export const fieldSorter = (fields) => {
  return (a, b) => {
    return fields
      .map((o) => {
        let dir = 1;
        if (o[0] === "-") {
          dir = -1;
          o = o.substring(1);
        }
        if (a[o] > b[o]) return dir;
        if (a[o] < b[o]) return -dir;
        return 0;
      })
      .reduce(function firstNonZeroValue(p, n) {
        return p ? p : n;
      }, 0);
  };
};

export const getThumbnail = (file, maxWidth = 150, maxHeight = 150, quality = 30) => {
  // return new Promise((resolve, reject) => {
  //   let img = new Image();
  //   img.onload = async function () {
  //     try {
  //       console.log("asdasd");
  //       let b64 = await mergeImages([imgUrl], {
  //         Canvas: Canvas.Canvas,
  //         Image: Canvas.Image,
  //         height: this.width * 0.1,
  //         width: this.height * 0.1,
  //         crossOrigin: "Anonymous",
  //         format: "image/jpeg",
  //         quality: 0.1,
  //       });
  //       let res = await fetch(b64);
  //       let blob = await res.blob();
  //       console.log(blob);
  //       console.log("fileName", fileName);
  //       blob.name = `IMG_${Date.now()}-thumb.jpg`;

  //       resolve({
  //         blob,
  //         base64: b64,
  //       });
  //     } catch (error) {
  //       console.log("getThumbnail error", error);
  //       reject(null);
  //     }
  //   };
  //   img.src = imgUrl;
  // });
  return new Promise(async (resolve, reject) => {
    try {
      if (typeof file === "string") {
        let res = await fetch(file);
        let buffer = await res.arrayBuffer();
        let fileName = "";
        let split = file.split("?")[0];
        fileName = split.split("/").pop();
        console.log("fileName", fileName);
        file = new File([buffer], fileName, { type: "image/jpeg" });
      }
      Resizer.imageFileResizer(
        file,
        maxWidth,
        maxHeight,
        "JPEG",
        quality,
        0,
        (uri) => {
          console.log("uri", uri);
          resolve(uri);
        },
        "file"
      );
    } catch (error) {
      console.log("getThumbnail error", error);
      reject(null);
    }
  });
};

export const sanitizeUrl = (url = "") => {
  if (url.includes("firebasestorage")) {
    let storageBase = "https://storage.googleapis.com/u-studio-a969d.appspot.com/";
    url = decodeURIComponent(url);
    url = url.replace("https://firebasestorage.googleapis.com/v0/b/u-studio-a969d.appspot.com/o/", storageBase);
  }
  return url;
};

export const isAnalyticsUniqueEntry = (currentIp, uid, logType = "", value = "") => {
  let key = `${currentIp}:${uid}`;
  let identity = localStorage.getItem(key);
  try {
    if (identity) {
      identity = JSON.parse(identity);
    } else {
      identity = {
        event: false,
        lobby: false,
        plenary: false,
        expo: false,
        booths: [],
        rooms: [],
        networkingRooms: [],
      };
    }
  } catch (error) {
    console.log("isAnalyticsUniqueEntry error", error);
    identity = {
      event: false,
      lobby: false,
      plenary: false,
      expo: false,
      booths: [],
      rooms: [],
      networkingRooms: [],
    };
  }

  let returnValue = false;
  switch (logType) {
    case LogTypesEnum.EVENT: {
      returnValue = !identity[LogTypesEnum.EVENT];
      identity[LogTypesEnum.EVENT] = true;
      break;
    }
    case LogTypesEnum.LOBBY: {
      returnValue = !identity[LogTypesEnum.LOBBY];
      identity[LogTypesEnum.LOBBY] = true;
      break;
    }
    case LogTypesEnum.PLENARY: {
      returnValue = !identity[LogTypesEnum.PLENARY];
      identity[LogTypesEnum.PLENARY] = true;
      break;
    }
    case LogTypesEnum.EXPO: {
      returnValue = !identity[LogTypesEnum.EXPO];
      identity[LogTypesEnum.EXPO] = true;
      break;
    }
    case LogTypesEnum.BOOTHS: {
      returnValue = !identity[LogTypesEnum.BOOTHS].includes(value);
      identity[LogTypesEnum.BOOTHS].push(value);
      break;
    }
    case LogTypesEnum.ROOMS: {
      returnValue = !identity[LogTypesEnum.ROOMS].includes(value);
      identity[LogTypesEnum.ROOMS].push(value);
      break;
    }
    case LogTypesEnum.NETWORKING_ROOMS: {
      returnValue = !identity[LogTypesEnum.NETWORKING_ROOMS].includes(value);
      identity[LogTypesEnum.NETWORKING_ROOMS].push(value);
      break;
    }
    default:
      returnValue = false;
      break;
  }
  localStorage.setItem(key, JSON.stringify(identity));
  return returnValue;
};

export const getDeviceId = () => {
  let deviceId = localStorage.getItem("deviceId");
  if (!deviceId) {
    deviceId = uuidv4();
    localStorage.setItem("deviceId", deviceId);
    console.log("deviceId", deviceId);
  }
  return deviceId;
};

export const getCurrentIp = async () => {
  let currentIp = localStorage.getItem("currentIp");
  if (!currentIp) {
    try {
      currentIp = await publicIp.v4({
        fallbackUrls: ["https://ifconfig.co/ip"],
      });
      localStorage.setItem("currentIp", currentIp);
      console.log("currentIp", currentIp);
    } catch (error) {
      currentIp = "";
      localStorage.setItem("currentIp", currentIp);
      console.log("getCurrentIp error", error);
    }
  }
  return currentIp;
};

export const getFileNameFromUrl = (url = "") => {
  let splits = url.split("?");
  url = splits[0];
  let fileName = url.substring(url.lastIndexOf("/") + 1);
  fileName = decodeURIComponent(fileName);
  fileName = fileName.split("/").pop();
  return fileName;
};

export const convertStylesStringToObject = (stringStyles) =>
  typeof stringStyles === "string"
    ? stringStyles.split(";").reduce((acc, style) => {
        const colonPosition = style.indexOf(":");

        if (colonPosition === -1) {
          return acc;
        }

        const camelCaseProperty = style
            .substr(0, colonPosition)
            .trim()
            .replace(/^-ms-/, "ms-")
            .replace(/-./g, (c) => c.substr(1).toUpperCase()),
          value = style.substr(colonPosition + 1).trim();

        return value ? { ...acc, [camelCaseProperty]: value } : acc;
      }, {})
    : {};

export const parseStyle = (value) => {
  let newValue = convertStylesStringToObject(value);
  console.log("parseStyle", newValue);
  return newValue;
};

export const byString = function (o, s) {
  s = s.replace(/\[(\w+)\]/g, ".$1"); // convert indexes to properties
  s = s.replace(/^\./, ""); // strip a leading dot
  var a = s.split(".");
  for (var i = 0, n = a.length; i < n; ++i) {
    var k = a[i];
    if (k in o) {
      o = o[k];
    } else {
      return;
    }
  }
  return o;
};

export const getFormattedDate = (date) => {
  let month = (new Date(date).getMonth() + 1).toString().padStart(2, "0");
  let year = new Date(date).getFullYear().toString();
  return `${month}-${year}`;
};

export const shuffle = (array) => {
  let currentIndex = array.length,
    temporaryValue,
    randomIndex;

  while (0 !== currentIndex) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
};

export const getDaysInWords = (date, currentDate = new Date()) => {
  let text = "";
  let days = differenceInCalendarDays(date, currentDate);
  if (days < 1) {
    text = "a couple of hours";
  } else {
    text = `${days} ${days === 1 ? "day" : "days"}`;
  }
  return text;
};

export const generateCode = () => {
  // return nanoid;
  return Math.floor(100000 + Math.random() * 900000).toString();
};

export const chunkify = (a, n, balanced) => {
  if (n < 2) return [a];

  var len = a.length,
    out = [],
    i = 0,
    size;

  if (len % n === 0) {
    size = Math.floor(len / n);
    while (i < len) {
      out.push(a.slice(i, (i += size)));
    }
  } else if (balanced) {
    while (i < len) {
      size = Math.ceil((len - i) / n--);
      out.push(a.slice(i, (i += size)));
    }
  } else {
    n--;
    size = Math.floor(len / n);
    if (len % size === 0) size--;
    while (i < size * n) {
      out.push(a.slice(i, (i += size)));
    }
    out.push(a.slice(size * n));
  }

  return out;
};
