export const HOME = "/";
export const DASHBOARD = "/dashboard";
export const SIGN_UP = "/sign-up";
export const SIGN_IN = "/sign-in";
export const FORGOT_PASSWORD = "/forgot-password";

export const ACCOUNT = "/account";
export const PROFILE = "/profile";
export const ORGANIZATION = "/organization";
export const EVENTS = "/events";
export const LIVE = "/live";
export const DEMO = "/demo";
export const NEW = "/new";
export const EDIT = "/edit";
export const OVERVIEW = "/overview";
export const ORGANIZERS = "/organizers";
export const LOBBY = "/lobby";
export const PLENARY = "/plenary";
export const ARCADE = "/arcade";
export const NETWORKING_ROOMS = "/networking-rooms";
export const BOOTHS = "/booths";
export const BACKSTAGE = "/backstage";
export const PRESENCE = "/presence";
export const EXPO = "/expo";
export const ROOMS = "/rooms";
export const PREVIEW = "/preview";
export const PASSPORT = "/passport";
export const TICKETS = "/tickets";
export const REGISTRATION_DETAILS = "/registration-details";
export const ATTENDEES = "/attendees";
export const COMPANION = "/companion";
export const RAFFLE = "/raffle";
export const FILTERS = "/filters";
export const PRIZES = "/prizes";
export const BULK_IMPORT_ATTENDEES = "/bulk-import-attendees";
export const PAY = "/pay";
export const JOIN_SUCCESS = "/join-success";
export const FAQS = "/faqs";
export const SURVEYS = "/surveys";
export const EMAIL_TEMPLATES = "/email-templates";
export const TEAM = "/team";

export const CHAT = "/chat";
export const VIDEOCALL = "/video-call";

export const TERMS = "/terms";
export const PRIVACY = "/privacy";

export const SANDBOX = "/sandbox";

export const VIDEO_MEETING = "/networking-rooms/:roomId";

// public
export const DOWNLOAD_ATTENDEES = "/download-attendee-list";
