import fileSaver from "file-saverjs";
import Excel from "exceljs";
import { format } from "date-fns";
import { splitCamelCase } from ".";
import _ from "lodash";
import { getFirebase } from "./Firestore";

export const ExportTypesEnum = Object.freeze({
  Attendees: "Attendees",
  BoothChat: "BoothChat",
  BoothSurvey: "BoothSurvey",
  BoothVisitors: "BoothVisitors",
  BoothAnalytics: "BoothAnalytics",
  RafflePool: "RafflePool",
});

export const getExportTypes = () => {
  return Object.keys(ExportTypesEnum);
};

export const exportToExcel = async (type, data) => {
  let alphabets = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  let workbook = new Excel.Workbook();
  let fileName = "";

  switch (type) {
    case ExportTypesEnum.Attendees: {
      fileName = "attendees";

      // let _worksheetNames = [...new Set(data?.map((a) => a.ticket.name))];
      // _worksheetNames.forEach((worksheetName) => workbook.addWorksheet(worksheetName));
      let _worksheetNames = [...data.tickets];
      // _worksheetNames.forEach((worksheetName) => workbook.addWorksheet(worksheetName));
      _worksheetNames.forEach((tick) => workbook.addWorksheet(tick.name));
      console.log("_worksheetNames", _worksheetNames);

      let companionWorksheet = workbook.addWorksheet("Companion");
      companionWorksheet.columns = [
        { header: "Employee Name", key: "employeeName", width: 30 },
        { header: "First Name", key: "firstName", width: 15 },
        { header: "Last Name", key: "lastName", width: 15 },
        { header: "Age", key: "age", width: 15 },
        { header: "Affiliation", key: "affiliation", width: 20 },
        { header: "Others", key: "affiliationOthers", width: 20 },
      ];
      // let companion = [];

      for (let i = 0; i < _worksheetNames.length; i++) {
        let worksheet = workbook.worksheets[i];
        let _data = data.attendees?.filter((a) => a.ticket.id === _worksheetNames[i].id);
        let columns = [
          { header: "First Name", key: "firstName", width: 15 },
          { header: "Last Name", key: "lastName", width: 15 },
          { header: "Email", key: "email", width: 30 },
          { header: "Employee Number", key: "employeeNumber", width: 30 },
        ];

        const deselectFields = [
          "displayName",
          "firstName",
          "lastName",
          "photoURL",
          "email",
          "ticket",
          "updatedAt",
          "uid",
          "country",
          "id",
          "approved",
          "paid",
          "passportComplete",
          "deleted",
          "customFields",
        ];

        let selectedFields = Object.keys(_data[0] || {}).filter((a) => !deselectFields.includes(a));
        selectedFields.sort();
        selectedFields.forEach((_s) => columns.push({ header: splitCamelCase(_s), key: _s, width: 20 }));

        // start customFields
        // let customFields = _data[0]["customFields"];
        // console.log("customFields", customFields);
        // let selectedCustomFields = [];
        // for (const property in customFields) {
        //   console.log(`${property}: ${customFields[property]}`);
        //   let condition1 = !property.toLowerCase().includes("first") || !property.toLowerCase().includes("name");
        //   let condition2 = !property.toLowerCase().includes("last") || !property.toLowerCase().includes("name");
        //   if (condition1 && condition2 && !property.toLowerCase().includes("companion")) {
        //     selectedCustomFields.push(property);
        //   }
        // }
        // console.log("selectedCustomFields", selectedCustomFields);
        // selectedCustomFields.forEach((_s) => columns.push({ header: splitCamelCase(_s), key: _s, width: 20 }));
        // columns.push({ header: "Companion", key: "companion", width: 20 });
        // end customFields

        // start customFields2
        let customFields = {};
        _worksheetNames[i].customFields.forEach((cf) => {
          let _target = data.registrationDetails.find((rd) => rd.name === cf);
          if (_target) {
            customFields[_target.label] = "";
          }
        });
        console.log("customFields", customFields);
        let selectedCustomFields = [];
        for (const property in customFields) {
          let condition1 = !property.toLowerCase().includes("first") || !property.toLowerCase().includes("name");
          let condition2 = !property.toLowerCase().includes("last") || !property.toLowerCase().includes("name");
          if (condition1 && condition2 && !property.toLowerCase().includes("companion")) {
            selectedCustomFields.push(property);
          }
        }

        console.log("selectedCustomFields", selectedCustomFields);
        selectedCustomFields.forEach((_s) => columns.push({ header: splitCamelCase(_s), key: _s, width: 20 }));
        columns.push({ header: "Companion", key: "companion", width: 20 });
        // end customFields2

        worksheet.columns = columns;

        _data.forEach((attendee) => {
          if (attendee.createdAt)
            attendee.createdAt = format(
              attendee.createdAt?._seconds ? new Date(attendee.createdAt._seconds * 1000) : new Date(attendee.createdAt),
              "MMMM d, yyyy h:mm a"
            );
          if (attendee.lastLogin)
            attendee.lastLogin = format(
              attendee.lastLogin?._seconds ? new Date(attendee.lastLogin._seconds + 1000) : new Date(attendee.lastLogin),
              "MMMM d, yyyy h:mm a"
            );
          let row = _.pick(attendee, [...columns.map((c) => c.key), ...selectedFields]);
          row.firstName = row.firstName.toUpperCase();
          row.lastName = row.lastName.toUpperCase();
          selectedCustomFields.forEach((_s) => {
            row[_s] = attendee?.customFields[_s]?.toString().toUpperCase();
          });

          if (attendee?.customFields?.Companion && attendee?.customFields?.Companion.length > 0) {
            row.companion = { formula: `=HYPERLINK("#Companion!A${companionWorksheet.rowCount + 1}", "VIEW")`, result: "VIEW" };
            attendee?.customFields?.Companion.forEach((c, cI) => {
              let obj = {
                employeeName: cI === 0 ? (attendee.firstName + " " + attendee.lastName).toUpperCase() : "",
                firstName: c["First Name"]?.toString().toUpperCase(),
                lastName: c["Last Name"]?.toString().toUpperCase(),
                age: c["Age"],
                affiliation: c["Affiliation"]?.toString().toUpperCase(),
                affiliationOthers: c["Affiliation Others"]?.toString().toUpperCase(),
              };
              companionWorksheet.addRow(obj);
            });
            worksheet.addRow(row);
          } else {
            worksheet.addRow(row);
          }
        });

        worksheet.autoFilter = `A1:${alphabets[columns.length - 1]}${worksheet.rowCount}`;
        companionWorksheet.autoFilter = `A1:${alphabets[companionWorksheet.columns.length - 1]}${companionWorksheet.rowCount}`;
      }

      break;
    }
    case ExportTypesEnum.BoothChat: {
      fileName = `Chats - ${data.booth}`;

      let _worksheetNames = [...data.messages.map((a) => a.displayName)];

      for (let i = 0; i < _worksheetNames.length; i++) {
        let worksheet = workbook.addWorksheet(_worksheetNames[i]);
        let _data = data.messages[i];
        let columns = [
          { header: "Sender", key: "sender", width: 20 },
          { header: "Date/Time", key: "dateTime", width: 15 },
          { header: "Message", key: "message", width: 30 },
        ];

        worksheet.columns = columns;

        _data.messages.forEach((innerM) => {
          if (innerM.createdAt) innerM.createdAt = format(new Date(innerM.createdAt), "MMMM d, yyyy h:mm a");
          worksheet.addRow({
            sender: innerM.booth ? data.booth : _data.displayName,
            dateTime: innerM.createdAt,
            message: innerM.message,
          });
        });

        worksheet.autoFilter = `A1:${alphabets[columns.length - 1]}${worksheet.rowCount}`;
      }

      break;
    }
    case ExportTypesEnum.BoothSurvey: {
      fileName = `Surveys - ${data.booth}`;

      let worksheet = workbook.addWorksheet("Survey");
      let columns = [
        { header: "Question", key: "question", width: 50 },
        { header: "Answers", key: "answers", width: 50 },
        { header: "Total", key: "total", width: 15 },
      ];

      worksheet.columns = columns;

      Object.keys(data.questions).forEach((key) => {
        if (data.questions[key].answer) {
          let _data = data.questions[key].answer;
          for (let i = 0; i < _data.length; i++) {
            let row = {
              answers: _data[i],
            };
            if (i === 0) row.question = data.questions[key].title;
            worksheet.addRow(row);
          }
        } else if (data.questions[key].answers) {
          Object.keys(data.questions[key].answers).forEach((key2, i) => {
            let row = {
              answers: data.questions[key].answers[key2].text,
              total: data.questions[key].answers[key2].total,
            };
            if (i === 0) row.question = data.questions[key].title;
            worksheet.addRow(row);
          });
        }
        worksheet.addRow({});
      });

      worksheet.autoFilter = `A1:${alphabets[columns.length - 1]}${worksheet.rowCount}`;

      break;
    }
    case ExportTypesEnum.BoothVisitors: {
      fileName = `Visitors - ${data.booth}`;

      let worksheet = workbook.addWorksheet("Visitors");

      let columns = [
        { header: "Name", key: "displayName", width: 40 },
        { header: "Email", key: "email", width: 40 },
        { header: "Contact", key: "contactNumber", width: 20 },
        { header: "Society Affiliation", key: "society", width: 15 },
        { header: "Initial Visit", key: "createdAt", width: 30 },
      ];

      worksheet.columns = columns;

      data.visitors.forEach((visitor) => {
        if (visitor.createdAt) visitor.createdAt = format(new Date(visitor.createdAt), "MMMM d, yyyy h:mm a");
        worksheet.addRow({
          displayName: visitor.displayName,
          email: visitor.email,
          contactNumber: visitor.contactNumber || "",
          society: visitor.society || "",
          createdAt: visitor.createdAt,
        });
      });

      worksheet.autoFilter = `A1:${alphabets[columns.length - 1]}${worksheet.rowCount}`;

      break;
    }
    case ExportTypesEnum.BoothAnalytics: {
      fileName = `Analytics - ${data.booth}`;
      let firebase = getFirebase();

      let keys = Object.keys(data.analytics).sort((a, b) => {
        if (a < b) return 1;
        if (a > b) return -1;
        return 0;
      });

      keys.forEach((key) => {
        switch (key) {
          case "visits": {
            let worksheet = workbook.addWorksheet(splitCamelCase(key));

            worksheet.addRow(["", "", "", "", "Total Unique Visits", data.analytics[key].filter((a) => a.unique).length, "Total Visits", data.analytics[key].length]);

            let columns = [
              { header: "Name", key: "displayName", width: 40 },
              { header: "Email", key: "email", width: 40 },
              { header: "IP Address", key: "ip", width: 40 },
              { header: "Date/Time", key: "createdAt", width: 30 },
            ];

            worksheet.columns = columns;

            data.analytics[key].sort((a, b) => {
              let aa = a.createdAt instanceof firebase.firestore.Timestamp ? a.createdAt.toDate() : new Date(a.createdAt);
              let bb = b.createdAt instanceof firebase.firestore.Timestamp ? b.createdAt.toDate() : new Date(b.createdAt);
              if (aa > bb) return 1;
              if (aa < bb) return -1;
              return 0;
            });
            data.analytics[key].forEach((row) => {
              if (row.createdAt)
                row.createdAt = format(row.createdAt instanceof firebase.firestore.Timestamp ? row.createdAt.toDate() : new Date(row.createdAt), "MMMM d, yyyy h:mm a");

              worksheet.addRow({
                displayName: row.displayName,
                email: row.email || "",
                ip: row.ip,
                createdAt: row.createdAt,
              });
            });

            worksheet.autoFilter = `A1:${alphabets[columns.length - 1]}${worksheet.rowCount}`;
            break;
          }
          case "videoCalls": {
            let worksheet = workbook.addWorksheet(splitCamelCase(key));

            worksheet.addRow(["", "", "", "", "Total Visits", data.analytics[key].length]);

            let columns = [
              { header: "Name", key: "displayName", width: 40 },
              { header: "Email", key: "email", width: 40 },
              { header: "IP Address", key: "ip", width: 40 },
              { header: "Date/Time", key: "createdAt", width: 30 },
            ];

            worksheet.columns = columns;

            data.analytics[key].sort((a, b) => {
              let aa = a.createdAt instanceof firebase.firestore.Timestamp ? a.createdAt.toDate() : new Date(a.createdAt);
              let bb = b.createdAt instanceof firebase.firestore.Timestamp ? b.createdAt.toDate() : new Date(b.createdAt);
              if (aa > bb) return 1;
              if (aa < bb) return -1;
              return 0;
            });
            data.analytics[key].forEach((row) => {
              if (row.createdAt)
                row.createdAt = format(row.createdAt instanceof firebase.firestore.Timestamp ? row.createdAt.toDate() : new Date(row.createdAt), "MMMM d, yyyy h:mm a");

              worksheet.addRow({
                displayName: row.displayName,
                email: row.email || "",
                ip: row.ip,
                createdAt: row.createdAt,
              });
            });

            worksheet.autoFilter = `A1:${alphabets[columns.length - 1]}${worksheet.rowCount}`;
            break;
          }

          case "viewImages": {
            let files = {};
            data.analytics[key].forEach((a) => {
              if (files[a.file?.fileName]) {
                files[a.file?.fileName].push(a);
              } else {
                files[a.file?.fileName] = [a];
              }
            });

            let names = Object.keys(files);

            names.forEach((name, nameI) => {
              let worksheet = workbook.addWorksheet(splitCamelCase(key) + `-${nameI}-${name}`);
              worksheet.properties.defaultColWidth = 0;

              worksheet.addRow(["", "", "", "", "", "Total Views", files[name].length]);

              let columns = [
                { header: "Name", key: "displayName", width: 40 },
                { header: "Email", key: "email", width: 40 },
                { header: "IP Address", key: "ip", width: 40 },
                { header: "Date/Time", key: "createdAt", width: 50 },
                { header: "File Name", key: "fileName", width: 50 },
              ];

              worksheet.columns = columns;

              files[name].sort((a, b) => {
                let aa = a.createdAt instanceof firebase.firestore.Timestamp ? a.createdAt.toDate() : new Date(a.createdAt);
                let bb = b.createdAt instanceof firebase.firestore.Timestamp ? b.createdAt.toDate() : new Date(b.createdAt);
                if (aa > bb) return 1;
                if (aa < bb) return -1;
                return 0;
              });
              files[name].forEach((row) => {
                console.log("row", row);
                if (row.createdAt)
                  row.createdAt = format(row.createdAt instanceof firebase.firestore.Timestamp ? row.createdAt.toDate() : new Date(row.createdAt), "MMMM d, yyyy h:mm a");

                worksheet.addRow({
                  displayName: row.displayName,
                  email: row.email,
                  ip: row.ip,
                  createdAt: row.createdAt,
                  fileName: name,
                });
              });

              worksheet.autoFilter = `A1:${alphabets[columns.length - 1]}${worksheet.rowCount}`;
            });

            break;
          }

          case "viewVideos": {
            let files = {};
            data.analytics[key].forEach((a) => {
              if (files[a.file?.fileName]) {
                files[a.file?.fileName].push(a);
              } else {
                files[a.file?.fileName] = [a];
              }
            });

            let names = Object.keys(files);

            names.forEach((name, nameI) => {
              let worksheet = workbook.addWorksheet(splitCamelCase(key) + `-${nameI}-${name}`);
              worksheet.properties.defaultColWidth = 0;

              worksheet.addRow(["", "", "", "", "", "Total Views", files[name].length]);

              let columns = [
                { header: "Name", key: "displayName", width: 40 },
                { header: "Email", key: "email", width: 40 },
                { header: "IP Address", key: "ip", width: 40 },
                { header: "Date/Time", key: "createdAt", width: 50 },
                { header: "File Name", key: "fileName", width: 50 },
              ];

              worksheet.columns = columns;

              files[name].sort((a, b) => {
                let aa = a.createdAt instanceof firebase.firestore.Timestamp ? a.createdAt.toDate() : new Date(a.createdAt);
                let bb = b.createdAt instanceof firebase.firestore.Timestamp ? b.createdAt.toDate() : new Date(b.createdAt);
                if (aa > bb) return 1;
                if (aa < bb) return -1;
                return 0;
              });
              files[name].forEach((row) => {
                console.log("row", row);
                if (row.createdAt)
                  row.createdAt = format(row.createdAt instanceof firebase.firestore.Timestamp ? row.createdAt.toDate() : new Date(row.createdAt), "MMMM d, yyyy h:mm a");

                worksheet.addRow({
                  displayName: row.displayName,
                  email: row.email,
                  ip: row.ip,
                  createdAt: row.createdAt,
                  fileName: name,
                });
              });

              worksheet.autoFilter = `A1:${alphabets[columns.length - 1]}${worksheet.rowCount}`;
            });

            break;
          }

          case "downloads": {
            let files = {};
            data.analytics[key].forEach((a) => {
              if (files[a.file?.fileName]) {
                files[a.file?.fileName].push(a);
              } else {
                files[a.file?.fileName] = [a];
              }
            });

            let names = Object.keys(files);

            names.forEach((name, nameI) => {
              let worksheet = workbook.addWorksheet(splitCamelCase(key) + `-${nameI}-${name}`);
              worksheet.properties.defaultColWidth = 0;

              worksheet.addRow(["", "", "", "", "", "Total Downloads", files[name].length]);

              let columns = [
                { header: "Name", key: "displayName", width: 40 },
                { header: "Email", key: "email", width: 40 },
                { header: "IP Address", key: "ip", width: 40 },
                { header: "Date/Time", key: "createdAt", width: 50 },
                { header: "File Name", key: "fileName", width: 50 },
              ];

              worksheet.columns = columns;

              files[name].sort((a, b) => {
                let aa = a.createdAt instanceof firebase.firestore.Timestamp ? a.createdAt.toDate() : new Date(a.createdAt);
                let bb = b.createdAt instanceof firebase.firestore.Timestamp ? b.createdAt.toDate() : new Date(b.createdAt);
                if (aa > bb) return 1;
                if (aa < bb) return -1;
                return 0;
              });
              files[name].forEach((row) => {
                console.log("row", row);
                if (row.createdAt)
                  row.createdAt = format(row.createdAt instanceof firebase.firestore.Timestamp ? row.createdAt.toDate() : new Date(row.createdAt), "MMMM d, yyyy h:mm a");

                worksheet.addRow({
                  displayName: row.displayName,
                  email: row.email,
                  ip: row.ip,
                  createdAt: row.createdAt,
                  fileName: name,
                });
              });

              worksheet.autoFilter = `A1:${alphabets[columns.length - 1]}${worksheet.rowCount}`;
            });

            break;
          }

          default:
            break;
        }
      });

      break;
    }
    case ExportTypesEnum.RafflePool: {
      let firebase = getFirebase();

      fileName = `Filter - ${data.name}`;

      let worksheet = workbook.addWorksheet("Filter");
      let columns = [];
      if (data.pool[0]) {
        for (let key in data.pool[0]) {
          if (key !== "id") {
            columns.push({ header: splitCamelCase(key), key, width: 30 });
          }
        }
      }

      worksheet.columns = columns;

      data.pool.forEach((a) => {
        let newRow = {};
        for (let key in a) {
          if (key !== "id") {
            if (a[key] instanceof Date) {
              newRow[key] = format(a[key], "MM-dd-yyyy hh:mm:ss a");
            } else if (a[key] instanceof firebase.firestore.Timestamp) {
              newRow[key] = format(a[key].toDate(), "MM-dd-yyyy hh:mm:ss a");
            } else {
              if (key.toLowerCase() === "ticket") {
                newRow[key] = a[key].name;
              } else {
                newRow[key] = a[key];
              }
            }
          }
        }
        worksheet.addRow(newRow);
      });

      worksheet.autoFilter = `A1:${alphabets[columns.length - 1]}${worksheet.rowCount}`;

      break;
    }

    case ExportTypesEnum.RafflePrizeWinners: {
      let firebase = getFirebase();

      fileName = `Prize: ${data.name}`;

      let worksheet = workbook.addWorksheet(`${data.name}`);
      let columns = [];
      if (data.pool[0]) {
        for (let key in data.pool[0]) {
          if (key !== "id" && key !== "customFields") {
            columns.push({ header: splitCamelCase(key), key, width: 30 });
          }
        }
      }

      worksheet.columns = columns;

      data.pool.forEach((a) => {
        let newRow = {};
        for (let key in a) {
          if (key !== "id" && key !== "customFields") {
            if (a[key] instanceof Date) {
              newRow[key] = format(a[key], "MM-dd-yyyy hh:mm:ss a");
            } else if (a[key] instanceof firebase.firestore.Timestamp) {
              newRow[key] = format(a[key].toDate(), "MM-dd-yyyy hh:mm:ss a");
            } else {
              if (key.toLowerCase() === "ticket") {
                newRow[key] = a[key].name;
              } else {
                newRow[key] = a[key];
              }
            }
          }
        }
        worksheet.addRow(newRow);
      });

      worksheet.autoFilter = `A1:${alphabets[columns.length - 1]}${worksheet.rowCount}`;

      break;
    }

    default:
      break;
  }

  fileName += ".xlsx";
  workbook.xlsx.writeBuffer().then(function (buffer) {
    fileSaver(new Blob([buffer], { type: "application/octet-stream" }), fileName);
  });
};
