export const ASYNC_ACTION_START = "ASYNC_ACTION_START";
export const ASYNC_ACTION_FINISH = "ASYNC_ACTION_FINISH";
export const ASYNC_ACTION_ERROR = "ASYNC_ACTION_ERROR";
export const APP_LOADED = "APP_LOADED";
export const SET_SHOW_SIDEBAR = "SET_SHOW_SIDEBAR";
export const SET_SHOW_PROGRESS = "SET_SHOW_PROGRESS";
export const SET_PROGRESS = "SET_PROGRESS";
export const SET_PREVIEW = "SET_PREVIEW";
export const TOGGLE_SHOW_LIVE_SIDEBAR = "TOGGLE_SHOW_LIVE_SIDEBAR";
export const SET_ALERT_DIALOG = "SET_ALERT_DIALOG";
export const TOGGLE_NAVIGATION_LAYOUT = "TOGGLE_NAVIGATION_LAYOUT";
export const SET_APP_THEME = "SET_APP_THEME";
export const SET_APP_SETTINGS = "SET_APP_SETTINGS";
export const SET_APP_METADATA = "SET_APP_METADATA";
export const SET_DIALOG_SHOWN = "SET_DIALOG_SHOWN";
