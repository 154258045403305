import { dataFromSnapshot, getFirebase } from "../../library/utilities/Firestore";
import { asyncActionError, asyncActionFinish, asyncActionStart, setAppLoaded } from "../App/actions";
import * as routes from "../../main/routes/constants";

export function getEventAttendeeByEmailAndCode(email, code) {
  return async (dispatch) => {
    dispatch(asyncActionStart());
    let firebase = getFirebase();
    // firebase.functions().useEmulator("localhost", 5001);
    let myFunction = firebase.functions().httpsCallable("getEventAttendeeByEmailAndCode");
    try {
      let result = await myFunction({ email, code });
      dispatch(asyncActionFinish());
      return result.data;
      // let promises = result.data.map(async (doc) => await dataFromSnapshot(doc));
      // const docs = await Promise.all(promises);
      // return docs;
    } catch (error) {
      dispatch(asyncActionError(error));
      return null;
    }
  };
}
