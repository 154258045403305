import {
  dataFromSnapshot,
  getFirebase,
  // dataFromSnapshot,
  signInWithEmail,
  signInWithPopup,
  signOutFirebase,
  signUpWithEmail,
} from "../../library/utilities/Firestore";
import { asyncActionError, asyncActionFinish, asyncActionStart, setAppLoaded } from "../App/actions";
import { getEventAttendeeByUid, saveAttendee, setTempJoinEvent } from "../Events/actions";
import { getProfile, setProfile, updateProfile, updateUserActivity } from "../User/actions";
import * as authTypes from "./constants";
import * as routes from "../../main/routes/constants";

export const signIn = ({ email, password }, user = null, history, redirect) => {
  return async (dispatch, getState) => {
    dispatch(asyncActionStart());
    try {
      if (user) {
        dispatch({ type: authTypes.SIGN_IN_USER, payload: user });
      } else {
        let result = await signInWithEmail(email, password);
        console.log("result", result);
        dispatch({ type: authTypes.SIGN_IN_USER, payload: result.user });
      }

      dispatch(asyncActionFinish());
      if (history) {
        if (getState().event?.tempJoinEvent?.event) {
          history.replace(routes.EVENTS + getState().event.tempJoinEvent.event.customURL);
        } else if (redirect) {
          history.push(redirect);
        }
      }
    } catch (error) {
      dispatch(asyncActionError(error, "signIn"));
    }
  };
};

let store = null;

export const setStore = (_store) => {
  store = _store;
};

export const signUp = ({ email, password, confirmPassword, ...props }, history, redirect) => {
  return async (dispatch, getState) => {
    dispatch(asyncActionStart());
    try {
      let result = await signUpWithEmail(email, password);
      console.log("result", result);
      let result2 = await dispatch(updateProfile({ ...props, email }));
      console.log("profileResult", result2);
      dispatch({ type: authTypes.SIGN_IN_USER, payload: result.user });

      dispatch(asyncActionFinish());
      if (getState().event?.tempJoinEvent?.event) {
        history.replace(routes.EVENTS + getState().event.tempJoinEvent.event.customURL);
      } else if (redirect) {
        history.push(redirect);
      }
    } catch (error) {
      dispatch(asyncActionError(error, "signUp"));
    }
  };
};

export const signOut = (history, redirect) => {
  return async (dispatch) => {
    dispatch(asyncActionStart());
    try {
      if (store?.getState().user?.profile) {
        await dispatch(updateUserActivity(store.getState().user.profile));
      }

      dispatch({ type: authTypes.SIGN_OUT_USER });
      dispatch(asyncActionFinish());
      await signOutFirebase();
      if (redirect) {
        history.push(redirect);
      }
    } catch (error) {
      dispatch(asyncActionError(error, "signOut"));
    }
  };
};

export function socialLogin(selectedProvider) {
  return async (dispatch) => {
    dispatch(asyncActionStart());
    try {
      let provider;
      if (selectedProvider === "facebook") {
        provider = new getFirebase().auth.FacebookAuthProvider();
      }
      if (selectedProvider === "google") {
        provider = new getFirebase().auth.GoogleAuthProvider();
      }

      let result = await signInWithPopup(provider);
      console.log("result socialLogin", result);
      dispatch({ type: authTypes.SIGN_IN_USER, payload: result.user });
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError(error, "socialLogin"));
    }
  };
}

export function verifyAuth(store, history) {
  return async (dispatch) => {
    let unsubscribe;
    let called = false;
    let called2 = false;
    return getFirebase()
      .auth()
      .onAuthStateChanged(async (user) => {
        if (user) {
          if (user.isAnonymous || (user.email === null && user.emailVerified === false)) {
            dispatch(setAppLoaded());
            dispatch(signOut());
          } else {
            console.log("not anonymous");

            const profileRef = getProfile(user.uid);

            let _prof = await profileRef.get();
            if (_prof.exists) {
              profileRef.update({
                lastLogin: getFirebase().firestore.FieldValue.serverTimestamp(),
              });
            }

            unsubscribe = profileRef.onSnapshot(
              async (snapshot) => {
                let data = await dataFromSnapshot(snapshot);
                console.log("profileData", data);
                setStore(store);
                await dispatch(setProfile(data));
                await dispatch(updateUserActivity(data));
                if (!called) {
                  dispatch(signIn({}, user, history));
                  dispatch(setAppLoaded());
                  called = true;
                }
                let tempJoinEvent = store.getState().event.tempJoinEvent;
                // console.log("tempJoinEvent", tempJoinEvent);
                if (!called2 && tempJoinEvent && data) {
                  called2 = true;
                  let existingTicket = (await getEventAttendeeByUid(tempJoinEvent.event.id, data.id).get()).docs[0]?.data();
                  if (!existingTicket) {
                    let _newAttendee = {
                      uid: data.id,
                      ticket: {
                        id: tempJoinEvent.ticket.id,
                        name: tempJoinEvent.ticket.name,
                        description: tempJoinEvent.ticket.description,
                        price: tempJoinEvent.ticket.price,
                        currency: tempJoinEvent.ticket.currency || "",
                      },
                      firstName: data.firstName,
                      lastName: data.lastName,
                      displayName: data.displayName,
                      photoURL: data.photoURL || "",
                      email: user.email,
                      country: data.country || "",
                      approved: false,
                      ...tempJoinEvent.otherData,
                    };
                    if (tempJoinEvent.ticket.price === 0) {
                      _newAttendee.paid = true;
                      _newAttendee.approved = tempJoinEvent.event.autoApprove;
                    } else {
                      _newAttendee.paid = false;
                      _newAttendee.approved = false;
                    }
                    await dispatch(saveAttendee(tempJoinEvent.organization, tempJoinEvent.event, tempJoinEvent.ticket, _newAttendee));
                    if (tempJoinEvent.ticket.price > 0) {
                      history.push(routes.EVENTS + `/${tempJoinEvent.event.customURL}` + routes.PAY);
                    }
                  }

                  await dispatch(setTempJoinEvent(null));
                }
              },
              (error) => {
                dispatch(asyncActionError(error, "verifyAuth"));
              }
            );
          }
        } else {
          if (unsubscribe) unsubscribe();
          dispatch(signOut());
          dispatch(setAppLoaded());
          called2 = false;
        }
      });
  };
}

export function updateAuthUser(payload) {
  return async (dispatch) => {
    dispatch({ type: authTypes.UPDATE_AUTH_USER, payload });
  };
}
