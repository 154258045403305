export const MapTypesEnum = Object.freeze({
  // Lobby: "Lobby",
  // Plenary: "Plenary",
  // Arcade: "Arcade",
  // Expo: "Expo",
  // Networking: "Networking",
  // Booth: "Booth",
  // Rooms: "Rooms",
  Link: "Link",
  // Image: "Image",
  // Video: "Video",
  // VideoOnScreen: "VideoOnScreen",
  // EmbedOnScreen: "EmbedOnScreen",
  Vimeo: "Vimeo",
  // VimeoOnScreen: "VimeoOnScreen",
  // VideoCall: "VideoCall",
  // PDF: "PDF",
  // Route: "Route",
  Games: "Games",
  // Survey: "Survey",
  // MeetingRequest: "MeetingRequest",
  // PhotoBooth: "PhotoBooth",
  // Stamp: "Stamp",
  // Help: "Help",
  // Chat: "Chat",
  // Download: "Download",
  IFrame: "IFrame",
});

export const getMapTypes = () => {
  return Object.keys(MapTypesEnum).filter((a) => a !== MapTypesEnum.Stamp);
};

// export const passwordRegex = /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/;
export const passwordRegex = /^(?=.*[A-Z])(?=.*\d)[a-zA-Z\d!@#$%^&*()\-_=+{};:,<.>]{6,}$/;

export const LogTypesEnum = Object.freeze({
  EVENT: "event",
  LOBBY: "lobby",
  PLENARY: "plenary",
  EXPO: "expo",
  BOOTHS: "booths",
  ROOMS: "rooms",
  NETWORKING_ROOMS: "networkingRooms",
  PHOTO_BOOTH: "photoBooth",
  VIDEO_CALL: "videoCalls",
});

export const getLogTypes = () => {
  return Object.keys(LogTypesEnum);
};

export const characterSet = [
  " ",
  "a",
  "b",
  "c",
  "d",
  "e",
  "f",
  "g",
  "h",
  "i",
  "j",
  "k",
  "l",
  "m",
  "n",
  "o",
  "p",
  "q",
  "r",
  "s",
  "t",
  "u",
  "v",
  "w",
  "x",
  "y",
  "z",
  " ",
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
  " ",
];
