import React, { useEffect } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
// import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as routes from "../../../main/routes/constants";
import LinearProgress from "@material-ui/core/LinearProgress";
import Grid from "@material-ui/core/Grid";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { TextField } from "formik-material-ui";
import _ from "lodash";

import StorageIcon from "@material-ui/icons/Storage";

import HomeNavbar from "../../../components/Navbars/HomeNavbar";
import { getEventAttendeeByEmailAndCode } from "../actions";
import { exportToExcel, ExportTypesEnum } from "../../../library/utilities/Excel";

const useStyles = makeStyles((theme) => {
  return {
    root: {
      width: "100%",
      "& > * + *": {
        marginTop: theme.spacing(2),
      },
    },
    paper: {
      marginTop: theme.spacing(8),
      marginBottom: theme.spacing(8),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.primary.main,
    },
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    facebook: {
      color: "white",
      backgroundColor: "#1772ED",
    },
    google: {
      color: "white",
      backgroundColor: "#DA3A25",
    },
    spacer: {
      marginTop: 15,
      marginBottom: 15,
    },
    spacerTop: {
      marginTop: 15,
    },
    spacerBottom: {
      marginBottom: 15,
    },
    row: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      width: "100%",
    },
    column: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
    },
  };
});

export default function DownloadAttendees(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { loading, appSettings } = useSelector((state) => state.app);

  const initialValues = {
    email: "",
    code: "",
  };

  const validationSchema = Yup.object({
    email: Yup.string().required("Email address is required").email("Invalid email address"),
    code: Yup.string().required("Code is required"),
  });

  return (
    <div className={classes.root}>
      {appSettings.REACT_APP_LOGO && <HomeNavbar position="sticky" className={classes.appBar} />}
      <Container component="main" maxWidth="sm">
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <StorageIcon />
          </Avatar>
          <Typography component="h1" variant="h4">
            Download Attendee List
          </Typography>

          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            validateOnMount
            enableReinitialize
            onSubmit={async (values) => {
              // await dispatch(
              //   signUp(
              //     _.pick(values, ["firstName", "lastName", "email", "password", "confirmPassword", "agreeToTerms", "agreeToUpdates"]),
              //     history,
              //     props.location.state?.redirect
              //   )
              // );
              let attendees = await dispatch(getEventAttendeeByEmailAndCode(values.email.toLowerCase(), values.code));
              console.log("attendees", attendees);
              if (attendees && attendees.length) {
                exportToExcel(ExportTypesEnum.Attendees, attendees);
              }
            }}
          >
            {({ isValid, values, setFieldValue }) => (
              <Form>
                <Grid item container direction="row" spacing={1} className={classes.spacer}>
                  <Grid item xs={12}>
                    <Field
                      component={TextField}
                      InputLabelProps={{ shrink: true }}
                      variant="outlined"
                      margin="dense"
                      required
                      fullWidth
                      id="email"
                      label="Email Address"
                      name="email"
                      autoComplete="email"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      component={TextField}
                      InputLabelProps={{ shrink: true }}
                      variant="outlined"
                      margin="dense"
                      required
                      fullWidth
                      name="code"
                      label="Code"
                      id="code"
                      autoComplete="current-code"
                    />
                  </Grid>
                </Grid>

                <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit} disabled={!isValid || loading}>
                  Download
                </Button>
                {loading && (
                  <div className={classes.root}>
                    <LinearProgress />
                  </div>
                )}
              </Form>
            )}
          </Formik>
        </div>
      </Container>
    </div>
  );
}
