import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { configureStore, history } from "./main/store";
import { ConnectedRouter } from "connected-react-router";
import AppWrapper from "./AppWrapper";

const version = "1.0.29";
const store = configureStore();
console.log(`%cStop!`, "color: #FF0000; font-size: 50px; white-space: nowrap; word-break: keep-all;");
console.log(
  `%cThis is a browser feature intended for developers. If someone told you to copy-paste something here to enable a feature or "hack" someone's account, it is a scam and will give them access to your account.!`,
  "font-size: 20px; white-space: nowrap; word-break: keep-all;"
);
console.log(`%cv${version}`, "font-size: 20px; white-space: nowrap; word-break: keep-all;");
if (process.env.NODE_ENV !== "development") {
  console.log = () => {};
}
console.log(process.env);

function render() {
  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <AppWrapper />
        </ConnectedRouter>
      </Provider>
    </React.StrictMode>,
    document.getElementById("root")
  );
}

if (module.hot) {
  module.hot.accept("./App", function () {
    setTimeout(render);
  });
}
render();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
