import {
  SET_ORGANIZATIONS,
  SET_ORGANIZATION,
  SET_INVITES,
  SET_ORGANIZATION_INVITES,
  SET_ORGANIZATION_ID,
} from "./constants";

const initialState = {
  organizations: [],
  invites: [],
  organization: null,
  organizationId: null,
  organizationInvites: [],
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case SET_ORGANIZATIONS:
      return {
        ...state,
        organizations: payload || [],
      };
    case SET_ORGANIZATION:
      return {
        ...state,
        organization: payload || null,
      };
    case SET_ORGANIZATION_ID:
      return {
        ...state,
        organizationId: payload || null,
      };
    case SET_INVITES:
      return {
        ...state,
        invites: payload || [],
      };
    case SET_ORGANIZATION_INVITES:
      return {
        ...state,
        organizationInvites: payload || [],
      };
    default:
      return state;
  }
}
