import React, { useEffect } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
// import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { signUp, socialLogin } from "../actions";
import IconPerson from "@material-ui/icons/Person";
import IconFacebook from "@material-ui/icons/Facebook";
import * as routes from "../../../main/routes/constants";
import LinearProgress from "@material-ui/core/LinearProgress";
import Grid from "@material-ui/core/Grid";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { TextField, CheckboxWithLabel } from "formik-material-ui";
import { passwordRegex } from "../../../library/common/constants";
import { forgotPassword } from "../../User/actions";
import { getUrlParams } from "../../../library/utilities";

const useStyles = makeStyles((theme) => {
  return {
    root: {
      width: "100%",
      "& > * + *": {
        marginTop: theme.spacing(2),
      },
    },
    paper: {
      marginTop: theme.spacing(8),
      marginBottom: theme.spacing(8),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.primary.main,
    },
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    facebook: {
      color: "white",
      backgroundColor: "#1772ED",
    },
    google: {
      color: "white",
      backgroundColor: "#DA3A25",
    },
    spacer: {
      marginTop: 15,
      marginBottom: 15,
    },
    spacerTop: {
      marginTop: 15,
    },
    spacerBottom: {
      marginBottom: 15,
    },
    row: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      width: "100%",
    },
    column: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
    },
  };
});

export default function ForgotPassword(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { isLoggedIn } = useSelector((state) => state.auth);
  const { loading } = useSelector((state) => state.app);
  const { tempJoinEvent } = useSelector((state) => state.event);

  useEffect(() => {
    if (isLoggedIn) {
      history.replace(routes.ACCOUNT);
    }
  }, [isLoggedIn, history]);

  let params = getUrlParams();
  const initialValues = {
    email: params?.email?.trim() || "",
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .required("Email address is required")
      .email("Invalid email address"),
  });

  return (
    <Container component="main" maxWidth="sm">
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <IconPerson />
        </Avatar>
        <Typography component="h1" variant="h4">
          Recover your password
        </Typography>
        <Typography
          variant="subtitle1"
          align="center"
          style={{ paddingTop: 10 }}
        >
          Enter your email and we will send you instructions to generate a new
          password.
        </Typography>

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnMount
          onSubmit={async (values) => {
            await dispatch(forgotPassword(values.email));
            history.push(routes.SIGN_IN);
          }}
        >
          {({ isValid }) => (
            <Form className={classes.form}>
              <Grid item container direction="column" spacing={1}>
                <Grid item xs={12}>
                  <Field
                    component={TextField}
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                  />
                </Grid>
              </Grid>

              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                disabled={!isValid || loading}
              >
                Send Instructions
              </Button>
              {loading && (
                <div className={classes.root}>
                  <LinearProgress />
                </div>
              )}
            </Form>
          )}
        </Formik>
        <Typography variant="caption" align="center" style={{ paddingTop: 10 }}>
          <Link
            color="primary"
            onClick={(e) => {
              e.preventDefault();
              history.push({
                pathname: routes.SIGN_IN,
                state: { ...props.location.state },
              });
            }}
          >
            Back to sign in
          </Link>
        </Typography>
      </div>
    </Container>
  );
}
