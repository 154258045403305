import { SET_WEBINAR_ATTENDEE } from "./constants";
// let savedWebinarAttendee = localStorage.getItem("webinarAttendee");

const initialState = {
  // attendee: savedWebinarAttendee ? JSON.parse(savedWebinarAttendee) : null,
  attendee: null,
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case SET_WEBINAR_ATTENDEE:
      return { ...state, attendee: payload || null };
    default:
      return state;
  }
}
