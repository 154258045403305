import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/database";
import "firebase/auth";
import "firebase/storage";
import "firebase/functions";
import { firebaseConfig } from ".";

firebase.initializeApp(firebaseConfig);
// firebase.firestore();
// firebase.analytics();

export default firebase;
