import { SET_ACTIVE_ROOM, SET_PARTICIPANTS } from "./constants";

const initialState = {
  activeRoom: null,
  participants: [],
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case SET_ACTIVE_ROOM:
      return { ...state, activeRoom: payload };
    case SET_PARTICIPANTS:
      return { ...state, participants: payload };
    default:
      return state;
  }
}
