import { toast } from "react-toastify";
import { getThumbnails } from "video-metadata-thumbnails";
import { createEventFileStructureByUrl, createUserFileStructureByUrl, getOrigin } from "../../library/utilities";
import { getFirebase, uploadToUserFirebaseStorage } from "../../library/utilities/Firestore";
import { asyncActionError, asyncActionFinish, asyncActionStart, setProgress, setShowProgress } from "../App/actions";
import { updateAuthUser } from "../Auth/actions";
import { patchAttendee } from "../Events/actions";
import { SET_PROFILE } from "./constants";

// firestore
export function updateUserPassword(values) {
  return async (dispatch) => {
    try {
      let firebase = getFirebase();
      const db = firebase.firestore();
      const collection = db.collection("users");
      const collectionOrganizations = db.collection("organizations");

      dispatch(asyncActionStart());
      const user = firebase.auth().currentUser;
      await user.updatePassword(values.password);
      toast.success("Password changed");
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError(error, "updateUserPassword"));
    }
  };
}

export function getProfile(userId) {
  let firebase = getFirebase();
  const db = firebase.firestore();
  const collection = db.collection("users");
  const collectionOrganizations = db.collection("organizations");

  return collection.doc(userId);
}

export async function getProfileDoc(userId) {
  let firebase = getFirebase();
  const db = firebase.firestore();
  const collection = db.collection("users");
  const collectionOrganizations = db.collection("organizations");

  return (await collection.doc(userId).get()).data();
}

export function updateProfile(profile, update = false, organizations = []) {
  return async (dispatch) => {
    try {
      dispatch(asyncActionStart());

      let firebase = getFirebase();
      const db = firebase.firestore();
      const collection = db.collection("users");
      const collectionOrganizations = db.collection("organizations");

      let date = firebase.firestore.FieldValue.serverTimestamp();
      const user = firebase.auth().currentUser;
      console.log("user", user);

      let updateFunc = async () => {
        profile.displayName = `${profile.firstName} ${profile.lastName}`;
        let userUpdate = {};
        if (user.displayName !== profile.displayName) userUpdate.displayName = profile.displayName;

        if (user.photoURL !== profile.photoURL) userUpdate.photoURL = profile.photoURL;

        if (Object.keys(userUpdate).length) {
          await user.updateProfile(userUpdate);
        }

        if (!profile.createdAt) {
          profile.createdAt = date;
        }
        if (update) {
          await collection.doc(user.uid).update({ ...profile, updatedAt: date });
        } else {
          let origin = window.location.origin;
          await collection.doc(user.uid).set(
            {
              ...profile,
              updatedAt: date,
              origin: origin.includes("localhost") ? "https://events.360logix.com" : origin,
            },
            { merge: true }
          );
        }
        dispatch(updateAuthUser({ displayName: profile.displayName }));

        //update organization team details
        if (organizations.length > 0) {
          let batchOrganizations = db.batch();
          for (let i = 0; i < organizations.length; i++) {
            let organization = { ...organizations[i] };

            for (let j = 0; j < organization.teamDetails.length; j++) {
              let teamDetail = organization.teamDetails[j];
              if (teamDetail.email === user.email) {
                teamDetail.name = profile.displayName;
                // await dispatch(updateOrganization(organization, false, false));
                let orgRef = collectionOrganizations.doc(organization.id);
                batchOrganizations.update(orgRef, {
                  teamDetails: organization.teamDetails,
                  updatedAt: date,
                });
                break;
              }
            }
          }
          batchOrganizations.commit();
        }
        if (update) toast.success(`Profile updated`);
        dispatch(asyncActionFinish());
      };

      if (profile.tempPhoto) {
        dispatch(setProgress(0));
        dispatch(setShowProgress(true));
        uploadToUserFirebaseStorage(
          false,
          profile.tempPhoto,
          "profile",
          (progress) => {
            console.log("Upload is " + progress + "% done");
            dispatch(setProgress(progress));
          },
          async (downloadURL) => {
            profile.photoURL = downloadURL;
            delete profile["tempPhoto"];
            delete profile["tempPhotoUrl"];
            await updateFunc();
          },
          false
        );
      } else {
        await updateFunc();
      }
    } catch (error) {
      dispatch(asyncActionError(error, "updateProfile"));
    }
  };
}

export function saveProfiles(profiles = []) {
  return async (dispatch) => {
    try {
      dispatch(asyncActionStart());

      let firebase = getFirebase();
      const db = firebase.firestore();
      const collection = db.collection("users");
      const collectionOrganizations = db.collection("organizations");

      let batchUsers = db.batch();
      let date = firebase.firestore.FieldValue.serverTimestamp();

      let updateFunc = async () => {
        let origin = window.location.origin;
        profiles.forEach((profile) => {
          let profileRef = collection.doc(profile.uid);
          batchUsers.set(profileRef, {
            ...profile,
            createdAt: date,
            origin: origin.includes("localhost") ? "https://events.360logix.com" : origin,
          });
        });

        batchUsers.commit();
        dispatch(asyncActionFinish());
      };

      await updateFunc();
    } catch (error) {
      dispatch(asyncActionError(error, "saveProfiles"));
    }
  };
}

export function patchProfile(id, data, showAlert = false) {
  return async (dispatch) => {
    try {
      dispatch(asyncActionStart());

      let firebase = getFirebase();
      const db = firebase.firestore();
      const collection = db.collection("users");
      const collectionOrganizations = db.collection("organizations");

      let date = firebase.firestore.FieldValue.serverTimestamp();

      console.log("patchProfile", id, data);

      let snap = await collection.doc(id).get();

      if (snap.exists) {
        await snap.ref.update({ ...data, updatedAt: date });
        if (showAlert) toast.success("Attendee Updated");
      }

      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError(error, "patchProfile"));
    }
  };
}

export function updateUserActivity(profile) {
  return async (dispatch) => {
    try {
      let firebase = getFirebase();
      const db = firebase.firestore();
      const collection = db.collection("users");
      const collectionOrganizations = db.collection("organizations");

      if (profile.events && profile.events.length > 0) {
        let date = firebase.firestore.FieldValue.serverTimestamp();
        for (let i = 0; i < profile.events.length; i++) {
          let event = profile.events[i];
          console.log("updateUserActivity", event);
          await dispatch(
            patchAttendee(profile.id, event, {
              lastLogin: date,
            })
          );
        }
      }
    } catch (error) {
      console.log("updateUserActivity error", error);
    }
  };
}

export function addToBriefcase(file, event, booth = null, room = null) {
  return async (dispatch) => {
    try {
      dispatch(asyncActionStart(true));

      let firebase = getFirebase();
      const db = firebase.firestore();
      const collection = db.collection("users");
      const collectionOrganizations = db.collection("organizations");

      const user = firebase.auth().currentUser;
      let date = firebase.firestore.FieldValue.serverTimestamp();

      let _newFile = {
        ...file,
        fileId: file.id,
        event: {
          id: event.id,
          name: event.name,
        },
        eventId: event.id,
        ...(booth !== null && {
          booth: { id: booth.id, name: booth.name },
          boothId: booth.id,
        }),
        ...(room !== null && {
          room: { id: room.id, name: room.name },
          roomId: room.id,
        }),
        updatedAt: date,
      };
      console.log("_newFile", _newFile);
      delete _newFile["id"];
      await collection.doc(user.uid).collection("eventFiles").add(_newFile);

      toast.success(`Added to User Briefcase`);
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError(error, "addToBriefcase"));
    }
  };
}

export function removeFromBriefcase(userFileId) {
  return async (dispatch) => {
    try {
      dispatch(asyncActionStart(true));

      let firebase = getFirebase();
      const db = firebase.firestore();
      const collection = db.collection("users");
      const collectionOrganizations = db.collection("organizations");

      const user = firebase.auth().currentUser;
      await collection.doc(user.uid).collection("eventFiles").doc(userFileId).delete();

      toast.info(`Removed from User Briefcase`);
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError(error, "removeFromBriefcase"));
    }
  };
}

export function addFiles(event, files = [], booth = null, onFinish) {
  return async (dispatch) => {
    try {
      dispatch(asyncActionStart());

      let firebase = getFirebase();
      const db = firebase.firestore();
      const collection = db.collection("users");
      const collectionOrganizations = db.collection("organizations");

      const user = firebase.auth().currentUser;
      let index = 0;
      let totalProgress = 0;
      let fileUrls = [];

      let addToUserFiles = async (url, file) => {
        let date = firebase.firestore.FieldValue.serverTimestamp();
        let tags = [];
        if (booth) {
          tags.push(booth.name);
        }
        let _newFile = createUserFileStructureByUrl(user, url, event.id, tags);
        _newFile = {
          ..._newFile,
          alt: file.alt || "",
          type: file.type,
          size: file.size,
          createdAt: date,
          createdBy: user.email,
          deleted: false,
        };
        await (
          await collection.doc(user.uid).collection("files").add(_newFile)
        ).onSnapshot((snap) => {
          // fileUrls.push(downloadURL);
          let fileDoc = snap.data();
          fileDoc.id = snap.id;
          console.log("fileDoc", fileDoc);
          fileUrls.push(fileDoc);
          if (onFinish) {
            onFinish(fileDoc);
          }
        });
      };

      dispatch(setShowProgress(true));
      dispatch(setProgress(0));
      let uploadFunc = async () => {
        if (files[index]) {
          if (files[index].type === "video/mp4" || files[index].type === "video/webm") {
            const thumbnails = await getThumbnails(files[index], {
              quality: 0.6,
            });
            files.splice(
              index + 1,
              0,
              new File([thumbnails[0].blob], `${files[index].name.split(".")[0]}-thumb.jpg`, {
                type: "image/jpeg",
                lastModified: new Date().getTime(),
              })
            );
          }

          uploadToUserFirebaseStorage(
            false,
            files[index],
            files[index].name,
            async (progress) => {
              let currentProgress = progress / files.length;
              dispatch(setProgress(totalProgress + currentProgress));
              if (progress >= 100) {
                totalProgress += currentProgress;
              }
            },
            async (downloadURL) => {
              await addToUserFiles(downloadURL, files[index]);
              index++;
              await uploadFunc();
            },
            false
          );
        } else {
          // if (onFinish) {
          //   onFinish(fileUrls);
          // }
          dispatch(asyncActionFinish());
        }
      };
      await uploadFunc();
    } catch (error) {
      dispatch(asyncActionError(error, "addFiles"));
    }
  };
}

export function forgotPassword(email) {
  return async (dispatch) => {
    try {
      dispatch(asyncActionStart(true));

      let firebase = getFirebase();
      const db = firebase.firestore();
      const collection = db.collection("users");
      const collectionOrganizations = db.collection("organizations");

      await firebase.auth().sendPasswordResetEmail(email, {
        url: getOrigin(),
        handleCodeInApp: true,
      });
      toast.success(`A password reset link has been sent to ${email}`);
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError(error, "forgotPassword"));
    }
  };
}

export function getUserEventPassport(eventId, userId = "") {
  let firebase = getFirebase();
  const db = firebase.firestore();
  const collection = db.collection("users");
  const collectionOrganizations = db.collection("organizations");

  if (!userId) {
    const user = firebase.auth().currentUser;
    return collection.doc(user.uid).collection("eventPassport").doc(eventId);
  } else {
    return collection.doc(userId).collection("eventPassport").doc(eventId);
  }
}

export function updateEventPassport(eventId, passportKey, name = "") {
  return async (dispatch) => {
    try {
      // dispatch(asyncActionStart());
      let firebase = getFirebase();
      const db = firebase.firestore();
      const collection = db.collection("users");
      const collectionOrganizations = db.collection("organizations");

      const user = firebase.auth().currentUser;
      const date = firebase.firestore.FieldValue.serverTimestamp();

      const docRef = collection.doc(user.uid).collection("eventPassport").doc(eventId);
      const snapshot = await docRef.get();
      if (snapshot.exists) {
        await docRef.update({
          updatedAt: date,
          [passportKey]: true,
        });
      } else {
        await docRef.set({
          createdAt: date,
          [passportKey]: true,
        });
      }
      if (name) toast.success(`${name} stamp acquired!`);

      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError(error, "updateEventPassport"));
    }
  };
}

export function getNotificationsByUserEvent(eventId) {
  let firebase = getFirebase();
  const db = firebase.firestore();
  const collection = db.collection("users");
  const collectionOrganizations = db.collection("organizations");

  const user = firebase.auth().currentUser;
  return collection.doc(user.uid).collection("events").doc(eventId).collection("notifications").where("unread", "==", true).orderBy("createdAt", "desc");
}

export async function deleteNotificationsEventBooth(eventId, boothId) {
  let firebase = getFirebase();
  const db = firebase.firestore();
  const collection = db.collection("users");
  const collectionOrganizations = db.collection("organizations");

  const batch = db.batch();
  const user = firebase.auth().currentUser;
  try {
    let notifications = await collection
      .doc(user.uid)
      .collection("events")
      .doc(eventId)
      .collection("notifications")
      .where("unread", "==", true)
      .where("booth", "==", boothId)
      .get();

    notifications.docs.forEach((doc) => {
      // console.log("delete", doc);
      batch.delete(collection.doc(user.uid).collection("events").doc(eventId).collection("notifications").doc(doc.id));
    });

    return await batch.commit();
  } catch (error) {
    console.log(error);
  }
}

export async function deleteNotificationsEvent(eventId) {
  let firebase = getFirebase();
  const db = firebase.firestore();
  const collection = db.collection("users");
  const collectionOrganizations = db.collection("organizations");

  const batch = db.batch();
  const user = firebase.auth().currentUser;
  try {
    let notifications = await collection
      .doc(user.uid)
      .collection("events")
      .doc(eventId)
      .collection("notifications")
      .where("unread", "==", true)
      .where("event", "==", eventId)
      .get();

    notifications.docs.forEach((doc) => {
      // console.log("delete", doc);
      batch.delete(collection.doc(user.uid).collection("events").doc(eventId).collection("notifications").doc(doc.id));
    });

    return await batch.commit();
  } catch (error) {
    console.log(error);
  }
}

// redux
export function setProfile(payload) {
  return {
    type: SET_PROFILE,
    payload,
  };
}
