import {
  APP_LOADED,
  ASYNC_ACTION_ERROR,
  ASYNC_ACTION_FINISH,
  ASYNC_ACTION_START,
  SET_SHOW_SIDEBAR,
  SET_PROGRESS,
  SET_SHOW_PROGRESS,
  SET_PREVIEW,
  TOGGLE_SHOW_LIVE_SIDEBAR,
  SET_ALERT_DIALOG,
  TOGGLE_NAVIGATION_LAYOUT,
  SET_APP_THEME,
  SET_APP_SETTINGS,
  SET_APP_METADATA,
  SET_DIALOG_SHOWN,
} from "./constants";

const initialState = {
  loading: false,
  error: null,
  initialized: false,
  showSidebar: true,
  showProgress: false,
  progress: 0,
  preview: false,
  showLiveSidebar: true,
  alertDialog: null,
  navigationLayout: "top",
  theme: null,
  appSettings: {},
  appMetadata: {},
  dialogShown: false,
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case ASYNC_ACTION_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ASYNC_ACTION_FINISH:
      return {
        ...state,
        loading: false,
        error: null,
        showProgress: false,
      };
    case ASYNC_ACTION_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
        showProgress: false,
      };
    case APP_LOADED:
      return {
        ...state,
        initialized: true,
        showProgress: false,
      };
    case SET_SHOW_SIDEBAR:
      return { ...state, showSidebar: payload };
    case SET_SHOW_PROGRESS:
      return { ...state, showProgress: payload };
    case SET_PROGRESS:
      return { ...state, progress: payload };
    case SET_PREVIEW:
      return { ...state, preview: payload };
    case TOGGLE_SHOW_LIVE_SIDEBAR:
      return { ...state, showLiveSidebar: !state.showLiveSidebar };
    case SET_ALERT_DIALOG:
      return { ...state, alertDialog: payload };
    case TOGGLE_NAVIGATION_LAYOUT:
      return {
        ...state,
        navigationLayout: state.navigationLayout === "top" ? "right" : "top",
      };
    case SET_APP_THEME:
      return { ...state, theme: payload };
    case SET_APP_SETTINGS: {
      return {
        ...state,
        appSettings: payload,
      };
    }
    case SET_APP_METADATA: {
      return {
        ...state,
        appMetadata: payload,
      };
    }
    case SET_DIALOG_SHOWN: {
      return {
        ...state,
        dialogShown: payload,
      };
    }
    default:
      return state;
  }
}
