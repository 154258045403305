import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

import { ValidatorForm } from "react-material-ui-form-validator";
import { useDispatch, useSelector } from "react-redux";
import { updateEvent } from "../actions";
import LinearProgress from "@material-ui/core/LinearProgress";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import { useHistory } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import * as routes from "../../../main/routes/constants";
import { options, categories, types } from "../constants";
import { getTimezone } from "../../../library/common/constants/Timezones";

const useStyles = makeStyles((theme) => {
  return {
    root: {
      width: "100%",
      "& > * + *": {
        marginTop: theme.spacing(2),
      },
    },
    paper: {
      marginTop: theme.spacing(8),
      marginBottom: theme.spacing(8),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.primary.main,
    },
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    fileUpload: {
      margin: theme.spacing(2, 0, 2),
      backgroundColor: "white",
      color: theme.palette.primary.main,
    },
    facebook: {
      color: "white",
      backgroundColor: "#1772ED",
    },
    google: {
      color: "white",
      backgroundColor: "#DA3A25",
    },
    header: {
      width: "100%",
      marginBottom: 20,
    },
    fullWidth: {
      width: "100%",
    },
    small: {
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
    large: {
      width: theme.spacing(7),
      height: theme.spacing(7),
      marginRight: 10,
    },
    media: {
      minHeight: 140,
    },
    spacer: {
      marginTop: 15,
      marginBottom: 15,
    },
    spacerTop: {
      marginTop: 15,
    },
    spacerBottom: {
      marginBottom: 15,
    },
  };
});

export default function NewEvent(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { loading, appSettings } = useSelector((state) => state.app);
  const { user } = useSelector((state) => state.auth);

  const [formData, setFormData] = useState({
    name: "",
    options,
    categories: [],
    type: types[2].key,
    status: "draft",
    deleted: false,
    about: "",
    showHostedBy: true,
    earlyAccess: false,
    autoApprove: true,
    emailSenderName: appSettings.REACT_APP_WEBSITE_NAME || process.env.REACT_APP_WEBSITE_NAME,
    timezone: getTimezone().tz,
    networkingRoomLimit: 20,
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    await dispatch(
      updateEvent({
        ...formData,
        team: [user.uid],
        teamDetails: [
          {
            user: user.uid,
            name: user.displayName,
            email: user.email,
          },
        ],
      })
    );
    history.replace(routes.ACCOUNT);
  };

  const onChange = (e) => {
    let updateObj = { ...formData, [e.target.name]: e.target.value };
    if (e.target.name === "agreeToTerms") updateObj.agreeToTerms = !formData.agreeToTerms;
    if (e.target.name === "agreeToUpdates") updateObj.agreeToUpdates = !formData.agreeToUpdates;
    setFormData(updateObj);
  };

  return (
    <ValidatorForm onSubmit={handleSubmit} className={classes.form} noValidate>
      <Container component="main" maxWidth="sm">
        <div className={classes.paper}>
          <Typography component="h1" variant="h4" className={classes.header}>
            New event
          </Typography>
          <Typography variant="subtitle1" className={[classes.fullWidth, classes.spacerBottom].join(" ")}>
            Start with the key details about your new event.
          </Typography>
          <TextField
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="name"
            label="Event Name"
            name="name"
            autoComplete="name"
            autoFocus
            value={formData.name}
            onChange={onChange}
            disabled={loading}
          />
          <Typography variant="body1" className={[classes.fullWidth, classes.spacerTop].join(" ")}>
            What would you like the event to have?
          </Typography>
          <Grid container spacing={1}>
            {formData.options?.map((opt) => (
              <Grid item xs={6} key={`option-${opt.key}`}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={opt.value}
                      onChange={() => {
                        let newOpts = [...formData.options];
                        let _opt = newOpts.find((a) => a.key === opt.key);
                        if (_opt) {
                          _opt.value = !_opt.value;
                        }
                        setFormData({ ...formData, options: newOpts });
                      }}
                      name="options"
                      color={"primary"}
                    />
                  }
                  label={
                    <Typography variant="body2" color="textSecondary">
                      {opt.label}
                    </Typography>
                  }
                />
              </Grid>
            ))}
          </Grid>
          <Typography variant="body1" className={[classes.fullWidth, classes.spacerTop].join(" ")}>
            What categories would you like to associate with your event?
          </Typography>
          <Grid container spacing={1}>
            {categories?.map((opt, index) => (
              <Grid item xs={12} key={`category-${index}`}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formData.categories.indexOf(opt) > -1}
                      onChange={() => {
                        let _categories = [...formData.categories];
                        let _opt = _categories.findIndex((a) => a === opt);
                        console.log("_opt", _opt, _categories);
                        if (_opt > -1) {
                          _categories.splice(_opt, 1);
                        } else {
                          _categories.push(opt);
                        }
                        setFormData({ ...formData, categories: _categories });
                      }}
                      name="categories"
                      color={"primary"}
                    />
                  }
                  label={
                    <Typography variant="body2" color="textSecondary">
                      {opt}
                    </Typography>
                  }
                />
              </Grid>
            ))}
          </Grid>
          <Typography variant="body1" className={[classes.fullWidth, classes.spacerTop].join(" ")}>
            Event Type
          </Typography>
          <RadioGroup aria-label="type" name="type" value={formData.type} onChange={onChange} className={classes.fullWidth}>
            {types?.map((opt) => (
              <FormControlLabel
                key={`type-${opt.key}`}
                value={opt.key}
                control={<Radio color="primary" />}
                label={
                  <div className={classes.spacerTop}>
                    <Typography variant="body2" color="textSecondary">
                      {opt.label}
                    </Typography>
                    <Typography variant="subtitle2" color="textSecondary">
                      {opt.description}
                    </Typography>
                  </div>
                }
              />
            ))}
          </RadioGroup>

          <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit} disabled={loading || !formData.name}>
            Create event
          </Button>
          {loading && (
            <div className={classes.root}>
              <LinearProgress />
            </div>
          )}
        </div>
      </Container>
    </ValidatorForm>
  );
}
