export const timezones = [
  {
    label: "International Date Line West",
    value: "GMT-12:00",
    tz: "Etc/GMT+12",
  },
  {
    label: "American Samoa, Midway Island",
    value: "GMT-11:00",
    tz: "Etc/GMT+11",
  },
  { label: "Hawaii", value: "GMT-10:00", tz: "Etc/GMT+10" },
  { label: "Alaska", value: "GMT-08:00", tz: "Etc/GMT+8" },
  {
    label: "Pacific Time US & Canada), Tijuana, Arizona",
    value: "GMT-07:00",
    tz: "Etc/GMT+7",
  },
  {
    label:
      "Chihuahua, Mazatlan, Mountain Time US & Canada), Central America, Saskatchewan",
    value: "GMT-06:00",
    tz: "Etc/GMT+6",
  },
  {
    label:
      "Central Time US & Canada), Guadalajara, Mexico City, Monterrey, Bogota, Lima, Quito",
    value: "GMT-05:00",
    tz: "Etc/GMT+5",
  },
  {
    label:
      "Eastern Time US & Canada), Indiana East), Caracas, Georgetown, La Paz, Puerto Rico, Santiago",
    value: "GMT-04:00",
    tz: "Etc/GMT+4",
  },
  {
    label: "Atlantic Time Canada), Brasilia, Buenos Aires, Montevideo",
    value: "GMT-03:00",
    tz: "Etc/GMT+3",
  },
  {
    label: "Greenland, Mid-Atlantic",
    value: "GMT-02:00",
    tz: "Etc/GMT+2",
  },
  {
    label: "Azores, Monrovia, UTC, Casablanca",
    value: "GMT+00:00",
  },
  {
    label: "Cape Verde Is.",
    value: "GMT-01:00",
    tz: "Etc/GMT+1",
  },
  {
    label: "Edinburgh, Lisbon, London, Dublin, West Central Africa",
    value: "GMT+01:00",
    tz: "Etc/GMT-1",
  },
  {
    label:
      "Amsterdam, Belgrade, Berlin, Bern, Bratislava, Brussels, Budapest, Copenhagen, Ljubljana, Madrid, Paris, Prague, Rome, Sarajevo, Skopje, Stockholm, Vienna, Warsaw, Zagreb, Zurich, Cairo, Harare, Kaliningrad, Pretoria",
    value: "GMT+02:00",
    tz: "Etc/GMT-2",
  },
  {
    label:
      "Athens, Bucharest, Helsinki, Jerusalem, Kyiv, Riga, Sofia, Tallinn, Vilnius, Baghdad, Istanbul, Kuwait, Minsk, Moscow, Nairobi, Riyadh, St. Petersburg, Volgograd",
    value: "GMT+03:00",
    tz: "Etc/GMT-3",
  },
  {
    label: "Tehran, Kabul",
    value: "GMT+04:30",
    tz: "Asia/Kabul",
  },
  {
    label: "Abu Dhabi, Baku, Muscat, Samara, Tbilisi, Yerevan",
    value: "GMT+04:00",
    tz: "Etc/GMT-4",
  },
  {
    label: "Ekaterinburg, Islamabad, Karachi, Tashkent",
    value: "GMT+05:00",
    tz: "Etc/GMT-5",
  },
  {
    label: "Chennai, Kolkata, Mumbai, New Delhi, Sri Jayawardenepura",
    value: "GMT+05:30",
    tz: "Asia/Colombo",
  },
  {
    label: "Kathmandu",
    value: "GMT+05:45",
    tz: "Asia/Katmandu",
  },
  {
    label: "Almaty, Astana, Dhaka, Urumqi",
    value: "GMT+06:00",
    tz: "Etc/GMT-6",
  },
  {
    label: "Rangoon",
    value: "GMT+06:30",
    tz: "Asia/Rangoon",
  },
  {
    label: "Bangkok, Hanoi, Jakarta, Krasnoyarsk, Novosibirsk",
    value: "GMT+07:00",
    tz: "Etc/GMT-7",
  },
  {
    label:
      "Beijing, Chongqing, Hong Kong, Irkutsk, Kuala Lumpur, Perth, Singapore, Taipei, Ulaanbaatar",
    value: "GMT+08:00",
    tz: "Etc/GMT-8",
  },
  {
    label: "Osaka, Sapporo, Seoul, Tokyo, Yakutsk",
    value: "GMT+09:00",
    tz: "Etc/GMT-9",
  },
  {
    label: "Adelaide, Darwin",
    value: "GMT+09:30",
    tz: "Australia/Adelaide",
  },
  {
    label:
      "Brisbane, Canberra, Guam, Hobart, Melbourne, Port Moresby, Sydney, Vladivostok",
    value: "GMT+10:00",
    tz: "Etc/GMT-10",
  },
  {
    label: "Magadan, New Caledonia, Solomon Is., Srednekolymsk",
    value: "GMT+11:00",
    tz: "Etc/GMT-11",
  },
  {
    label: "Auckland, Fiji, Kamchatka, Marshall Is., Wellington",
    value: "GMT+12:00",
    tz: "Etc/GMT-12",
  },
  {
    label: "Chatham Is.",
    value: "GMT+12:45",
    tz: "Pacific/Chatham",
  },
  {
    label: "Nuku'alofa, Samoa, Tokelau Is.",
    value: "GMT+13:00",
    tz: "Etc/GMT-13",
  },
];

export const getTimezone = (tz = "Etc/GMT-8") => {
  return timezones.find((a) => a.tz === tz);
};
