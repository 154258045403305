import { chunkify, createEventFileStructureByUrl, getCurrentIp, getFileType, isAnalyticsUniqueEntry } from "../../library/utilities";
import { getFirebase, uploadToFirebaseStorage, deleteFileByUrl, generateUid } from "../../library/utilities/Firestore";
import { asyncActionError, asyncActionFinish, asyncActionStart, setProgress, setShowProgress } from "../App/actions";
import {
  SET_EVENTS,
  SET_EVENT,
  SET_EVENT_ID,
  SET_EVENT_FILES,
  SET_BOOTHS,
  SET_BOOTH,
  SET_EVENT_BOOTH_FILES,
  SET_BOOTH_ID,
  SET_TICKET,
  SET_ATTENDEES,
  SET_TEMP_JOIN_EVENT,
  SET_USER_EVENTS,
  SET_EVENT_MESSAGES,
  SET_EVENT_USER_FILES,
  SET_EVENT_USER_PASSPORT,
  SET_ATTENDEE,
  SET_BOOTH_TEAM,
  SET_BOOTH_INVITES,
  SET_USER_BOOTH_INVITES,
  SET_USER_BOOTHS,
  SET_BOOTH_MESSAGES,
  TOGGLE_SHOW_PASSPORT,
  SET_HELP_MESSAGES,
  TOGGLE_SHOW_CHAT,
  SET_BOOTH_SURVEY_ANSWERS,
  SET_EVENT_NOTIFICATIONS,
  TOGGLE_SHOW_EVENT_CHAT,
  SET_EVENT_PLENARY_MESSAGES,
  SET_EVENT_CONTROLS,
  SET_EVENT_BROADCAST_MESSAGES,
  TOGGLE_EVENT_MUSIC_MUTED,
  SET_ROUTING_LOCKED,
  TOGGLE_SHOW_HELP_CHAT,
  SET_EVENT_BOOTH_NOTIFICATIONS,
  SET_REGISTRATION_DETAIL,
  SET_NETWORKING_ROOMS,
  SET_NETWORKING_ROOM,
  SET_NETWORKING_ROOM_ID,
  SET_ROOMS,
  SET_ROOM,
  SET_ROOM_ID,
  SET_ROOM_CHILDREN,
  SET_SURVEYS,
  SET_SURVEY_ANSWERS,
  SET_ON_VIDEO_CALL,
  SET_BOOTH_VISITORS,
  SET_ATTENDEE_COMPANIONS,
  SET_EVENT_FAQS,
  SET_EVENT_FAQ,
  SET_EVENT_BROADCAST_MESSAGE_TEMPLATES,
  SET_EVENT_RAFFLE_FILTERS,
  SET_EVENT_RAFFLE_PRIZES,
  SET_EVENT_RAFFLE,
  SET_EVENT_RAFFLE_PRIZE,
  SET_EVENT_RAFFLE_WINNERS,
  SET_EVENT_RAFFLE_PRIZE_ID,
  SET_EVENT_ATTENDEE_PRESENCE,
  SET_EVENT_EMAIL_TEMPLATES,
} from "./constants";
import { getThumbnails } from "video-metadata-thumbnails";
import { toast } from "react-toastify";
import { subMinutes } from "date-fns";
import { patchProfile } from "../User/actions";
import { LogTypesEnum } from "../../library/common/constants";

// email templates
import successfulRegistration from "../../components/EmailEditor/successfulRegistration.json";
import pendingPayment from "../../components/EmailEditor/pendingPayment.json";
import pendingApproval from "../../components/EmailEditor/pendingApproval.json";
import reminder from "../../components/EmailEditor/reminder.json";
import access from "../../components/EmailEditor/access.json";

import _ from "lodash";

let firebase = getFirebase();
const db = firebase.firestore();
const collection = db.collection("events");
const collectionUserEvents = db.collection("userEvents");
const collectionUsers = db.collection("users");
const collectionBoothInvites = db.collection("boothInvites");
const collectionUserBooths = db.collection("userBooths");
const collectionPartners = db.collection("partners");
const collectionAnalyticsEvents = db.collection("analyticsEvents");
const collectionStatus = db.collection("status");
const collectionEmailTemplates = db.collection("emailTemplates");
const invitesCollection = db.collection("invites");

// firestore
export function getPartners() {
  return collectionPartners;
}

export function getPartnerByDomain(domain = "") {
  return collectionPartners.where("domain", "==", domain);
}

export function getEvents(userId) {
  if (!userId) {
    const user = firebase.auth().currentUser;
    userId = user.uid;
  }
  console.log("getEvents", userId);
  return collection.where("team", "array-contains", userId).where("deleted", "==", false).orderBy("createdAt");
}

export function getEventById(eventId) {
  console.log("getEventById", eventId);
  return collection.doc(eventId);
}

export function getEventByCustomURL(url) {
  console.log("getEventByCustomURL", url);
  return collection.where("customURL", "==", url).where("deleted", "==", false);
}

export async function getEventByCustomURLDoc(url) {
  console.log("getEventByCustomURL", url);
  return (await (await collection.where("customURL", "==", url).get()).docs[0])?.data();
}

export function getEventFilesByIdLegacy(eventId) {
  // console.log("getEventById", eventId);
  // return collection.doc(eventId);
  return async (dispatch) => {
    // dispatch(asyncActionStart());
    try {
      const storageRef = firebase.storage().ref();
      let a = await storageRef.child(`public/events/${eventId}/files`).listAll();
      let files = [];
      if (a?.items?.length) {
        for (let i = 0; i < a.items.length; i++) {
          // console.log(await a.items[i].getMetadata());
          let url = await a.items[i].getDownloadURL();

          let fileName = url.split("?").shift();
          fileName = decodeURIComponent(fileName);
          fileName = fileName.split("/").pop();
          let file = {
            url,
            fileName,
            fileType: getFileType(fileName),
          };
          if (fileName.includes(".mp4") || fileName.includes(".webm") || fileName.includes(".m4v")) {
            let _urls = url.split("?");
            let first = decodeURIComponent(_urls[0]);
            let last = _urls[1];

            let newFileName = `${fileName.split(".").shift()}-thumb.jpg`;
            first = first.replace(fileName, newFileName);
            first = first.replace(`public/events/${eventId}/files/${newFileName}`, encodeURIComponent(`public/events/${eventId}/files/${newFileName}`));
            file.thumbnail = `${first}?${last}`;
          }
          files.push(file);
        }
      }
      dispatch(setEventFiles(files));
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError(error, "getEventFilesByIdLegacy"));
    }
  };
}

export function getEventFilesById(eventId) {
  console.log("getEventFilesById", eventId);
  return collection.doc(eventId).collection("files").where("deleted", "==", false).orderBy("createdAt");
}

export function getEventFilesByBooth(eventId, boothId) {
  console.log("getEventFilesByBooth", eventId, boothId);
  return collection.doc(eventId).collection("files").where("booth", "==", boothId).where("deleted", "==", false).orderBy("createdAt");
}

export function getEventsByOrganization(orgId) {
  console.log("getEventsByOrganization", orgId);
  return collection.where("organization", "==", orgId).where("deleted", "==", false).orderBy("createdAt");
}

export function updateEvent(event, key = "") {
  return async (dispatch) => {
    try {
      dispatch(asyncActionStart());
      let date = firebase.firestore.FieldValue.serverTimestamp();
      let newId = collection.doc().id;

      let updateFunc = async () => {
        console.log("event", event);
        if (!event?.createdAt) {
          event.createdAt = date;
        }
        if (event.id) {
          let id = event.id;
          delete event["id"];
          await collection.doc(id).update({ ...event, updatedAt: date });
        } else {
          await collection.doc(newId).set(
            {
              ...event,
              customURL: newId,
              updatedAt: date,
              registrationDetails: [
                {
                  required: true,
                  options: [],
                  updatedAt: new Date(),
                  createdAt: new Date(),
                  name: "email",
                  fieldType: "text",
                  uniqueField: "email",
                  caption: "",
                  label: "Email Address",
                  type: "TextField",
                  constant: "email",
                },
              ],
            },
            { merge: true }
          );
          await dispatch(
            saveEventEmailTemplate(
              newId,
              {
                id: "successfulRegistration",
                name: "Successful Registration",
                design: successfulRegistration.design,
                html: successfulRegistration.html,
              },
              false
            )
          );
          await dispatch(
            saveEventEmailTemplate(
              newId,
              {
                id: "pendingPayment",
                name: "Pending Payment",
                design: pendingPayment.design,
                html: pendingPayment.html,
              },
              false
            )
          );
          await dispatch(
            saveEventEmailTemplate(
              newId,
              {
                id: "pendingApproval",
                name: "Pending Approval",
                design: pendingApproval.design,
                html: pendingApproval.html,
              },
              false
            )
          );
          await dispatch(
            saveEventEmailTemplate(
              newId,
              {
                id: "reminder",
                name: "Event Reminder",
                design: reminder.design,
                html: reminder.html,
              },
              false
            )
          );
          await dispatch(
            saveEventEmailTemplate(
              newId,
              {
                id: "access",
                name: "Event Access",
                design: access.design,
                html: access.html,
              },
              false
            )
          );
        }

        // toast.success(`Event ${event.id ? "updated" : "created"}`);
        dispatch(asyncActionFinish());
      };

      let savePath = `public/events/${event?.id || newId}/documents`;

      dispatch(setProgress(0));
      if (key) {
        console.log("key", key);
        if (event[key]?.tempVideoBackground) {
          dispatch(setShowProgress(true));
          uploadToFirebaseStorage(
            true,
            event[key].tempVideoBackground,
            key + "VideoBackground",
            (progress) => {
              // console.log("Upload is " + progress + "% done");
              dispatch(setProgress(progress));
            },
            async (downloadURL) => {
              event = { ...event };
              event[key] = {
                ...event[key],
                videoBackground: downloadURL,
              };
              const thumbnails = await getThumbnails(event[key].tempVideoBackground, {
                quality: 0.6,
                scale: 1,
              });
              dispatch(setShowProgress(true));
              uploadToFirebaseStorage(
                true,
                thumbnails[0].blob,
                key + "ImageBackground",
                (progress) => {
                  // console.log("Upload is " + progress + "% done");
                  dispatch(setProgress(progress));
                },
                async (downloadURL2) => {
                  event[key] = {
                    ...event[key],
                    imageBackground: downloadURL2,
                  };
                  delete event[key]["tempVideoBackground"];
                  await updateFunc();
                },
                savePath,
                ".jpg"
              );
            },
            savePath
          );
        } else if (event[key]?.tempImageBackground) {
          dispatch(setShowProgress(true));
          uploadToFirebaseStorage(
            true,
            event[key]?.tempImageBackground,
            key + "ImageBackground",
            (progress) => {
              // console.log("Upload is " + progress + "% done");
              dispatch(setProgress(progress));
            },
            async (downloadURL2) => {
              event = { ...event };
              event[key] = {
                ...event[key],
                imageBackground: downloadURL2,
                // videoBackground: firebase.firestore.FieldValue.delete(),
              };
              if (event[key].videoBackground) {
                deleteFileByUrl(event[key].videoBackground);
              }
              delete event[key]["videoBackground"];
              delete event[key]["tempImageBackground"];
              await updateFunc();
            },
            savePath
          );
        } else if (event[key]?.tempIntroVideo) {
          dispatch(setShowProgress(true));
          uploadToFirebaseStorage(
            true,
            event[key]?.tempIntroVideo,
            key + "IntroVideo",
            (progress) => {
              // console.log("Upload is " + progress + "% done");
              dispatch(setProgress(progress));
            },
            async (downloadURL2) => {
              event = { ...event };
              event[key] = {
                ...event[key],
                introVideo: downloadURL2,
              };
              delete event[key]["tempIntroVideo"];
              await updateFunc();
            },
            savePath
          );
        } else {
          await updateFunc();
        }
      } else {
        if (event.tempLogo || event.tempCover) {
          if (event.tempLogo) {
            dispatch(setShowProgress(true));
            uploadToFirebaseStorage(
              true,
              event.tempLogo,
              "logo",
              (progress) => {
                dispatch(setProgress(progress));
              },
              async (downloadURL) => {
                event = { ...event, logo: downloadURL };
                delete event["tempLogo"];
                delete event["tempLogoURL"];

                if (event.tempCover) {
                  dispatch(setShowProgress(true));
                  uploadToFirebaseStorage(
                    true,
                    event.tempCover,
                    "cover",
                    (progress) => {
                      dispatch(setProgress(progress));
                    },
                    async (downloadURL2) => {
                      event = { ...event, cover: downloadURL2 };
                      delete event["tempCover"];
                      delete event["tempCoverURL"];
                      await updateFunc();
                    },
                    savePath
                  );
                } else {
                  await updateFunc();
                }
              },
              savePath
            );
          } else {
            dispatch(setShowProgress(true));
            uploadToFirebaseStorage(
              true,
              event.tempCover,
              "cover",
              (progress) => {
                dispatch(setProgress(progress));
              },
              async (downloadURL2) => {
                event = { ...event, cover: downloadURL2 };
                delete event["tempCover"];
                delete event["tempCoverURL"];
                await updateFunc();
              },
              savePath
            );
          }
        } else {
          await updateFunc();
        }
      }
    } catch (error) {
      dispatch(asyncActionError(error, "updateEvent"));
    }
  };
}

export function patchEvent(id, data, deletes = []) {
  return async (dispatch) => {
    try {
      dispatch(asyncActionStart());
      let date = firebase.firestore.FieldValue.serverTimestamp();

      console.log("patchEvent", data);

      await collection.doc(id).update({ ...data, updatedAt: date });
      for (let i = 0; i < deletes.length; i++) {
        await deleteFileByUrl(deletes[i]);
      }
      // toast.success(`Event updated`);
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError(error, "patchEvent"));
    }
  };
}

export function patchEventFileByKey(event, key, field, file) {
  return async (dispatch) => {
    try {
      dispatch(asyncActionStart());
      let date = firebase.firestore.FieldValue.serverTimestamp();

      console.log("patchEventFileByKey", key, field, file);

      let savePath = `public/events/${event?.id}/documents`;

      if (field === "musicBackground") {
        dispatch(setShowProgress(true));
        uploadToFirebaseStorage(
          true,
          file,
          key + "MusicBackground",
          (progress) => {
            dispatch(setProgress(progress));
          },
          async (downloadURL) => {
            await collection.doc(event?.id).update({ [`${key}.${field}`]: downloadURL, updatedAt: date });
          },
          savePath
        );
      }

      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError(error, "patchEventFileByKey"));
    }
  };
}

export function addFiles(event, files = [], booth = null, onFinish) {
  return async (dispatch) => {
    try {
      dispatch(asyncActionStart());
      const user = firebase.auth().currentUser;
      let savePath = `public/events/${event.id}/files`;
      let index = 0;
      let totalProgress = 0;
      let downloadURLs = [];

      let addToEventFiles = async (url, file) => {
        let date = firebase.firestore.FieldValue.serverTimestamp();
        let tags = [];
        if (booth) {
          tags.push(booth.name);
        }
        let _newFile = createEventFileStructureByUrl(url, event.id, tags, booth?.id);
        _newFile = {
          ..._newFile,
          alt: file.alt || "",
          type: file.type,
          size: file.size,
          createdAt: date,
          createdBy: user.email,
          deleted: false,
        };
        await collection.doc(event.id).collection("files").add(_newFile);
      };

      dispatch(setShowProgress(true));
      dispatch(setProgress(0));
      let uploadFunc = async () => {
        if (files[index]) {
          if (files[index].type === "video/mp4" || files[index].type === "video/webm") {
            const thumbnails = await getThumbnails(files[index], {
              quality: 0.6,
            });
            files.splice(
              index + 1,
              0,
              new File([thumbnails[0].blob], `${files[index].name.split(".")[0]}-thumb.jpg`, {
                type: "image/jpeg",
                lastModified: new Date().getTime(),
              })
            );
          }

          uploadToFirebaseStorage(
            true,
            files[index],
            files[index].name,
            async (progress) => {
              let currentProgress = progress / files.length;
              dispatch(setProgress(totalProgress + currentProgress));
              if (progress >= 100) {
                totalProgress += currentProgress;
              }
            },
            async (downloadURL) => {
              downloadURLs.push(downloadURL);
              await addToEventFiles(downloadURL, files[index]);
              index++;
              await uploadFunc();
            },
            savePath
          );
        } else {
          if (onFinish) {
            onFinish(downloadURLs);
          }
          dispatch(asyncActionFinish());
        }
      };
      await uploadFunc();
    } catch (error) {
      dispatch(asyncActionError(error, "addFiles"));
    }
  };
}

export function addDynamicFiles(values, location, onFinish) {
  return async (dispatch) => {
    let _files = Object.keys(values).filter((k) => k.includes("temp_"));
    let files = [];
    console.log("_files", _files);
    _files.forEach((key) => {
      files.push(values[key]);
    });
    let index = 0;
    let totalProgress = 0;

    dispatch(setProgress(0));
    dispatch(setShowProgress(true));
    let uploadFunc = async () => {
      if (files[index]) {
        uploadToFirebaseStorage(
          true,
          files[index],
          files[index].name,
          async (progress) => {
            let currentProgress = progress / files.length;
            dispatch(setProgress(totalProgress + currentProgress));
            if (progress >= 100) {
              totalProgress += currentProgress;
            }
          },
          async (downloadURL) => {
            let field = _files[index].replace("temp_", "");
            delete values[_files[index]];
            values[field] = downloadURL;
            index++;
            await uploadFunc();
          },
          location
        );
      } else {
        if (onFinish) onFinish(values);
      }
    };
    await uploadFunc();
  };
}

export function addRegistrationFile(event, files = [], onFinish) {
  return async (dispatch) => {
    try {
      dispatch(asyncActionStart());
      let savePath = `public/events/${event.id}/registration`;
      let index = 0;
      let totalProgress = 0;

      dispatch(setShowProgress(true));
      dispatch(setProgress(0));
      let downloadURLs = [];
      let uploadFunc = async () => {
        if (files[index]) {
          if (files[index].type === "video/mp4" || files[index].type === "video/webm") {
            const thumbnails = await getThumbnails(files[index], {
              quality: 0.6,
            });
            files.splice(
              index + 1,
              0,
              new File([thumbnails[0].blob], `${files[index].name.split(".")[0]}-thumb.jpg`, {
                type: "image/jpeg",
                lastModified: new Date().getTime(),
              })
            );
          }

          uploadToFirebaseStorage(
            false,
            files[index],
            Date.now() + "-" + files[index].name,
            async (progress) => {
              let currentProgress = progress / files.length;
              dispatch(setProgress(totalProgress + currentProgress));
              if (progress >= 100) {
                totalProgress += currentProgress;
              }
            },
            async (downloadURL) => {
              downloadURLs.push(downloadURL);
              index++;
              await uploadFunc();
            },
            savePath
          );
        } else {
          dispatch(asyncActionFinish());
          if (onFinish) {
            onFinish(downloadURLs);
          }
        }
      };
      await uploadFunc();
    } catch (error) {
      dispatch(asyncActionError(error, "addRegistrationFile"));
    }
  };
}

export function patchFile(docId, file, event) {
  return async (dispatch) => {
    try {
      dispatch(asyncActionStart());
      let date = firebase.firestore.FieldValue.serverTimestamp();

      let patchFunc = async () => {
        delete file["id"];
        await collection
          .doc(event.id)
          .collection("files")
          .doc(docId)
          .update({ ...file, updatedAt: date });
      };

      await patchFunc();
    } catch (error) {
      dispatch(asyncActionError(error, "patchFile"));
    }
  };
}

export function deleteFile(docId, event) {
  return async (dispatch) => {
    try {
      dispatch(asyncActionStart());

      let patchFunc = async () => {
        await collection.doc(event.id).collection("files").doc(docId).delete();
      };

      await patchFunc();
    } catch (error) {
      dispatch(asyncActionError(error, "deleteFile"));
    }
  };
}

export function getNetworkingRoomsByEvent(eventId) {
  console.log("getNetworkingRoomsByEvent", eventId);
  return collection.doc(eventId).collection("networkingRooms").where("deleted", "==", false).orderBy("createdAt");
}

export function getNetworkingRoomById(networkingRoomId, eventId) {
  console.log("getNetworkingRoomById", networkingRoomId);
  return collection.doc(eventId).collection("networkingRooms").doc(networkingRoomId);
}

export function updateNetworkingRoom(networkingRoom, eventId, key = "") {
  return async (dispatch) => {
    try {
      dispatch(asyncActionStart());
      let date = firebase.firestore.FieldValue.serverTimestamp();
      let newId = collection.doc(eventId).collection("networkingRooms").doc().id;

      let updateFunc = async () => {
        console.log("networkingRoom", networkingRoom, eventId);
        if (!networkingRoom?.createdAt) {
          networkingRoom.createdAt = date;
        }
        if (networkingRoom.id) {
          let id = networkingRoom.id;
          delete networkingRoom["id"];
          await collection
            .doc(eventId)
            .collection("networkingRooms")
            .doc(id)
            .update({ ...networkingRoom, updatedAt: date });
          toast.success(`Networking Room updated`);
        } else {
          await collection
            .doc(eventId)
            .collection("networkingRooms")
            .doc(newId)
            .set({ ...networkingRoom, updatedAt: date }, { merge: true });
          toast.success(`Networking Room created`);
        }

        dispatch(asyncActionFinish());
      };

      let savePath = `public/events/${eventId}/networkingRooms/${networkingRoom?.id || newId}/documents`;

      dispatch(setProgress(0));
      if (key) {
        if (networkingRoom[key]?.tempVideoBackground) {
          dispatch(setShowProgress(true));
          uploadToFirebaseStorage(
            true,
            networkingRoom[key].tempVideoBackground,
            "videoBackground",
            (progress) => {
              dispatch(setProgress(progress));
            },
            async (downloadURL) => {
              networkingRoom = { ...networkingRoom };
              networkingRoom[key] = {
                ...networkingRoom[key],
                videoBackground: downloadURL,
              };
              const thumbnails = await getThumbnails(networkingRoom[key].tempVideoBackground, {
                quality: 0.6,
                scale: 1,
              });
              dispatch(setShowProgress(true));
              uploadToFirebaseStorage(
                true,
                thumbnails[0].blob,
                "imageBackground",
                (progress) => {
                  dispatch(setProgress(progress));
                },
                async (downloadURL2) => {
                  networkingRoom[key] = {
                    ...networkingRoom[key],
                    imageBackground: downloadURL2,
                  };
                  delete networkingRoom[key]["tempVideoBackground"];
                  await updateFunc();
                },
                savePath,
                ".jpg"
              );
            },
            savePath
          );
        } else if (networkingRoom[key]?.tempImageBackground) {
          dispatch(setShowProgress(true));
          uploadToFirebaseStorage(
            true,
            networkingRoom[key]?.tempImageBackground,
            "imageBackground",
            (progress) => {
              dispatch(setProgress(progress));
            },
            async (downloadURL2) => {
              networkingRoom = { ...networkingRoom };
              networkingRoom[key] = {
                ...networkingRoom[key],
                imageBackground: downloadURL2,
              };
              if (networkingRoom[key].videoBackground) {
                deleteFileByUrl(networkingRoom[key].videoBackground);
              }
              delete networkingRoom[key]["videoBackground"];
              delete networkingRoom[key]["tempImageBackground"];
              await updateFunc();
            },
            savePath
          );
        } else {
          await updateFunc();
        }
      } else {
        if (networkingRoom.tempLogo || networkingRoom.tempCover || networkingRoom.tempThumbnail) {
          if (networkingRoom.tempLogo) {
            dispatch(setShowProgress(true));
            uploadToFirebaseStorage(
              true,
              networkingRoom.tempLogo,
              "logo",
              (progress) => {
                dispatch(setProgress(progress));
              },
              async (downloadURL) => {
                networkingRoom = { ...networkingRoom, logo: downloadURL };
                delete networkingRoom["tempLogo"];
                delete networkingRoom["tempLogoURL"];

                if (networkingRoom.tempCover) {
                  dispatch(setShowProgress(true));
                  uploadToFirebaseStorage(
                    true,
                    networkingRoom.tempCover,
                    "cover",
                    (progress) => {
                      dispatch(setProgress(progress));
                    },
                    async (downloadURL2) => {
                      networkingRoom = { ...networkingRoom, cover: downloadURL2 };
                      delete networkingRoom["tempCover"];
                      delete networkingRoom["tempCoverURL"];
                      if (networkingRoom.tempThumbnail) {
                        dispatch(setShowProgress(true));
                        uploadToFirebaseStorage(
                          true,
                          networkingRoom.tempCover,
                          "thumbnail",
                          (progress) => {
                            dispatch(setProgress(progress));
                          },
                          async (downloadURL3) => {
                            networkingRoom = { ...networkingRoom, thumbnail: downloadURL3 };
                            delete networkingRoom["tempThumbnail"];
                            delete networkingRoom["tempThumbnailURL"];
                            await updateFunc();
                          },
                          savePath
                        );
                      } else {
                        await updateFunc();
                      }
                    },
                    savePath
                  );
                } else if (networkingRoom.tempThumbnail) {
                  dispatch(setShowProgress(true));
                  uploadToFirebaseStorage(
                    true,
                    networkingRoom.tempThumbnail,
                    "thumbnail",
                    (progress) => {
                      dispatch(setProgress(progress));
                    },
                    async (downloadURL3) => {
                      networkingRoom = { ...networkingRoom, thumbnail: downloadURL3 };
                      delete networkingRoom["tempThumbnail"];
                      delete networkingRoom["tempThumbnailURL"];
                      await updateFunc();
                    },
                    savePath
                  );
                } else {
                  await updateFunc();
                }
              },
              savePath
            );
          } else if (networkingRoom.tempCover) {
            dispatch(setShowProgress(true));
            uploadToFirebaseStorage(
              true,
              networkingRoom.tempCover,
              "cover",
              (progress) => {
                dispatch(setProgress(progress));
              },
              async (downloadURL2) => {
                networkingRoom = { ...networkingRoom, cover: downloadURL2 };
                delete networkingRoom["tempCover"];
                delete networkingRoom["tempCoverURL"];
                if (networkingRoom.tempThumbnail) {
                  dispatch(setShowProgress(true));
                  uploadToFirebaseStorage(
                    true,
                    networkingRoom.tempThumbnail,
                    "thumbnail",
                    (progress) => {
                      dispatch(setProgress(progress));
                    },
                    async (downloadURL3) => {
                      networkingRoom = { ...networkingRoom, thumbnail: downloadURL3 };
                      delete networkingRoom["tempThumbnail"];
                      delete networkingRoom["tempThumbnailURL"];
                      await updateFunc();
                    },
                    savePath
                  );
                } else {
                  await updateFunc();
                }
              },
              savePath
            );
          } else {
            dispatch(setShowProgress(true));
            uploadToFirebaseStorage(
              true,
              networkingRoom.tempThumbnail,
              "thumbnail",
              (progress) => {
                dispatch(setProgress(progress));
              },
              async (downloadURL3) => {
                networkingRoom = { ...networkingRoom, thumbnail: downloadURL3 };
                delete networkingRoom["tempThumbnail"];
                delete networkingRoom["tempThumbnailURL"];
                await updateFunc();
              },
              savePath
            );
          }
        } else {
          await updateFunc();
        }
        // if (networkingRoom.tempLogo) {
        //   dispatch(setShowProgress(true));
        //   uploadToFirebaseStorage(true,
        //     networkingRoom.tempLogo,
        //     "logo",
        //     (progress) => {
        //       dispatch(setProgress(progress));
        //     },
        //     async (downloadURL2) => {
        //       networkingRoom = { ...networkingRoom, logo: downloadURL2 };
        //       delete networkingRoom["tempLogo"];
        //       delete networkingRoom["tempLogoURL"];
        //       await updateFunc();
        //     },
        //     savePath
        //   );
        // } else {
        //   await updateFunc();
        // }
      }
    } catch (error) {
      dispatch(asyncActionError(error, "updateNetworkingRoom"));
    }
  };
}

export function patchNetworkingRoom(id, eventId, data, deletes = [], showAlert = true) {
  console.log("patchNetworkingRoom", id);
  return async (dispatch) => {
    try {
      dispatch(asyncActionStart());
      let date = firebase.firestore.FieldValue.serverTimestamp();

      console.log("patchNetworkingRoom data", data);

      await collection
        .doc(eventId)
        .collection("networkingRooms")
        .doc(id)
        .update({ ...data, updatedAt: date });
      for (let i = 0; i < deletes.length; i++) {
        await deleteFileByUrl(deletes[i]);
      }
      if (showAlert) toast.success(`Networking Room updated`);
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError(error, "patchNetworkingRoom"));
    }
  };
}

export function patchNetworkingRoomFileByKey(networkingRoomId, eventId, key, field, file) {
  return async (dispatch) => {
    try {
      dispatch(asyncActionStart());
      let date = firebase.firestore.FieldValue.serverTimestamp();
      let savePath = `public/events/${eventId}/networkingRooms/${networkingRoomId}/documents`;

      console.log("patchNetworkingRoomFileByKey", key, field, file);

      if (field === "musicBackground") {
        dispatch(setShowProgress(true));
        uploadToFirebaseStorage(
          true,
          file,
          "musicBackground",
          (progress) => {
            dispatch(setProgress(progress));
          },
          async (downloadURL) => {
            await collection
              .doc(eventId)
              .collection("networkingRooms")
              .doc(networkingRoomId)
              .update({ [`${key}.${field}`]: downloadURL, updatedAt: date });
          },
          savePath
        );
      }

      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError(error, "patchNetworkingRoomFileByKey"));
    }
  };
}

// Rooms Start
export function getRoomsByEvent(eventId) {
  console.log("getRoomsByEvent", eventId);
  return collection.doc(eventId).collection("rooms").where("deleted", "==", false).orderBy("createdAt");
}

export function getRoomById(eventId, roomId) {
  console.log("getRoomById", roomId);
  return collection.doc(eventId).collection("rooms").doc(roomId);
}

export function getRoomChildByRoom(eventId, roomId) {
  console.log("getRoomChildByRoom", roomId);
  return collection.doc(eventId).collection("rooms").doc(roomId).collection("children").where("deleted", "==", false).orderBy("createdAt");
}

export function updateRoom(room, eventId, key = "") {
  return async (dispatch) => {
    try {
      dispatch(asyncActionStart());
      let date = firebase.firestore.FieldValue.serverTimestamp();
      let newId = collection.doc(eventId).collection("rooms").doc().id;

      let updateFunc = async () => {
        console.log("room", room, eventId);
        if (!room?.createdAt) {
          room.createdAt = date;
        }
        if (room.id) {
          let id = room.id;
          delete room["id"];
          await collection
            .doc(eventId)
            .collection("rooms")
            .doc(id)
            .update({ ...room, updatedAt: date });
          toast.success(`Room updated`);
        } else {
          await collection
            .doc(eventId)
            .collection("rooms")
            .doc(newId)
            .set({ ...room, updatedAt: date, deleted: false }, { merge: true });
          toast.success(`Room created`);
        }

        dispatch(asyncActionFinish());
      };

      let savePath = `public/events/${eventId}/rooms/${room?.id || newId}/documents`;

      dispatch(setProgress(0));
      if (key) {
        if (room[key]?.tempVideoBackground) {
          dispatch(setShowProgress(true));
          uploadToFirebaseStorage(
            true,
            room[key].tempVideoBackground,
            "videoBackground",
            (progress) => {
              dispatch(setProgress(progress));
            },
            async (downloadURL) => {
              room = { ...room };
              room[key] = {
                ...room[key],
                videoBackground: downloadURL,
              };
              const thumbnails = await getThumbnails(room[key].tempVideoBackground, {
                quality: 0.6,
                scale: 1,
              });
              dispatch(setShowProgress(true));
              uploadToFirebaseStorage(
                true,
                thumbnails[0].blob,
                "imageBackground",
                (progress) => {
                  dispatch(setProgress(progress));
                },
                async (downloadURL2) => {
                  room[key] = {
                    ...room[key],
                    imageBackground: downloadURL2,
                  };
                  delete room[key]["tempVideoBackground"];
                  await updateFunc();
                },
                savePath,
                ".jpg"
              );
            },
            savePath
          );
        } else if (room[key]?.tempImageBackground) {
          dispatch(setShowProgress(true));
          uploadToFirebaseStorage(
            true,
            room[key]?.tempImageBackground,
            "imageBackground",
            (progress) => {
              dispatch(setProgress(progress));
            },
            async (downloadURL2) => {
              room = { ...room };
              room[key] = {
                ...room[key],
                imageBackground: downloadURL2,
              };
              if (room[key].videoBackground) {
                deleteFileByUrl(room[key].videoBackground);
              }
              delete room[key]["videoBackground"];
              delete room[key]["tempImageBackground"];
              await updateFunc();
            },
            savePath
          );
        } else {
          await updateFunc();
        }
      } else {
        if (room.tempLogo || room.tempCover) {
          if (room.tempLogo) {
            dispatch(setShowProgress(true));
            uploadToFirebaseStorage(
              true,
              room.tempLogo,
              "logo",
              (progress) => {
                dispatch(setProgress(progress));
              },
              async (downloadURL) => {
                room = { ...room, logo: downloadURL };
                delete room["tempLogo"];
                delete room["tempLogoURL"];

                if (room.tempCover) {
                  dispatch(setShowProgress(true));
                  uploadToFirebaseStorage(
                    true,
                    room.tempCover,
                    "cover",
                    (progress) => {
                      dispatch(setProgress(progress));
                    },
                    async (downloadURL2) => {
                      room = { ...room, cover: downloadURL2 };
                      delete room["tempCover"];
                      delete room["tempCoverURL"];
                      await updateFunc();
                    },
                    savePath
                  );
                } else {
                  await updateFunc();
                }
              },
              savePath
            );
          } else {
            dispatch(setShowProgress(true));
            uploadToFirebaseStorage(
              true,
              room.tempCover,
              "cover",
              (progress) => {
                dispatch(setProgress(progress));
              },
              async (downloadURL2) => {
                room = { ...room, cover: downloadURL2 };
                delete room["tempCover"];
                delete room["tempCoverURL"];
                await updateFunc();
              },
              savePath
            );
          }
        } else {
          await updateFunc();
        }
      }
    } catch (error) {
      dispatch(asyncActionError(error, "updateRoom"));
    }
  };
}

export function updateRoomChild(eventId, roomId, roomChild, key = "") {
  return async (dispatch) => {
    try {
      dispatch(asyncActionStart());
      let date = firebase.firestore.FieldValue.serverTimestamp();
      let newId = collection.doc(eventId).collection("rooms").doc(roomId).collection("children").doc().id;

      let updateFunc = async () => {
        console.log("updateRoomChild", roomChild, eventId);
        if (!roomChild?.createdAt) {
          roomChild.createdAt = date;
        }
        if (roomChild.id) {
          let id = roomChild.id;
          delete roomChild["id"];
          await collection
            .doc(eventId)
            .collection("rooms")
            .doc(roomId)
            .collection("children")
            .doc(id)
            .update({ ...roomChild, updatedAt: date });
          toast.success(`Item updated`);
        } else {
          await collection
            .doc(eventId)
            .collection("rooms")
            .doc(roomId)
            .collection("children")
            .doc(newId)
            .set({ ...roomChild, updatedAt: date, deleted: false }, { merge: true });
          toast.success(`Item created`);
        }

        dispatch(asyncActionFinish());
      };

      let savePath = `public/events/${eventId}/rooms/${roomId}/children/${roomChild?.id || newId}/documents`;

      dispatch(setProgress(0));
      if (key) {
        if (roomChild[key]?.tempVideoBackground) {
          dispatch(setShowProgress(true));
          uploadToFirebaseStorage(
            true,
            roomChild[key].tempVideoBackground,
            "videoBackground",
            (progress) => {
              dispatch(setProgress(progress));
            },
            async (downloadURL) => {
              roomChild = { ...roomChild };
              roomChild[key] = {
                ...roomChild[key],
                videoBackground: downloadURL,
              };
              const thumbnails = await getThumbnails(roomChild[key].tempVideoBackground, {
                quality: 0.6,
                scale: 1,
              });
              dispatch(setShowProgress(true));
              uploadToFirebaseStorage(
                true,
                thumbnails[0].blob,
                "imageBackground",
                (progress) => {
                  dispatch(setProgress(progress));
                },
                async (downloadURL2) => {
                  roomChild[key] = {
                    ...roomChild[key],
                    imageBackground: downloadURL2,
                  };
                  delete roomChild[key]["tempVideoBackground"];
                  await updateFunc();
                },
                savePath,
                ".jpg"
              );
            },
            savePath
          );
        } else if (roomChild[key]?.tempImageBackground) {
          dispatch(setShowProgress(true));
          uploadToFirebaseStorage(
            true,
            roomChild[key]?.tempImageBackground,
            "imageBackground",
            (progress) => {
              dispatch(setProgress(progress));
            },
            async (downloadURL2) => {
              roomChild = { ...roomChild };
              roomChild[key] = {
                ...roomChild[key],
                imageBackground: downloadURL2,
              };
              if (roomChild[key].videoBackground) {
                deleteFileByUrl(roomChild[key].videoBackground);
              }
              delete roomChild[key]["videoBackground"];
              delete roomChild[key]["tempImageBackground"];
              await updateFunc();
            },
            savePath
          );
        } else {
          await updateFunc();
        }
      } else {
        if (roomChild.tempFile || roomChild.tempThumbnail) {
          if (roomChild.tempFile) {
            dispatch(setShowProgress(true));
            uploadToFirebaseStorage(
              true,
              roomChild.tempFile,
              "",
              (progress) => {
                dispatch(setProgress(progress));
              },
              async (downloadURL) => {
                roomChild = { ...roomChild, file: downloadURL };
                delete roomChild["tempFile"];
                delete roomChild["tempFileURL"];

                if (roomChild.tempThumbnail) {
                  dispatch(setShowProgress(true));
                  uploadToFirebaseStorage(
                    true,
                    roomChild.tempThumbnail,
                    "thumbnail",
                    (progress) => {
                      dispatch(setProgress(progress));
                    },
                    async (downloadURL2) => {
                      roomChild = { ...roomChild, thumbnail: downloadURL2 };
                      delete roomChild["tempThumbnail"];
                      delete roomChild["tempThumbnailURL"];
                      await updateFunc();
                    },
                    savePath
                  );
                } else {
                  await updateFunc();
                }
              },
              savePath
            );
          } else {
            dispatch(setShowProgress(true));
            uploadToFirebaseStorage(
              true,
              roomChild.tempThumbnail,
              "thumbnail",
              (progress) => {
                dispatch(setProgress(progress));
              },
              async (downloadURL2) => {
                roomChild = { ...roomChild, thumbnail: downloadURL2 };
                delete roomChild["tempThumbnail"];
                delete roomChild["tempThumbnailURL"];
                await updateFunc();
              },
              savePath
            );
          }
        } else {
          await updateFunc();
        }
      }
    } catch (error) {
      dispatch(asyncActionError(error, "updateRoomChild"));
    }
  };
}

export function patchRoom(id, eventId, data, deletes = [], showAlert = true) {
  console.log("patchRoom", id);
  return async (dispatch) => {
    try {
      dispatch(asyncActionStart());
      let date = firebase.firestore.FieldValue.serverTimestamp();

      console.log("patchRoom data", data);

      await collection
        .doc(eventId)
        .collection("rooms")
        .doc(id)
        .update({ ...data, updatedAt: date });
      for (let i = 0; i < deletes.length; i++) {
        await deleteFileByUrl(deletes[i]);
      }
      if (showAlert) toast.success(`Room updated`);
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError(error, "patchRoom"));
    }
  };
}

export function patchRoomFileByKey(roomId, eventId, key, field, file) {
  return async (dispatch) => {
    try {
      dispatch(asyncActionStart());
      let date = firebase.firestore.FieldValue.serverTimestamp();
      let savePath = `public/events/${eventId}/rooms/${roomId}/documents`;

      console.log("patchRoomFileByKey", key, field, file);

      if (field === "musicBackground") {
        dispatch(setShowProgress(true));
        uploadToFirebaseStorage(
          true,
          file,
          "musicBackground",
          (progress) => {
            dispatch(setProgress(progress));
          },
          async (downloadURL) => {
            await collection
              .doc(eventId)
              .collection("rooms")
              .doc(roomId)
              .update({ [`${key}.${field}`]: downloadURL, updatedAt: date });
          },
          savePath
        );
      }

      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError(error, "patchRoomFileByKey"));
    }
  };
}
// Rooms End

// Surveys Start
export function getSurveysByEvent(eventId) {
  console.log("getSurveysByEvent", eventId);
  return collection.doc(eventId).collection("surveys").where("deleted", "==", false).orderBy("createdAt");
}

export function getSurveyAnswers(eventId, surveyId) {
  console.log("getSurveyAnswers");
  return collection.doc(eventId).collection("surveys").doc(surveyId).collection("answers").orderBy("createdAt");
}

export function saveSurvey(event, survey) {
  return async (dispatch) => {
    try {
      dispatch(asyncActionStart());
      let date = firebase.firestore.FieldValue.serverTimestamp();
      let newId = collection.doc(event.id).collection("surveys").doc().id;

      let updateFunc = async () => {
        console.log("survey", survey, event.id);
        if (!survey?.createdAt) {
          survey.createdAt = date;
        }
        if (survey.id) {
          let id = survey.id;
          delete survey["id"];
          await collection
            .doc(event.id)
            .collection("surveys")
            .doc(id)
            .update({ ...survey, updatedAt: date });
          toast.success("Successfully updated survey");
        } else {
          await collection
            .doc(event.id)
            .collection("surveys")
            .doc(newId)
            .set(
              {
                ...survey,
                updatedAt: date,
                deleted: false,
              },
              { merge: true }
            );
          toast.success("Successfully added survey");
        }

        dispatch(asyncActionFinish());
      };

      dispatch(setProgress(0));
      await updateFunc();
    } catch (error) {
      dispatch(asyncActionError(error, "saveSurvey"));
    }
  };
}
// Surveys End

export function getBoothsByEvent(eventId) {
  console.log("getBoothsByEvent", eventId);
  return collection.doc(eventId).collection("booths").where("deleted", "==", false).orderBy("createdAt");
}

export function getBoothById(boothId, eventId) {
  console.log("getBoothById", boothId);
  return collection.doc(eventId).collection("booths").doc(boothId);
}

export function getBoothVisitorsById(eventId, boothId) {
  console.log("getBoothVisitorsById", boothId);
  return collection.doc(eventId).collection("booths").doc(boothId).collection("visitors");
}

export function saveBoothVisitor(eventId, boothId, user) {
  return async (dispatch) => {
    try {
      // dispatch(asyncActionStart());
      let date = firebase.firestore.FieldValue.serverTimestamp();
      let docRef = collection.doc(eventId).collection("booths").doc(boothId).collection("visitors").doc(user.uid);

      let snapshot = await docRef.get();

      if (snapshot.exists) {
        docRef.update({
          displayName: user.displayName,
          email: user.email,
          contactNumber: user.contactNumber,
          society: user.society,
          updatedAt: date,
        });
      } else {
        docRef.set({
          uid: user.uid,
          createdAt: date,
          displayName: user.displayName,
          email: user.email,
          contactNumber: user.contactNumber,
          society: user.society,
        });
      }
      // dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError(error, "saveBoothVisitor"));
    }
  };
}

export function updateBooth(booth, eventId, key = "") {
  return async (dispatch) => {
    try {
      dispatch(asyncActionStart());
      let date = firebase.firestore.FieldValue.serverTimestamp();
      let newId = collection.doc(eventId).collection("booths").doc().id;

      let updateFunc = async () => {
        console.log("booth", booth, eventId);
        if (!booth?.createdAt) {
          booth.createdAt = date;
        }
        if (booth.id) {
          let id = booth.id;
          delete booth["id"];
          await collection
            .doc(eventId)
            .collection("booths")
            .doc(id)
            .update({ ...booth, updatedAt: date });
          toast.success(`Booth updated`);
        } else {
          await collection
            .doc(eventId)
            .collection("booths")
            .doc(newId)
            .set({ ...booth, updatedAt: date }, { merge: true });
          toast.success(`Booth created`);
        }

        dispatch(asyncActionFinish());
      };

      let savePath = `public/events/${eventId}/booths/${booth?.id || newId}/documents`;

      dispatch(setProgress(0));
      if (key) {
        if (booth[key]?.tempVideoBackground) {
          dispatch(setShowProgress(true));
          uploadToFirebaseStorage(
            true,
            booth[key].tempVideoBackground,
            "videoBackground",
            (progress) => {
              dispatch(setProgress(progress));
            },
            async (downloadURL) => {
              booth = { ...booth };
              booth[key] = {
                ...booth[key],
                videoBackground: downloadURL,
              };
              const thumbnails = await getThumbnails(booth[key].tempVideoBackground, {
                quality: 0.6,
                scale: 1,
              });
              dispatch(setShowProgress(true));
              uploadToFirebaseStorage(
                true,
                thumbnails[0].blob,
                "imageBackground",
                (progress) => {
                  dispatch(setProgress(progress));
                },
                async (downloadURL2) => {
                  booth[key] = {
                    ...booth[key],
                    imageBackground: downloadURL2,
                  };
                  delete booth[key]["tempVideoBackground"];
                  await updateFunc();
                },
                savePath,
                ".jpg"
              );
            },
            savePath
          );
        } else if (booth[key]?.tempImageBackground) {
          dispatch(setShowProgress(true));
          uploadToFirebaseStorage(
            true,
            booth[key]?.tempImageBackground,
            "imageBackground",
            (progress) => {
              dispatch(setProgress(progress));
            },
            async (downloadURL2) => {
              booth = { ...booth };
              booth[key] = {
                ...booth[key],
                imageBackground: downloadURL2,
              };
              if (booth[key].videoBackground) {
                deleteFileByUrl(booth[key].videoBackground);
              }
              delete booth[key]["videoBackground"];
              delete booth[key]["tempImageBackground"];
              await updateFunc();
            },
            savePath
          );
        } else {
          await updateFunc();
        }
      } else {
        if (booth.tempLogo) {
          dispatch(setShowProgress(true));
          uploadToFirebaseStorage(
            true,
            booth.tempLogo,
            "logo",
            (progress) => {
              dispatch(setProgress(progress));
            },
            async (downloadURL2) => {
              booth = { ...booth, logo: downloadURL2 };
              delete booth["tempLogo"];
              delete booth["tempLogoURL"];
              await updateFunc();
            },
            savePath
          );
        } else {
          await updateFunc();
        }
      }
    } catch (error) {
      dispatch(asyncActionError(error, "updateBooth"));
    }
  };
}

export function patchBooth(id, eventId, data, deletes = []) {
  return async (dispatch) => {
    try {
      dispatch(asyncActionStart());
      let date = firebase.firestore.FieldValue.serverTimestamp();

      console.log("patchBooth", data);

      await collection
        .doc(eventId)
        .collection("booths")
        .doc(id)
        .update({ ...data, updatedAt: date });
      for (let i = 0; i < deletes.length; i++) {
        await deleteFileByUrl(deletes[i]);
      }
      toast.success(`Booth updated`);
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError(error, "patchBooth"));
    }
  };
}

export function patchBoothFileByKey(boothId, eventId, key, field, file) {
  return async (dispatch) => {
    try {
      dispatch(asyncActionStart());
      let date = firebase.firestore.FieldValue.serverTimestamp();
      let savePath = `public/events/${eventId}/booths/${boothId}/documents`;

      console.log("patchBoothFileByKey", key, field, file);

      if (field === "musicBackground") {
        dispatch(setShowProgress(true));
        uploadToFirebaseStorage(
          true,
          file,
          "musicBackground",
          (progress) => {
            dispatch(setProgress(progress));
          },
          async (downloadURL) => {
            await collection
              .doc(eventId)
              .collection("booths")
              .doc(boothId)
              .update({ [`${key}.${field}`]: downloadURL, updatedAt: date });
          },
          savePath
        );
      }

      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError(error, "patchBoothFileByKey"));
    }
  };
}

export function saveAttendee(event, ticket, attendee, showAlert = true, message = "Successfully joined event") {
  return async (dispatch) => {
    try {
      dispatch(asyncActionStart());
      let date = firebase.firestore.FieldValue.serverTimestamp();
      // let newId = collection.doc(event.id).collection("attendees").doc().id;

      let updateFunc = async () => {
        console.log("attendee", attendee, event.id);
        if (!attendee?.createdAt) {
          attendee.createdAt = date;
        }
        if (attendee.id) {
          let id = attendee.id;
          delete attendee["id"];
          await collection
            .doc(event.id)
            .collection("attendees")
            .doc(id)
            .update({ ...attendee, updatedAt: date });
        } else {
          await collection
            .doc(event.id)
            .collection("attendees")
            .doc(attendee.uid)
            .set(
              {
                ...attendee,
                passportComplete: false,
                updatedAt: date,
                deleted: false,
              },
              { merge: true }
            );
          // await collectionUserEvents.add({
          //   event: `/events/${event.id}`,
          //   user: `/users/${attendee.uid}`,
          //   attendee: `/events/${event.id}/attendees/${attendee.uid}`,
          //   eventId: event.id,
          //   userId: attendee.uid,
          //   ticket: {
          //     id: ticket.id,
          //     name: ticket.name,
          //     description: ticket.description,
          //     price: ticket.price,
          //     currency: ticket.currency || "",
          //   },
          //   createdAt: date,
          //   deleted: false,
          // });
        }

        // for (let property in attendee.customFields) {
        //   console.log("property", property);
        //   console.log("isArray", Array.isArray(attendee.customFields[property]));
        //   if (Array.isArray(attendee.customFields[property])) {
        //     let parent = {
        //       id: attendee.id || attendee.uid,
        //       displayName: attendee.displayName,
        //     };
        //     let companion = attendee.customFields[property].map((companion) => ({
        //       firstName: companion["First Name"],
        //       lastName: companion["Last Name"],
        //       age: companion["Age"],
        //       affiliation: companion["Affiliation"],
        //       affiliationOthers: companion["Affiliation Others"] || "",
        //     }));
        //     await dispatch(saveCompanions(event, ticket, parent, companion, false));
        //     break;
        //   }
        // }
        // await dispatch(
        //   patchProfile(attendee.uid, {
        //     events: getFirebase().firestore.FieldValue.arrayUnion(event.id),
        //   })
        // );
        if (showAlert) {
          toast.success(message);
        }

        dispatch(asyncActionFinish());
      };

      dispatch(setProgress(0));
      await updateFunc();
    } catch (error) {
      dispatch(asyncActionError(error, "saveAttendee"));
    }
  };
}

export function saveAttendees(event, ticket, attendees = [], showAlert = true, message = "Successfully joined event") {
  return async (dispatch) => {
    try {
      dispatch(asyncActionStart());

      // let batchUserEvents = db.batch();
      // let batchUserProfiles = db.batch();
      let date = firebase.firestore.FieldValue.serverTimestamp();
      // let newId = collection.doc(event.id).collection("attendees").doc().id;

      let updateFunc = async () => {
        // let chunks = chunkify(attendees, Math.ceil(attendees.length / 500), true);
        let chunks = _.chunk(attendees, 450);

        console.log("chunks", chunks);

        for (let j = 0; j < chunks.length; j++) {
          let batchAttendees = db.batch();

          for (let i = 0; i < chunks[j].length; i++) {
            let attendee = chunks[j][i];
            let attendeeRef = collection.doc(event.id).collection("attendees").doc(attendee.uid);

            batchAttendees.set(attendeeRef, {
              ...attendee,
              passportComplete: false,
              createdAt: date,
              deleted: false,
            });
          }

          await batchAttendees.commit();
        }

        // attendees.forEach((attendee) => {
        //   console.log("attendee", attendee, event.id);
        //   let attendeeRef = collection.doc(event.id).collection("attendees").doc(attendee.uid);

        //   batchAttendees.set(attendeeRef, {
        //     ...attendee,
        //     passportComplete: false,
        //     createdAt: date,
        //     deleted: false,
        //   });

        // let userEventRef = collectionUserEvents.doc();
        // batchUserEvents.set(userEventRef, {
        //   event: `/events/${event.id}`,
        //   user: `/users/${attendee.uid}`,
        //   attendee: `/events/${event.id}/attendees/${attendee.uid}`,
        //   eventId: event.id,
        //   userId: attendee.uid,
        //   ticket: {
        //     id: ticket.id,
        //     name: ticket.name,
        //     description: ticket.description,
        //     price: ticket.price,
        //     currency: ticket.currency || "",
        //   },
        //   createdAt: date,
        //   deleted: false,
        // });

        // let userProfileRef = collectionUsers.doc(attendee.uid);
        // batchUserProfiles.update(userProfileRef, {
        //   events: getFirebase().firestore.FieldValue.arrayUnion(event.id),
        // });
        // });
        // batchAttendees.commit();
        // batchUserEvents.commit();
        // batchUserProfiles.commit();

        if (showAlert) {
          toast.success(message);
        }

        dispatch(asyncActionFinish());
      };

      dispatch(setProgress(0));
      await updateFunc();
    } catch (error) {
      dispatch(asyncActionError(error, "saveAttendees"));
    }
  };
}

export function saveCompanions(event, ticket, parent, companion = [], showAlert = true, message = "Successfully joined event") {
  return async (dispatch) => {
    try {
      dispatch(asyncActionStart());
      let batchCompanions = db.batch();
      let date = firebase.firestore.FieldValue.serverTimestamp();
      // let newId = collection.doc(event.id).collection("attendees").doc().id;

      let updateFunc = async () => {
        console.log("companion", companion);
        companion.forEach((companion) => {
          console.log("companion", companion, event.id);
          let companionRef = collection.doc(event.id).collection("companion").doc(generateUid());

          batchCompanions.set(companionRef, {
            ...companion,
            parentId: parent.id,
            parentDisplayName: parent.displayName,
            passportComplete: false,
            createdAt: date,
            deleted: false,
          });
        });
        batchCompanions.commit();

        if (showAlert) {
          toast.success(message);
        }

        dispatch(asyncActionFinish());
      };

      dispatch(setProgress(0));
      await updateFunc();
    } catch (error) {
      dispatch(asyncActionError(error, "saveCompanions"));
    }
  };
}

export function patchAttendee(id, eventId, data, deletes = [], showAlert = false) {
  return async (dispatch) => {
    try {
      dispatch(asyncActionStart());
      let date = firebase.firestore.FieldValue.serverTimestamp();

      console.log("patchAttendee", id, eventId, data);

      let userEventUpdateDoc = {
        updatedAt: date,
      };
      if (data.deleted && eventId) {
        dispatch(
          patchProfile(id, {
            events: getFirebase().firestore.FieldValue.arrayRemove(eventId),
          })
        );
        await collection.doc(eventId).collection("attendees").doc(id).delete();
        userEventUpdateDoc.deleted = true;

        // let userEvents = await collectionUserEvents.where("eventId", "==", eventId).where("userId", "==", id).get();

        // userEvents.docs.forEach((userEventDoc) => {
        //   console.log("userEventDoc", userEventDoc.data());
        //   userEventDoc.ref.update(userEventUpdateDoc);
        // });
      } else if (eventId) {
        let snap = await collection.doc(eventId).collection("attendees").doc(id).get();
        if (snap.exists) {
          await snap.ref.update({ ...data, updatedAt: date });
        }
      }

      if (deletes) {
        for (let i = 0; i < deletes.length; i++) {
          await deleteFileByUrl(deletes[i]);
        }
      }

      if (showAlert) toast.success("Attendee Updated");
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError(error, "patchAttendee"));
    }
  };
}

export function patchAttendee2(id, eventId, data, showAlert = false) {
  return async (dispatch) => {
    try {
      dispatch(asyncActionStart());

      console.log("patchAttendee2", id, eventId, data);
      if (eventId) {
        let snap = await collection.doc(eventId).collection("attendees").doc(id).get();
        if (snap.exists) {
          await snap.ref.update({ ...data });
        }
      }

      if (showAlert) toast.success("Attendee Updated");
    } catch (error) {
      dispatch(asyncActionError(error, "patchAttendee"));
    }
  };
}

export function sendCodeToEmail(eventId, attendeeId, actionStart = true) {
  return async (dispatch) => {
    if (actionStart) {
      dispatch(asyncActionStart());
    }

    let firebase = getFirebase();
    // firebase.functions().useEmulator("localhost", 5001);
    let myFunction = firebase.functions().httpsCallable("sendCodeToEmail");
    try {
      let result = await myFunction({ eventId, attendeeId });
      if (actionStart) {
        dispatch(asyncActionFinish());
      }
      console.log("result", result);
    } catch (error) {
      dispatch(asyncActionError(error));
      return null;
    }
  };
}

export function sendReminderToEmail(eventId, attendeeId, actionStart = true) {
  return async (dispatch) => {
    if (actionStart) {
      dispatch(asyncActionStart());
    }

    let firebase = getFirebase();
    // firebase.functions().useEmulator("localhost", 5001);
    let myFunction = firebase.functions().httpsCallable("sendReminderToEmail");
    try {
      let result = await myFunction({ eventId, attendeeId });
      if (actionStart) {
        dispatch(asyncActionFinish());
      }
      console.log("result", result);
    } catch (error) {
      dispatch(asyncActionError(error));
      return null;
    }
  };
}

export function patchCompanion(id, eventId, data, deletes = [], showAlert = false) {
  return async (dispatch) => {
    try {
      dispatch(asyncActionStart());
      let date = firebase.firestore.FieldValue.serverTimestamp();

      console.log("patchCompanion", data);

      let userEventUpdateDoc = {
        updatedAt: date,
      };
      if (data.deleted) {
        dispatch(
          patchProfile(id, {
            events: getFirebase().firestore.FieldValue.arrayRemove(eventId),
          })
        );
        await collection.doc(eventId).collection("companion").doc(id).delete();
        userEventUpdateDoc.deleted = true;

        // let userEvents = await collectionUserEvents.where("eventId", "==", eventId).where("userId", "==", id).get();

        // userEvents.docs.forEach((userEventDoc) => {
        //   console.log("userEventDoc", userEventDoc.data());
        //   userEventDoc.ref.update(userEventUpdateDoc);
        // });
      } else {
        let snap = await collection.doc(eventId).collection("companion").doc(id).get();
        if (!snap.exists) {
          await snap.ref.update({ ...data, updatedAt: date });
        }
      }

      if (deletes) {
        for (let i = 0; i < deletes.length; i++) {
          await deleteFileByUrl(deletes[i]);
        }
      }

      if (showAlert) toast.success("Companion Updated");
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError(error, "patchCompanion"));
    }
  };
}

export function getEventAttendees(eventId) {
  console.log("getEventAttendees", eventId);
  return collection.doc(eventId).collection("attendees").where("deleted", "==", false).orderBy("createdAt");
}

export function getEventAttendeeCompanions(eventId) {
  console.log("getEventAttendeeCompanions", eventId);
  return collection.doc(eventId).collection("companion").where("deleted", "==", false).orderBy("parentId").orderBy("createdAt");
}

export function getEventAttendeeCompanionsByAttendee(eventId, attendeeId) {
  console.log("getEventAttendeeCompanions", eventId);
  return collection.doc(eventId).collection("companion").where("uid", "==", attendeeId).where("deleted", "==", false).orderBy("parentId").orderBy("createdAt");
}

export function getEventAttendeeByUid(eventId, uid) {
  console.log("getEventAttendeeByUid", eventId, uid);
  return collection.doc(eventId).collection("attendees").where("uid", "==", uid).where("deleted", "==", false);
}

export function getEventAttendeeByEmail(eventId, email) {
  console.log("getEventAttendeeByEmail", eventId, email);
  return collection.doc(eventId).collection("attendees").where("email", "==", email).where("deleted", "==", false);
}

export function getUserEventsByUser() {
  const user = firebase.auth().currentUser;
  console.log("getUserEventsByUser");

  // (async () => {
  //   let data = await collectionUserEvents.get();

  //   data.docs.forEach((doc) => {
  //     console.log("userEvent", doc.data());
  //     doc.ref.update({
  //       deleted: false,
  //     });
  //   });
  // })();

  return collectionUserEvents.where("userId", "==", user.uid).where("deleted", "==", false);
}

export function getEventMessages(eventId) {
  return collection.doc(eventId).collection("messages").orderBy("createdAt", "desc").limit(500);
}

export function getEventPlenaryMessages(eventId) {
  return collection.doc(eventId).collection("plenaryMessages").orderBy("createdAt", "desc").limit(500);
}

export function getBoothMessages(eventId, boothId) {
  return collection.doc(eventId).collection("booths").doc(boothId).collection("messages").orderBy("createdAt");
}

export function getHelpMessages(eventId) {
  return collection.doc(eventId).collection("helpMessages").orderBy("createdAt");
}

export function sendEventMessage(eventId, message) {
  return async (dispatch) => {
    try {
      let date = firebase.firestore.FieldValue.serverTimestamp();

      await collection
        .doc(eventId)
        .collection("messages")
        .add({ ...message, createdAt: date });

      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError(error, "sendEventMessage"));
    }
  };
}

export function sendEventPlenaryMessage(eventId, message) {
  return async (dispatch) => {
    try {
      let date = firebase.firestore.FieldValue.serverTimestamp();

      await collection
        .doc(eventId)
        .collection("plenaryMessages")
        .add({ ...message, createdAt: date });

      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError(error, "sendEventPlenaryMessage"));
    }
  };
}

export function sendBoothMessage(eventId, boothId, message) {
  return async (dispatch) => {
    try {
      let date = firebase.firestore.FieldValue.serverTimestamp();

      await collection
        .doc(eventId)
        .collection("booths")
        .doc(boothId)
        .collection("messages")
        .add({ ...message, createdAt: date });

      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError(error, "sendBoothMessage"));
    }
  };
}

export function sendHelpMessage(eventId, message) {
  return async (dispatch) => {
    try {
      let date = firebase.firestore.FieldValue.serverTimestamp();

      await collection
        .doc(eventId)
        .collection("helpMessages")
        .add({ ...message, createdAt: date });

      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError(error, "sendHelpMessage"));
    }
  };
}

export function getUserEventFiles(eventId) {
  const user = firebase.auth().currentUser;
  return collectionUsers.doc(user.uid).collection("eventFiles").where("eventId", "==", eventId).orderBy("createdAt", "desc");
}

export function getBoothInvitesByEmail(email) {
  console.log("getBoothInvitesByEmail");
  if (!email) {
    const user = firebase.auth().currentUser;
    email = user.email;
  }
  return collectionBoothInvites.where("email", "==", email).where("updated", "==", false).orderBy("createdAt", "desc");
}

export function getBoothInvitesByBooth(boothId) {
  console.log("getBoothInvitesByBooth");
  return collectionBoothInvites.where("booth", "==", boothId).where("updated", "==", false).orderBy("createdAt", "desc");
}

export function addInvite(invite, booth, event) {
  return async (dispatch) => {
    try {
      dispatch(asyncActionStart());
      const user = firebase.auth().currentUser;
      let date = firebase.firestore.FieldValue.serverTimestamp();

      console.log("invite", invite);

      if (!invite?.createdAt) {
        invite.createdAt = date;
      }
      await collectionBoothInvites.add(
        {
          ...invite,
          updated: false,
          booth: booth.id,
          boothName: booth.name,
          boothLogo: booth.logo,
          event: event.id,
          eventName: event.name,
          updatedAt: date,
          createdBy: user.uid,
        },
        { merge: true }
      );

      toast.success(`Added a booth invite`);
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError(error, "addInvite"));
    }
  };
}

export function updateInvite(invite, user, accept = false) {
  return async (dispatch) => {
    try {
      dispatch(asyncActionStart());
      let date = firebase.firestore.FieldValue.serverTimestamp();

      console.log("invite", invite);

      let id = invite.id;
      delete invite["id"];
      await collectionBoothInvites.doc(id).update({ ...invite, updated: true, updatedAt: date });
      if (accept) {
        await collectionUserBooths.add({
          event: `/events/${invite.event}`,
          eventId: invite.event,
          user: `/users/${user.uid}`,
          userId: user.uid,
          booth: `/events/${invite.event}/booths/${invite.booth}`,
          boothId: invite.booth,
          deleted: false,
          createdAt: date,
        });
      }

      toast.success(`Booth invite updated`);
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError(error, "updateInvite"));
    }
  };
}

export function updateInviteTeam(invite, accept = false) {
  return async (dispatch) => {
    try {
      dispatch(asyncActionStart());
      const user = firebase.auth().currentUser;
      let date = firebase.firestore.FieldValue.serverTimestamp();

      console.log("invite", invite);

      let updateFunc = async () => {
        if (!invite?.createdAt) {
          invite.createdAt = date;
        }
        if (invite.id) {
          let id = invite.id;
          delete invite["id"];
          await invitesCollection.doc(id).update({ ...invite, updatedAt: date });
          if (accept) {
            await collection.doc(invite.event).update({
              team: getFirebase().firestore.FieldValue.arrayUnion(user.uid),
              teamDetails: getFirebase().firestore.FieldValue.arrayUnion({
                email: user.email,
                name: user.displayName,
                user: user.uid,
              }),
              updatedAt: date,
            });
          }
        } else {
          await invitesCollection.add({ ...invite, updatedAt: date, createdBy: user.uid }, { merge: true });
        }

        toast.success(`Invites updated`);
        dispatch(asyncActionFinish());
      };

      await updateFunc();
    } catch (error) {
      dispatch(asyncActionError(error, "updateInvite"));
    }
  };
}

export function getBoothUsersByBooth(boothId) {
  console.log("getBoothUsersByBooth");
  return collectionUserBooths.where("boothId", "==", boothId).where("deleted", "==", false);
}

export function getBoothUsersByUser() {
  console.log("getBoothUsersByUser");
  const user = firebase.auth().currentUser;
  return collectionUserBooths.where("userId", "==", user.uid).where("deleted", "==", false);
}

export function patchBoothUserById(id, data) {
  console.log("getBoothUsersByBooth");
  let date = firebase.firestore.FieldValue.serverTimestamp();
  return collectionUserBooths.doc(id).update({ ...data, updatedAt: date });
}

export function addBoothSurveyAnswers(eventId, boothId, data) {
  console.log("getBoothUsersByUser");
  let date = firebase.firestore.FieldValue.serverTimestamp();
  return collection
    .doc(eventId)
    .collection("booths")
    .doc(boothId)
    .collection("surveys")
    .add({
      ...data,
      createdAt: date,
    });
}

export function addEventSurveyAnswers(eventId, data) {
  console.log("getBoothUsersByUser");
  let date = firebase.firestore.FieldValue.serverTimestamp();
  return collection
    .doc(eventId)
    .collection("surveys")
    .add({
      ...data,
      createdAt: date,
    });
}

export function getBoothSurveyAnswers(eventId, boothId) {
  console.log("getBoothSurveyAnswers");
  return collection.doc(eventId).collection("booths").doc(boothId).collection("surveys").orderBy("createdAt");
}

export function getEventSurveyAnswers(eventId) {
  console.log("getBoothSurveyAnswers");
  return collection.doc(eventId).collection("surveys").orderBy("createdAt");
}

export function sendEventControls(eventId, data) {
  console.log("sendEventControls");
  let date = firebase.firestore.FieldValue.serverTimestamp();
  return collection
    .doc(eventId)
    .collection("controls")
    .add({
      ...data,
      createdAt: date,
    });
}

export function getEventControls(eventId) {
  console.log("getEventControls");
  return collection.doc(eventId).collection("controls").orderBy("createdAt", "desc");
}

export function sendBroadcastMessage(eventId, data) {
  let date = firebase.firestore.FieldValue.serverTimestamp();
  return collection
    .doc(eventId)
    .collection("broadcastMessages")
    .add({
      ...data,
      createdAt: date,
    });
}

export function saveBroadcastMessage(eventId, data) {
  return async (dispatch) => {
    try {
      dispatch(asyncActionStart());
      let date = firebase.firestore.FieldValue.serverTimestamp();
      if (data.id) {
        let docId = data.id;
        delete data["id"];
        await collection
          .doc(eventId)
          .collection("broadcastMessageTemplates")
          .doc(docId)
          .update({
            ...data,
            updatedAt: date,
          });
      } else {
        await collection
          .doc(eventId)
          .collection("broadcastMessageTemplates")
          .add({
            ...data,
            createdAt: date,
          });
      }
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError(error, "saveBroadcastMessage"));
    }
  };
}

export function deleteBroadcastMessage(eventId, id) {
  console.log("deleteBroadcastMessage", eventId, id);
  return async (dispatch) => {
    try {
      dispatch(asyncActionStart());
      await collection.doc(eventId).collection("broadcastMessageTemplates").doc(id).delete();

      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError(error, "saveBroadcastMessage"));
    }
  };
}

export function getBroadcastMessagesByTicketId(eventId, ticketId) {
  console.log("getBroadcastMessagesByTicketId");
  return collection
    .doc(eventId)
    .collection("broadcastMessages")
    .where("tickets", "array-contains", ticketId)
    .where("createdAt", ">=", firebase.firestore.Timestamp.fromDate(subMinutes(new Date(), 1)))
    .orderBy("createdAt", "desc");
}

export function getBroadcastMessageTemplates(eventId) {
  console.log("getBroadcastMessageTemplates");
  return collection.doc(eventId).collection("broadcastMessageTemplates").orderBy("createdAt", "desc");
}

export function getNotificationsByEventBooth(eventId, boothId) {
  return collection.doc(eventId).collection("booths").doc(boothId).collection("notifications").where("unread", "==", true).orderBy("createdAt", "desc");
}

export async function deleteNotificationsByEventBoothUser(eventId, boothId, userId) {
  const batch = db.batch();
  try {
    let notifications = await collection
      .doc(eventId)
      .collection("booths")
      .doc(boothId)
      .collection("notifications")
      .where("unread", "==", true)
      .where("user", "==", userId)
      .orderBy("createdAt", "desc")
      .get();

    notifications.docs.forEach((doc) => {
      batch.delete(collection.doc(eventId).collection("booths").doc(boothId).collection("notifications").doc(doc.id));
    });

    return await batch.commit();
  } catch (error) {
    console.log(error);
  }
}
export async function clearEventData(eventId, ticketId) {
  console.log("clearEventData");
  let batch = db.batch();

  let attendees = await collection.doc(eventId).collection("attendees").get();
  attendees.docs.forEach(async (user) => {
    batch.update(collection.doc(eventId).collection("attendees").doc(user.id), {
      passportComplete: false,
    });
  });
  batch.commit();

  let batch1 = db.batch();
  attendees.docs.forEach(async (user) => {
    batch1.delete(collectionUsers.doc(user.id).collection("eventPassport").doc(eventId));
  });
  batch1.commit();

  let booths = await collection.doc(eventId).collection("booths").get();

  booths.docs.forEach(async (booth) => {
    let batch2 = db.batch();

    let boothMsgs = await collection.doc(eventId).collection("booths").doc(booth.id).collection("messages").get();

    boothMsgs.forEach(async (boothMsg) => {
      batch2.delete(collection.doc(eventId).collection("booths").doc(booth.id).collection("messages").doc(boothMsg.id));
    });

    let boothSurveys = await collection.doc(eventId).collection("booths").doc(booth.id).collection("surveys").get();

    boothSurveys.forEach(async (boothSurvey) => {
      batch2.delete(collection.doc(eventId).collection("booths").doc(booth.id).collection("surveys").doc(boothSurvey.id));
    });
    batch2.commit();
  });
}

// Start Analytics Events
export function addAnalyticsEvent(event, user, logType = LogTypesEnum.EVENT, action = "visits", others = {}) {
  console.log("addAnalyticsEvent", event.id, user.uid, logType);
  return async (dispatch, getState) => {
    try {
      let { appMetadata } = getState().app;
      console.log("appMetadata", appMetadata);
      if (!appMetadata?.analytics[logType]) {
        return;
      }
      let date = getFirebase().firestore.FieldValue.serverTimestamp();
      let currentIp = await getCurrentIp();
      let unique = isAnalyticsUniqueEntry(currentIp, user.uid, logType);

      let _collection = action;
      let _append = logType.charAt(0).toUpperCase() + logType.slice(1);
      console.log("_append", _append);
      let totalVisits = "total";
      let totalUniqueVisits = "totalUnique";

      let updateObj = null;
      if (logType === LogTypesEnum.EVENT) {
        if (action === "visits") {
          totalVisits += "Visits";
          totalUniqueVisits += "Visits";
        } else if (action === "downloads" || action === "viewImages" || action === "viewVideos" || action === "viewFiles") {
          totalVisits = action + `;${others.file.id}`;
          updateObj = {
            [totalVisits + ".id"]: others.file.id,
            [totalVisits + ".fileName"]: others.file.fileName,
            [totalVisits + ".total"]: firebase.firestore.FieldValue.increment(1),
          };
        } else {
          _collection += _append;
          totalVisits += _collection.charAt(0).toUpperCase() + _collection.slice(1);
          totalUniqueVisits += _collection.charAt(0).toUpperCase() + _collection.slice(1);
        }
      } else if (logType !== LogTypesEnum.EVENT) {
        if (action === "downloads" || action === "viewImages" || action === "viewVideos" || action === "viewFiles") {
          _collection += _append;
          totalVisits = _collection + `;${others.file.id}`;
          updateObj = {
            [totalVisits + ".id"]: others.file.id,
            [totalVisits + ".fileName"]: others.file.fileName,
            [totalVisits + ".total"]: firebase.firestore.FieldValue.increment(1),
          };
        } else {
          _collection += _append;
          totalVisits += _collection.charAt(0).toUpperCase() + _collection.slice(1);
          totalUniqueVisits += _collection.charAt(0).toUpperCase() + _collection.slice(1);
        }
      }

      console.log("_collection", _collection, "_append", _append);
      console.log("totalVisits", totalVisits, "totalUniqueVisits", totalUniqueVisits);
      await collectionAnalyticsEvents
        .doc(event.id)
        .collection(_collection)
        .add({
          ip: currentIp,
          unique,
          uid: user.uid,
          displayName: user.displayName,
          email: user.email,
          ...(action !== "visits" && { ...others }),
          createdAt: date,
          updatedAt: date,
        });
      await collectionUsers
        .doc(user.uid)
        .collection("events")
        .doc(event.id)
        .collection(_collection)
        .add({
          ip: currentIp,
          unique,
          event: event.id,
          eventName: event.name,
          ...(action !== "visits" && { ...others }),
          createdAt: date,
          updatedAt: date,
        });

      let docRef = collectionAnalyticsEvents.doc(event.id);
      let snapshot = await docRef.get();
      if (snapshot.exists) {
        docRef.update({
          ...(updateObj ? updateObj : { [totalVisits]: firebase.firestore.FieldValue.increment(1) }),
          ...(action === "visits" && unique && { [totalUniqueVisits]: firebase.firestore.FieldValue.increment(1) }),
          updatedAt: date,
        });
      } else {
        docRef.set({
          ...(updateObj ? updateObj : { [totalVisits]: firebase.firestore.FieldValue.increment(1) }),
          ...(action === "visits" && unique && { [totalUniqueVisits]: firebase.firestore.FieldValue.increment(1) }),
          createdAt: date,
          updatedAt: date,
        });
      }
    } catch (error) {
      dispatch(asyncActionError(error, "addAnalyticsEvent", false));
    }
  };
}

export function addAnalyticsEventInner(event, user, inner, logType = LogTypesEnum.BOOTHS, action = "visits", others = {}) {
  console.log("addAnalyticsEventInner", event.id, user.uid, logType);
  console.log("others", others, "inner", inner);
  return async (dispatch, getState) => {
    try {
      let { appMetadata } = getState().app;
      console.log("appMetadata", appMetadata);
      if (!appMetadata?.analytics[logType]) {
        return;
      }
      let date = getFirebase().firestore.FieldValue.serverTimestamp();
      let currentIp = await getCurrentIp();
      let unique = isAnalyticsUniqueEntry(currentIp, user.uid, logType, inner.id);

      let _collection1 = logType;
      let _collection = action;
      let _append = _collection.charAt(0).toUpperCase() + _collection.slice(1);
      let totalVisits = "total";
      let totalUniqueVisits = "totalUnique";

      // if (action === "visits") {
      //   totalVisits += "Visits";
      //   totalUniqueVisits += "Visits";
      // } else {
      // totalVisits += _append;
      // totalUniqueVisits += _append;
      // }
      let updateObj = null;
      if (action === "downloads" || action === "viewImages" || action === "viewVideos" || action === "viewFiles") {
        totalVisits = action + `;${others.file.id}`;
        // totalVisitsAction = {
        //   ...others.file,
        //   total: firebase.firestore.FieldValue.increment(1),
        // };
        updateObj = {
          [totalVisits + ".id"]: others.file.id,
          [totalVisits + ".fileName"]: others.file.fileName,
          [totalVisits + ".total"]: firebase.firestore.FieldValue.increment(1),
        };
      } else {
        totalVisits += _append;
        totalUniqueVisits += _append;
      }

      console.log("_collection1", _collection1);
      console.log("_collection", _collection, "_append", _append);
      console.log("totalVisits", totalVisits, "totalUniqueVisits", totalUniqueVisits);

      await collectionAnalyticsEvents
        .doc(event.id)
        .collection(_collection1)
        .doc(inner.id)
        .collection(_collection)
        .add({
          ip: currentIp,
          unique,
          uid: user.uid,
          displayName: user.displayName,
          email: user.email,
          roomName: inner.name,
          ...(action !== "visits" && { ...others }),
          createdAt: date,
          updatedAt: date,
        });
      await collectionUsers
        .doc(user.uid)
        .collection("events")
        .doc(event.id)
        .collection(_collection1)
        .doc(inner.id)
        .collection(_collection)
        .add({
          ip: currentIp,
          unique,
          event: event.id,
          eventName: event.name,
          ...(action !== "visits" && { ...others }),
          createdAt: date,
          updatedAt: date,
        });

      let docRef = collectionAnalyticsEvents.doc(event.id).collection(_collection1).doc(inner.id);
      let snapshot = await docRef.get();
      if (snapshot.exists) {
        docRef.update({
          ...(updateObj ? updateObj : { [totalVisits]: firebase.firestore.FieldValue.increment(1) }),
          ...(action === "visits" && unique && { [totalUniqueVisits]: firebase.firestore.FieldValue.increment(1) }),
          updatedAt: date,
        });
      } else {
        docRef.set({
          [totalVisits]: firebase.firestore.FieldValue.increment(1),
          ...(action === "visits" && unique && { [totalUniqueVisits]: firebase.firestore.FieldValue.increment(1) }),
          createdAt: date,
          updatedAt: date,
        });
      }
    } catch (error) {
      dispatch(asyncActionError(error, "addAnalyticsEventInner", false));
    }
  };
}

export function getAnalyticsByEvent(eventId) {
  return collectionAnalyticsEvents.doc(eventId);
}

export function getAnalyticsByEventCollection(eventId, collection = "visits") {
  return collectionAnalyticsEvents.doc(eventId).collection(collection);
}

export function getAnalyticsByEventInner(eventId, innerId, collection = "booths") {
  return collectionAnalyticsEvents.doc(eventId).collection(collection).doc(innerId);
}

export function getAnalyticsByEventInnerCollection(eventId, innerId, collection = "booths", collection2 = "visits") {
  return collectionAnalyticsEvents.doc(eventId).collection(collection).doc(innerId).collection(collection2);
}
// End Analytics Events

// Start Faqs
export function getEventFaqsByEvent(eventId) {
  console.log("getEventFaqsByEvent", eventId);
  return collection.doc(eventId).collection("faqs").where("deleted", "==", false).orderBy("priority");
}

export function updateFaq(eventId, faq) {
  console.log("updateFaq", eventId, faq);

  return async (dispatch, getState) => {
    try {
      let date = getFirebase().firestore.FieldValue.serverTimestamp();

      if (faq.id) {
        let updateObj = { ...faq };
        delete updateObj["id"];
        await collection
          .doc(eventId)
          .collection("faqs")
          .doc(faq.id)
          .update({
            ...updateObj,
            updatedAt: date,
          });

        if (faq.deleted === true) {
          toast.success("Faq deleted");
        } else {
          toast.success("Faq updated");
        }
      } else {
        await collection
          .doc(eventId)
          .collection("faqs")
          .add({
            ...faq,
            createdAt: date,
            updatedAt: date,
          });

        toast.success("Faq successfully added");
      }
    } catch (error) {
      dispatch(asyncActionError(error, "updateFaq", false));
    }
  };
}
// End Faqs

// Start Raffle
export function getFilters(eventId) {
  console.log("getFilters", eventId);
  return collection.doc(eventId).collection("filters").orderBy("createdAt", "desc");
}

export function saveFilter(eventId, filter) {
  console.log("saveFilter", eventId, filter);

  return async (dispatch, getState) => {
    try {
      dispatch(asyncActionStart());
      let date = getFirebase().firestore.FieldValue.serverTimestamp();

      if (filter.id) {
        let updateObj = { ...filter };
        delete updateObj["id"];
        await collection
          .doc(eventId)
          .collection("filters")
          .doc(filter.id)
          .update({
            ...updateObj,
            updatedAt: date,
          });

        if (filter.deleted === true) {
          toast.success("Filter deleted");
        } else {
          toast.success("Filter updated");
        }
      } else {
        await collection
          .doc(eventId)
          .collection("filters")
          .add({
            ...filter,
            createdAt: date,
            updatedAt: date,
          });

        toast.success("Filter successfully added");
      }
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError(error, "saveFilter", false));
    }
  };
}

export function getPrizes(eventId) {
  console.log("getPrizes", eventId);
  return collection.doc(eventId).collection("prizes").orderBy("createdAt", "desc");
}

export function getPrize(eventId, prizeId) {
  console.log("getPrize", eventId, prizeId);
  return collection.doc(eventId).collection("prizes").doc(prizeId);
}

export function savePrize(eventId, prize, actionStart = true, showAlert = true) {
  console.log("savePrize", eventId, prize);

  return async (dispatch, getState) => {
    try {
      if (actionStart) dispatch(asyncActionStart());
      let date = getFirebase().firestore.FieldValue.serverTimestamp();
      let savePath = `public/events/${eventId}/documents`;

      dispatch(
        addDynamicFiles(prize, savePath, async (newValues) => {
          if (newValues.id) {
            let updateObj = { ...newValues };
            delete updateObj["id"];
            await collection
              .doc(eventId)
              .collection("prizes")
              .doc(newValues.id)
              .update({
                ...updateObj,
                updatedAt: date,
              });

            if (newValues.deleted === true) {
              if (showAlert) toast.success("Prize deleted");
            } else {
              if (showAlert) toast.success("Prize updated");
            }
          } else {
            await collection
              .doc(eventId)
              .collection("prizes")
              .add({
                ...newValues,
                createdAt: date,
                updatedAt: date,
              });

            if (showAlert) toast.success("Prize successfully added");
          }
        })
      );
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError(error, "savePrize", false));
    }
  };
}

export function getRaffle(eventId, doc = "default") {
  console.log("getRaffle", eventId);
  return collection.doc(eventId).collection("raffle").doc(doc);
}

export function saveRaffle(eventId, raffle, actionStart = true, showAlert = true, doc = "default") {
  console.log("saveRaffle", eventId, raffle);

  return async (dispatch) => {
    try {
      if (actionStart) dispatch(asyncActionStart());
      let date = getFirebase().firestore.FieldValue.serverTimestamp();

      let updateObj = { ...raffle };
      delete updateObj["id"];
      if (raffle.id) {
        let updateObj = { ...raffle };
        delete updateObj["id"];
        await collection
          .doc(eventId)
          .collection("raffle")
          .doc(raffle.id)
          .update({
            ...updateObj,
            updatedAt: date,
          });

        if (showAlert) toast.success("Raffle updated");
      } else {
        await collection
          .doc(eventId)
          .collection("raffle")
          .doc(doc)
          .set({
            ...raffle,
            createdAt: date,
            updatedAt: date,
          });
      }
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError(error, "savePrize", false));
    }
  };
}

export function getRaffleWinners(eventId) {
  console.log("getRaffleWinners", eventId);
  return collection.doc(eventId).collection("raffleWinners").orderBy("updatedAt", "desc");
}

export function saveRaffleWinner(eventId, winner, actionStart = true, showAlert = true) {
  console.log("saveRaffleWinner", eventId, winner);

  return async (dispatch) => {
    try {
      if (actionStart) dispatch(asyncActionStart());
      let date = getFirebase().firestore.FieldValue.serverTimestamp();

      if (Array.isArray(winner)) {
        let batchWinners = db.batch();
        for (let i = 0; i < winner.length; i++) {
          let docRef = collection.doc(eventId).collection("raffleWinners").doc(winner[i].id);

          batchWinners.set(
            docRef,
            {
              ...winner[i],
              passportComplete: false,
              createdAt: date,
              updatedAt: date,
            },
            {
              merge: true,
            }
          );
        }
        batchWinners.commit();
      } else {
        let docRef = collection.doc(eventId).collection("raffleWinners").doc(winner.id);

        let snapshot = await docRef.get();

        if (snapshot.exists) {
          await collection
            .doc(eventId)
            .collection("raffleWinners")
            .doc(winner.id)
            .update({
              ...winner,
              updatedAt: date,
            });

          if (showAlert) toast.success("Raffle winner updated");
        } else {
          await collection
            .doc(eventId)
            .collection("raffleWinners")
            .doc(winner)
            .set({
              ...winner,
              createdAt: date,
              updatedAt: date,
            });
          if (showAlert) toast.success("Raffle winner added");
        }
      }

      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError(error, "savePrize", false));
    }
  };
}

// End Raffle

// Start Presence

export function getEventAttendeePresence(eventId) {
  console.log("getEventAttendeePresence", eventId);
  // /status/${event}/attendees/${attendee?.id}`;
  return collectionStatus.doc(eventId).collection("attendees");
}

export function getStateAttendees() {
  console.log("getStateAttendees");

  return (dispatch, getState) => getState().event?.attendees;
}

export function getStateEvent() {
  console.log("getStateEvent");

  return (dispatch, getState) => getState().event?.event;
}

export function getStateAttendeePresence() {
  console.log("getStateAttendeePresence");

  return (dispatch, getState) => getState().event?.attendeePresence;
}

// End Presence

// Start Email Templates

export function getEmailTemplatesByType(type = "event") {
  console.log("getEmailTemplatesByType", type);
  return collectionEmailTemplates.where("type", "==", type).orderBy("name");
}

export function getEmailTemplatesByEvent(eventId) {
  console.log("getEmailTemplatesByEvent", eventId);
  return collection.doc(eventId).collection("emailTemplates").orderBy("name");
}

export function saveEventEmailTemplate(eventId, template, updateIfExists = true) {
  console.log("saveEventEmailTemplate", eventId, template);

  return async (dispatch) => {
    try {
      let date = getFirebase().firestore.FieldValue.serverTimestamp();

      dispatch(asyncActionStart());
      let updateObj = { ...template };
      // delete updateObj["id"];

      let docRef = collection.doc(eventId).collection("emailTemplates").doc(updateObj.id);

      // if (defaults) {
      //   docRef = collection.doc(eventId).collection("emailTemplates").doc(template.id);
      // } else {
      //   docRef = collection.doc(eventId).collection("emailTemplates").doc(template.id);
      // }

      let snapshot = await docRef.get();

      console.log("updateObj", updateObj);
      if (snapshot.exists) {
        if (updateIfExists) {
          await docRef.update({
            ...updateObj,
            updatedAt: date,
          });

          toast.success("Email template updated");
        }
      } else {
        await docRef.set({
          ...updateObj,
          createdAt: date,
          updatedAt: date,
        });

        toast.success("Email template successfully added");
      }
    } catch (error) {
      dispatch(asyncActionError(error, "saveEventEmailTemplate", false));
    } finally {
      dispatch(asyncActionFinish());
    }
  };
}
// End Email Templates

// redux
export function setEvents(payload) {
  return {
    type: SET_EVENTS,
    payload,
  };
}

export function setEvent(payload) {
  return {
    type: SET_EVENT,
    payload,
  };
}

export function setEventId(payload) {
  return {
    type: SET_EVENT_ID,
    payload,
  };
}

export function setEventFiles(payload) {
  console.log("setEventFiles", payload);
  return {
    type: SET_EVENT_FILES,
    payload,
  };
}

export function setEventBoothFiles(payload) {
  console.log("setEventBoothFiles", payload);
  return {
    type: SET_EVENT_BOOTH_FILES,
    payload,
  };
}

export function setBooths(payload) {
  return {
    type: SET_BOOTHS,
    payload,
  };
}

export function setBooth(payload) {
  return {
    type: SET_BOOTH,
    payload,
  };
}

export function setBoothId(payload) {
  return {
    type: SET_BOOTH_ID,
    payload,
  };
}

export function setNetworkingRooms(payload = []) {
  return {
    type: SET_NETWORKING_ROOMS,
    payload,
  };
}

export function setNetworkingRoom(payload) {
  return {
    type: SET_NETWORKING_ROOM,
    payload,
  };
}

export function setNetworkingRoomId(payload) {
  return {
    type: SET_NETWORKING_ROOM_ID,
    payload,
  };
}

export function setTicket(payload) {
  return {
    type: SET_TICKET,
    payload,
  };
}

export function setRegistrationDetail(payload) {
  return {
    type: SET_REGISTRATION_DETAIL,
    payload,
  };
}

export function setAttendees(payload) {
  return {
    type: SET_ATTENDEES,
    payload,
  };
}

export function setAttendeeCompanions(payload) {
  return {
    type: SET_ATTENDEE_COMPANIONS,
    payload,
  };
}

export function setTempJoinEvent(payload) {
  return {
    type: SET_TEMP_JOIN_EVENT,
    payload,
  };
}

export function setUserEvents(payload) {
  return {
    type: SET_USER_EVENTS,
    payload,
  };
}

export function setEventMessages(payload) {
  return {
    type: SET_EVENT_MESSAGES,
    payload,
  };
}

export function setEventPlenaryMessages(payload) {
  return {
    type: SET_EVENT_PLENARY_MESSAGES,
    payload,
  };
}

export function setEventUserFiles(payload) {
  return {
    type: SET_EVENT_USER_FILES,
    payload,
  };
}

export function setEventUserPassport(payload) {
  return {
    type: SET_EVENT_USER_PASSPORT,
    payload,
  };
}

export function setAttendee(payload) {
  return {
    type: SET_ATTENDEE,
    payload,
  };
}

export function setBoothTeam(payload) {
  return {
    type: SET_BOOTH_TEAM,
    payload,
  };
}

export function setBoothInvites(payload) {
  return {
    type: SET_BOOTH_INVITES,
    payload,
  };
}

export function setUserBoothInvites(payload) {
  return {
    type: SET_USER_BOOTH_INVITES,
    payload,
  };
}

export function setUserBooths(payload) {
  return {
    type: SET_USER_BOOTHS,
    payload,
  };
}

export function setBoothMessages(payload, boothId) {
  let messages = [];
  payload.forEach((p) => {
    let message = messages.find((a) => a.user.uid === p.user.uid);
    if (message) {
      message.messages.push({
        createdAt: p.createdAt,
        message: p.message,
        booth: p.booth,
      });
    } else {
      messages.push({
        user: p.user,
        messages: [
          {
            createdAt: p.createdAt,
            message: p.message,
            booth: p.booth,
          },
        ],
      });
    }
  });
  let byUser = {};
  messages.forEach((a) => (byUser[a.user.uid] = a));
  return {
    type: SET_BOOTH_MESSAGES,
    payload: {
      [boothId]: byUser,
    },
  };
}

export function setHelpMessages(payload) {
  let messages = [];
  payload.forEach((p) => {
    let message = messages.find((a) => a.user.uid === p.user.uid);
    if (message) {
      message.messages.push({
        createdAt: p.createdAt,
        message: p.message,
        desk: p.desk,
      });
    } else {
      messages.push({
        user: p.user,
        messages: [
          {
            createdAt: p.createdAt,
            message: p.message,
            desk: p.desk,
          },
        ],
      });
    }
  });
  let byUser = {};
  messages.forEach((a) => (byUser[a.user.uid] = a));
  return {
    type: SET_HELP_MESSAGES,
    payload: byUser,
  };
}

export function toggleShowPassport() {
  return {
    type: TOGGLE_SHOW_PASSPORT,
  };
}

export function toggleShowChat() {
  return {
    type: TOGGLE_SHOW_CHAT,
  };
}

export function toggleShowHelpChat() {
  return {
    type: TOGGLE_SHOW_HELP_CHAT,
  };
}

export function setBoothSurveyAnswers(payload) {
  return {
    type: SET_BOOTH_SURVEY_ANSWERS,
    payload,
  };
}

export function setEventNotifications(payload) {
  return {
    type: SET_EVENT_NOTIFICATIONS,
    payload,
  };
}

export function setEventBoothNotifications(payload) {
  return {
    type: SET_EVENT_BOOTH_NOTIFICATIONS,
    payload,
  };
}

export function toggleShowEventChat() {
  return {
    type: TOGGLE_SHOW_EVENT_CHAT,
  };
}

export function setEventControls(payload) {
  return {
    type: SET_EVENT_CONTROLS,
    payload,
  };
}

export function setEventBroadcastMessages(payload) {
  return {
    type: SET_EVENT_BROADCAST_MESSAGES,
    payload,
  };
}

export function setEventBroadcastMessageTemplates(payload) {
  return {
    type: SET_EVENT_BROADCAST_MESSAGE_TEMPLATES,
    payload,
  };
}

export function toggleEventMusicMuted(payload) {
  return {
    type: TOGGLE_EVENT_MUSIC_MUTED,
    payload,
  };
}

export function setRoutingLocked(payload) {
  return {
    type: SET_ROUTING_LOCKED,
    payload,
  };
}

export function setRooms(payload = []) {
  return {
    type: SET_ROOMS,
    payload,
  };
}

export function setRoom(payload = null) {
  return {
    type: SET_ROOM,
    payload,
  };
}

export function setRoomId(payload = null) {
  return {
    type: SET_ROOM_ID,
    payload,
  };
}

export function setRoomChildren(payload = []) {
  return {
    type: SET_ROOM_CHILDREN,
    payload,
  };
}

export function setSurveys(payload = []) {
  return {
    type: SET_SURVEYS,
    payload,
  };
}

export function setSurveyAnswers(payload = []) {
  return {
    type: SET_SURVEY_ANSWERS,
    payload,
  };
}

export function setOnVideoCall(payload = false) {
  return {
    type: SET_ON_VIDEO_CALL,
    payload,
  };
}

export function setBoothVisitors(payload = []) {
  return {
    type: SET_BOOTH_VISITORS,
    payload,
  };
}

export function setEventFaqs(payload = []) {
  return {
    type: SET_EVENT_FAQS,
    payload,
  };
}

export function setEventFaq(payload = null) {
  return {
    type: SET_EVENT_FAQ,
    payload,
  };
}

export function setEventRaffleFilters(payload = []) {
  return {
    type: SET_EVENT_RAFFLE_FILTERS,
    payload,
  };
}

export function setEventRafflePrizes(payload = []) {
  return {
    type: SET_EVENT_RAFFLE_PRIZES,
    payload,
  };
}

export function setEventRafflePrize(payload = null) {
  return {
    type: SET_EVENT_RAFFLE_PRIZE,
    payload,
  };
}

export function setEventRafflePrizeId(payload = null) {
  return {
    type: SET_EVENT_RAFFLE_PRIZE_ID,
    payload,
  };
}

export function setEventRaffle(payload = {}) {
  return {
    type: SET_EVENT_RAFFLE,
    payload,
  };
}

export function setEventRaffleWinners(payload = []) {
  return {
    type: SET_EVENT_RAFFLE_WINNERS,
    payload,
  };
}

export function setEventAttendeePresence(payload = []) {
  return {
    type: SET_EVENT_ATTENDEE_PRESENCE,
    payload,
  };
}

export function setEventEmailTemplates(payload = []) {
  return {
    type: SET_EVENT_EMAIL_TEMPLATES,
    payload,
  };
}
