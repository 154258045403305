export const firebaseConfig = {
  apiKey: "AIzaSyAjCZFs3Ma8GU9m6l48ouhZNbZCJMVn2P8",
  authDomain: "u-studio-a969d.firebaseapp.com",
  projectId: "u-studio-a969d",
  databaseURL: "https://u-studio-a969d-default-rtdb.asia-southeast1.firebasedatabase.app",
  storageBucket: "u-studio-a969d.appspot.com",
  messagingSenderId: "210333868672",
  appId: "1:210333868672:web:1fd50b6eeba60b28487c65",
  measurementId: "G-WNF270S00Z",
};
