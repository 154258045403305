import React, { useRef, useEffect } from "react";
import { Track } from "twilio-video";
import { styled } from "@material-ui/core/styles";

const Video = styled("video")({
  width: "100%",
  height: "100%",
});

export default function VideoTrack({ track, isLocal, priority }) {
  const ref = useRef(null);

  useEffect(() => {
    const el = ref.current;
    el.muted = true;
    if (track.setPriority && priority) {
      track.setPriority(priority);
    }
    track.attach(el);
    return () => {
      console.log("VideoTrack return");
      track.detach(el);
      if (track.setPriority && priority) {
        // Passing `null` to setPriority will set the track's priority to that which it was published with.
        track.setPriority(null);
      }
    };
  }, [track, priority]);

  const style = {
    // transform: isLocal && isFrontFacing ? "rotateY(180deg)" : "",
    // objectFit: isPortrait || track.name.includes("screen") ? "contain" : "cover",
    transform: "",
    objectFit: "cover",
  };

  return <Video ref={ref} style={style} />;
}
