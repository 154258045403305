import React, { lazy } from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import ForgotPassword from "../../modules/Auth/components/ForgotPassword";
import NewEvent from "../../modules/Events/components/NewEvent";
import DownloadAttendees from "../../modules/Public/components/DownloadAttendees";
// import LiveEventLayout from "../layouts/LiveEventLayout";
// import OrganizersLayout from "../layouts/OrganizersLayout";
import * as routes from "./constants";

import PrivateRoute from "./PrivateRoute";

const Home = lazy(() => import("../layouts/HomeLayout"));
const UserLayout = lazy(() => import("../layouts/UserLayout"));
const OrganizationLayout = lazy(() => import("../layouts/OrganizationLayout"));
const SignIn = lazy(() => import("../../modules/Auth/components/SignIn"));
const SignUp = lazy(() => import("../../modules/Auth/components/SignUp"));
const Chat = lazy(() => import("../../components/Chat"));
const VideoCall = lazy(() => import("../../components/VideoCall"));
const EventLayout = lazy(() => import("../layouts/EventLayout"));
const OrganizersLayout = lazy(() => import("../layouts/OrganizersLayout"));
const LiveEventLayout = lazy(() => import("../layouts/LiveEventLayout"));
const LiveEventDemoLayout = lazy(() => import("../layouts/LiveEventDemoLayout"));
const SandboxLayout = lazy(() => import("../layouts/SandboxLayout"));
const Video = lazy(() => import("../../modules/Events/components/Video"));
const EventRaffleView = lazy(() => import("../../modules/Events/components/EventRaffleView"));

const Routes = () => {
  const location = useLocation();
  const { from } = location.state || { from: { pathname: routes.HOME } };

  const { isLoggedIn } = useSelector((state) => state.auth);

  return (
    <Switch>
      {/* <Route
        exact
        path={routes.HOME}
        render={() => {
          console.log("isLoggedIn", isLoggedIn);
          console.log("from", from);
          if (isLoggedIn && from) {
            return <Redirect to={from} />;
          } else {
            return <Home />;
          }
        }}
      /> */}
      {/* <Route exact path={routes.HOME} component={Home} /> */}
      <Route exact path={routes.HOME} component={SignIn} />
      <PrivateRoute path={routes.ACCOUNT} component={UserLayout} isLoggedIn={isLoggedIn} />
      {/* <PrivateRoute
        path={routes.ORGANIZATION}
        component={OrganizationLayout}
        isLoggedIn={isLoggedIn}
      /> */}
      {/* <PrivateRoute path={routes.ORGANIZERS + routes.NEW + routes.EVENTS} component={NewEvent} isLoggedIn={isLoggedIn} /> */}
      <PrivateRoute path={routes.ORGANIZERS + routes.EVENTS + routes.NEW} component={NewEvent} isLoggedIn={isLoggedIn} />
      <PrivateRoute path={routes.ORGANIZERS + routes.EVENTS + "/:customURL"} component={OrganizersLayout} isLoggedIn={isLoggedIn} />
      <PrivateRoute path={routes.CHAT} component={Chat} isLoggedIn={isLoggedIn} />
      <PrivateRoute path={routes.VIDEOCALL} component={VideoCall} isLoggedIn={isLoggedIn} />
      <Route exact path={routes.SIGN_IN} component={SignIn} />
      <Route exact path={routes.SIGN_UP} component={SignUp} />
      <Route exact path={routes.DOWNLOAD_ATTENDEES} component={DownloadAttendees} />
      <Route exact path={routes.FORGOT_PASSWORD} component={ForgotPassword} />
      <Route exact path={routes.HOME} component={Home} />
      <Route path={routes.EVENTS + "/:customURL"} component={EventLayout} />
      {/* <PrivateRoute path={routes.LIVE + "/:customURL"} component={LiveEventLayout} isLoggedIn={isLoggedIn} /> */}
      <Route path={routes.LIVE + "/:customURL"} component={LiveEventDemoLayout} />
      <Route path={routes.DEMO + "/:customURL"} component={LiveEventDemoLayout} />
      <PrivateRoute path={routes.VIDEO_MEETING} component={Video} isLoggedIn={isLoggedIn} />
      <Route path={routes.RAFFLE + "/:customURL"} component={EventRaffleView} />
      <Route path={routes.SANDBOX} component={SandboxLayout} />

      {/* <Redirect from={routes.HOME} to={routes.ACCOUNT} /> */}

      {/* <Redirect path="/(.+)" to={routes.HOME} /> */}
    </Switch>
  );
};

export default Routes;
