import { SIGN_IN_USER, UPDATE_AUTH_USER } from "./constants";

const initialState = {
  isLoggedIn: false,
  user: null,
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case SIGN_IN_USER:
      return {
        ...state,
        isLoggedIn: true,
        user: {
          email: payload.email,
          photoURL: payload.photoURL,
          uid: payload.uid,
          displayName: payload.displayName,
          providerId: payload.providerData[0]?.providerId,
        },
      };
    case UPDATE_AUTH_USER:
      return {
        ...state,
        user: {
          ...state.user,
          ...payload,
        },
      };
    default:
      return state;
  }
}
