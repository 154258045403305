import React, { useCallback, useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core";
import Loader from "../Loader";
import { getDeviceInfo } from "../../library/utilities";
import { toast } from "react-toastify";
import Video from "twilio-video";
import VideoTrack from "./VideoTrack";
import AudioLevelIndicator from "./AudioLevelIndicator";
import useMediaStreamTrack from "../../hooks/useMediaStreamTrack";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 300,
    minHeight: 200,
  },
  spacer: {
    padding: theme.spacing(1.5),
  },
  preview: {
    width: "300px",
    maxHeight: "200px",
    margin: "0.5em auto",
    "& video": {
      maxHeight: "200px",
    },
  },
}));

export const DEFAULT_VIDEO_CONSTRAINTS = {
  width: 1280,
  height: 720,
  frameRate: 24,
};

// These are used to store the selected media devices in localStorage
export const SELECTED_AUDIO_INPUT_KEY = "EventsHub:selectedAudioInput";
export const SELECTED_AUDIO_OUTPUT_KEY = "EventsHub:selectedAudioOutput";
export const SELECTED_VIDEO_INPUT_KEY = "EventsHub:selectedVideoInput";

export default function Devices({ room, onSave }) {
  const classes = useStyles();
  const [audioInput, setAudioInput] = useState("");
  const [audioOutput, setAudioOutput] = useState("");
  const [videoInput, setVideoInput] = useState("");
  const [audioInputDevices, setAudioInputDevices] = useState([]);
  const [audioOutputDevices, setAudioOutputDevices] = useState([]);
  const [videoInputDevices, setVideoInputDevices] = useState([]);

  const [isAcquiringLocalTracks, setIsAcquiringLocalTracks] = useState(true);

  const [localVideoTrack, setLocalVideoTrack] = useState(null);
  const [localAudioTrack, setLocalAudioTrack] = useState(null);
  // const getAudioVideoTracks = useCallback(async () => {}, [
  //   audioTrack,
  //   videoTrack,
  //   isAcquiringLocalTracks,
  // ]);

  useEffect(() => {
    const acquireDevices = async () => {
      const { audioOutputDevices, audioInputDevices, videoInputDevices, hasAudioInputDevices, hasVideoInputDevices } = await getDeviceInfo();

      setAudioInputDevices(audioInputDevices);
      setAudioOutputDevices(audioOutputDevices);
      setVideoInputDevices(videoInputDevices);

      const selectedAudioDeviceId = window.localStorage.getItem(SELECTED_AUDIO_INPUT_KEY) || audioInputDevices[0]?.deviceId;
      const selectedAudioOutputDeviceId = window.localStorage.getItem(SELECTED_AUDIO_OUTPUT_KEY) || audioOutputDevices[0]?.deviceId;
      const selectedVideoDeviceId = window.localStorage.getItem(SELECTED_VIDEO_INPUT_KEY) || videoInputDevices[0]?.deviceId;

      const hasSelectedAudioDevice = audioInputDevices.some((device) => selectedAudioDeviceId && device.deviceId === selectedAudioDeviceId);
      setAudioInput(selectedAudioDeviceId);

      const hasSelectedAudioOutputDevice = audioOutputDevices.some((device) => selectedAudioOutputDeviceId && device.deviceId === selectedAudioOutputDeviceId);
      setAudioOutput(selectedAudioOutputDeviceId);

      const hasSelectedVideoDevice = videoInputDevices.some((device) => selectedVideoDeviceId && device.deviceId === selectedVideoDeviceId);
      setVideoInput(selectedVideoDeviceId);

      const localTrackConstraints = {
        video: hasVideoInputDevices && {
          ...DEFAULT_VIDEO_CONSTRAINTS,
          name: `camera-${Date.now()}`,
          ...(hasSelectedVideoDevice && {
            deviceId: { exact: selectedVideoDeviceId },
          }),
        },
        audio: hasSelectedAudioDevice ? { deviceId: { exact: selectedAudioDeviceId } } : hasAudioInputDevices,
      };

      if (selectedVideoDeviceId) {
        try {
          let newLocalVideoTrack = await Video.createLocalVideoTrack({
            ...DEFAULT_VIDEO_CONSTRAINTS,
            name: `camera-${Date.now()}`,
            ...(hasSelectedVideoDevice && {
              deviceId: { exact: selectedVideoDeviceId },
            }),
          });
          console.log("newLocalVideoTrack", newLocalVideoTrack);
          setLocalVideoTrack(newLocalVideoTrack);
        } catch (error) {
          console.log("localVideoTrack error", error);
        }
      }
      if (selectedAudioDeviceId) {
        try {
          let newLocalAudioTrack = await Video.createLocalAudioTrack({
            ...(hasSelectedVideoDevice && {
              // deviceId: { exact: selectedVideoDeviceId },
              deviceId: selectedVideoDeviceId,
            }),
          });
          console.log("newLocalAudioTrack", newLocalAudioTrack);
          setLocalAudioTrack(newLocalAudioTrack);
        } catch (error) {
          console.log("newLocalAudioTrack error", error);
        }
      }

      setTimeout(() => {
        setIsAcquiringLocalTracks(false);
      }, 500);
    };
    navigator?.mediaDevices?.addEventListener("devicechange", acquireDevices);
    acquireDevices();

    return () => {
      navigator?.mediaDevices?.removeEventListener("devicechange", acquireDevices);
    };
  }, []);

  const saveSettings = () => {
    localStorage.setItem(SELECTED_AUDIO_INPUT_KEY, audioInput);
    localStorage.setItem(SELECTED_AUDIO_OUTPUT_KEY, audioOutput);
    localStorage.setItem(SELECTED_VIDEO_INPUT_KEY, videoInput);
    toast.success("Device settings updated");
    localVideoTrack?.stop();
    localAudioTrack?.stop();
    if (onSave) {
      setTimeout(() => {
        onSave({
          audioInput,
          audioOutput,
          videoInput,
        });
      }, 300);
    }
  };

  useEffect(() => {
    return () => {
      console.log("device cleaner video");
      if (localVideoTrack) {
        console.log("clean localVideoTrack");
        localVideoTrack.stop();
      }
    };
  }, [localVideoTrack]);

  useEffect(() => {
    return () => {
      console.log("device cleaner audio");
      if (localAudioTrack) {
        console.log("clean localAudioTrack");
        localAudioTrack.stop();
      }
    };
  }, [localAudioTrack]);

  return (
    <div className={classes.root}>
      <Grid container className={classes.spacer}>
        {isAcquiringLocalTracks ? (
          <Loader open={true} />
        ) : (
          <>
            {audioInputDevices.length > 0 && (
              <Grid item xs={12}>
                <FormControl variant="outlined" className={classes.spacer} fullWidth>
                  <InputLabel id={`select-audio-input-devices`}>Microphone</InputLabel>
                  <div style={{ display: "flex", flex: 1, flexDirection: "row", alignItems: "center" }}>
                    <Select
                      labelId={`select-audio-input-devices`}
                      id={`select-audio-input-devices`}
                      name={`audioInput`}
                      onChange={(e) => {
                        setAudioInput(e.target.value);
                        if (e.target.value) {
                          if (localAudioTrack) {
                            localAudioTrack?.restart({ deviceId: { exact: e.target.value } });
                          } else {
                            Video.createLocalAudioTrack({
                              deviceId: { exact: e.target.value },
                            })
                              .then((newLocalAudioTrack) => {
                                console.log("newLocalAudioTrack", newLocalAudioTrack);
                                setLocalAudioTrack(newLocalAudioTrack);
                              })
                              .catch((error) => {
                                console.log("newLocalAudioTrack error", error);
                              });
                          }
                        }
                      }}
                      fullWidth
                      label="Microphone"
                      value={audioInput}
                    >
                      {audioInputDevices.map((a) => (
                        <MenuItem key={a.deviceId} value={a.deviceId}>
                          {a.label}
                        </MenuItem>
                      ))}
                    </Select>
                    <AudioLevelIndicator audioTrack={localAudioTrack} color="black" />
                  </div>
                </FormControl>
              </Grid>
            )}

            {audioOutputDevices.length > 0 && (
              <Grid item xs={12}>
                <FormControl variant="outlined" className={classes.spacer} fullWidth>
                  <InputLabel id={`select-audio-output-devices`}>Speakers</InputLabel>
                  <Select
                    labelId={`select-audio-output-devices`}
                    id={`select-audio-output-devices`}
                    name={`audioOutput`}
                    onChange={(e) => {
                      setAudioOutput(e.target.value);
                    }}
                    fullWidth
                    label="Speakers"
                    value={audioOutput}
                  >
                    {audioOutputDevices.map((a) => (
                      <MenuItem key={a.deviceId} value={a.deviceId}>
                        {a.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
            {videoInputDevices.length > 0 && (
              <Grid item xs={12}>
                <FormControl variant="outlined" className={classes.spacer} fullWidth>
                  <InputLabel id={`select-video-input-devices`}>Camera</InputLabel>
                  <Select
                    labelId={`select-video-input-devices`}
                    id={`select-video-input-devices`}
                    name={`videoInput`}
                    onChange={(e) => {
                      setVideoInput(e.target.value);
                      if (e.target.value) {
                        if (localVideoTrack) {
                          localVideoTrack?.restart({ ...DEFAULT_VIDEO_CONSTRAINTS, deviceId: { exact: e.target.value } });
                        } else {
                          Video.createLocalVideoTrack({
                            ...DEFAULT_VIDEO_CONSTRAINTS,
                            name: `camera-${Date.now()}`,
                            deviceId: { exact: e.target.value },
                          })
                            .then((newLocalVideoTrack) => {
                              console.log("newLocalVideoTrack", newLocalVideoTrack);
                              setLocalVideoTrack(newLocalVideoTrack);
                            })
                            .catch((error) => {
                              console.log("localVideoTrack error", error);
                            });
                        }
                      }
                    }}
                    fullWidth
                    label="Camera"
                    value={videoInput}
                  >
                    {videoInputDevices.map((a) => (
                      <MenuItem key={a.deviceId} value={a.deviceId}>
                        {a.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {localVideoTrack && (
                  <div className={classes.preview}>
                    <VideoTrack isLocal track={localVideoTrack} />
                  </div>
                )}
              </Grid>
            )}
            <Grid item xs={12}>
              <Button fullWidth color="primary" variant="contained" className={classes.spacer} onClick={saveSettings}>
                Save
              </Button>
            </Grid>
          </>
        )}
      </Grid>
    </div>
  );
}
