import {
  ASYNC_ACTION_ERROR,
  ASYNC_ACTION_FINISH,
  ASYNC_ACTION_START,
  APP_LOADED,
  SET_SHOW_SIDEBAR,
  SET_SHOW_PROGRESS,
  SET_PROGRESS,
  SET_PREVIEW,
  TOGGLE_SHOW_LIVE_SIDEBAR,
  SET_ALERT_DIALOG,
  TOGGLE_NAVIGATION_LAYOUT,
  SET_APP_THEME,
  SET_APP_SETTINGS,
  SET_APP_METADATA,
  SET_DIALOG_SHOWN,
} from "./constants";
import { toast } from "react-toastify";
import { getFirebase } from "../../library/utilities/Firestore";

// firebase
export function getAppMetadata(id = "default") {
  let firebase = getFirebase();
  const db = firebase.firestore();
  const collection = db.collection("appMetadata");
  console.log("getAppMetadata", id);
  return collection.doc(id);
}

// redux
export function asyncActionStart() {
  return {
    type: ASYNC_ACTION_START,
  };
}

export function asyncActionFinish() {
  return {
    type: ASYNC_ACTION_FINISH,
  };
}

export function asyncActionError(payload, caller = "", showNotification = true) {
  if (showNotification) {
    toast.error(payload?.message || payload);
  }

  console.log("caller", caller);
  try {
    console.log(payload?.message || payload);
  } catch (error) {
    console.log(error);
  }

  return {
    type: ASYNC_ACTION_ERROR,
    payload,
  };
}

export function setAppLoaded(payload = true) {
  return {
    type: APP_LOADED,
    payload,
  };
}

export function setShowSidebar(payload = true) {
  return {
    type: SET_SHOW_SIDEBAR,
    payload,
  };
}

export function setShowProgress(payload = true) {
  return {
    type: SET_SHOW_PROGRESS,
    payload,
  };
}

export function setProgress(payload = 0) {
  return {
    type: SET_PROGRESS,
    payload,
  };
}

export function setPreview(payload = false) {
  return {
    type: SET_PREVIEW,
    payload,
  };
}

export function toggleShowLiveSidebar() {
  return {
    type: TOGGLE_SHOW_LIVE_SIDEBAR,
  };
}

export function setAlertDialog(payload = null) {
  return {
    type: SET_ALERT_DIALOG,
    payload,
  };
}

export function toggleNavigationLayout() {
  return {
    type: TOGGLE_NAVIGATION_LAYOUT,
  };
}

export function setAppTheme(payload = null) {
  return {
    type: SET_APP_THEME,
    payload,
  };
}

export function setAppSettings(payload = {}) {
  return {
    type: SET_APP_SETTINGS,
    payload,
  };
}

export function setAppMetadata(payload = {}) {
  return {
    type: SET_APP_METADATA,
    payload,
  };
}

export function setDialogShown(payload = false) {
  return {
    type: SET_DIALOG_SHOWN,
    payload,
  };
}
