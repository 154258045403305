import React from "react";
import { Route, Redirect } from "react-router-dom";
import * as routes from "./constants";

const PrivateRoute = ({
  component: Component,
  isLoggedIn = false,
  ...rest
}) => {
  const componentCheck = (props) => {
    if (isLoggedIn) {
      return <Component {...props} />;
    } else {
      console.log("else");
      return (
        <Redirect
          to={{
            pathname: routes.HOME,
            state: { from: props.location },
          }}
          // to={routes.HOME}
        />
      );
    }
  };

  return <Route {...rest} render={(props) => componentCheck(props)} />;
};

export default PrivateRoute;
