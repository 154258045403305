import React, { useEffect, useState } from "react";
import App from "./App";
import { useFirestoreDoc } from "./library/utilities/Firestore";
import { getAppMetadata, setAppMetadata } from "./modules/App/actions";
import { useDispatch } from "react-redux";
import Loader from "./components/Loader";
import { getCurrentIp, getDeviceId } from "./library/utilities";

function AppWrapper() {
  const dispatch = useDispatch();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    getCurrentIp();
    getDeviceId();
  }, []);

  useFirestoreDoc(
    {
      query: () => getAppMetadata(),
      data: (result) => {
        console.log("result", result);
        setInitialized(true);
        dispatch(setAppMetadata(result));
      },
      deps: [],
    },
    !initialized,
    true
  );

  if (!initialized) return <Loader open={true} />;

  return <App />;
}

export default AppWrapper;
