import {
  SET_EVENT,
  SET_EVENTS,
  SET_EVENT_ID,
  SET_EVENT_FILES,
  ADD_EVENT_FILE,
  SET_BOOTHS,
  SET_BOOTH,
  SET_EVENT_BOOTH_FILES,
  SET_BOOTH_ID,
  SET_TICKET,
  SET_REGISTRATION_DETAIL,
  SET_ATTENDEES,
  SET_TEMP_JOIN_EVENT,
  SET_USER_EVENTS,
  SET_EVENT_MESSAGES,
  SET_EVENT_USER_FILES,
  SET_EVENT_USER_PASSPORT,
  SET_ATTENDEE,
  SET_BOOTH_TEAM,
  SET_BOOTH_INVITES,
  SET_USER_BOOTH_INVITES,
  SET_USER_BOOTHS,
  SET_BOOTH_MESSAGES,
  TOGGLE_SHOW_PASSPORT,
  SET_HELP_MESSAGES,
  TOGGLE_SHOW_CHAT,
  SET_BOOTH_SURVEY_ANSWERS,
  SET_EVENT_NOTIFICATIONS,
  TOGGLE_SHOW_EVENT_CHAT,
  SET_EVENT_PLENARY_MESSAGES,
  SET_EVENT_CONTROLS,
  SET_EVENT_BROADCAST_MESSAGES,
  TOGGLE_EVENT_MUSIC_MUTED,
  SET_ROUTING_LOCKED,
  TOGGLE_SHOW_HELP_CHAT,
  SET_EVENT_BOOTH_NOTIFICATIONS,
  SET_NETWORKING_ROOMS,
  SET_NETWORKING_ROOM,
  SET_NETWORKING_ROOM_ID,
  SET_ROOMS,
  SET_ROOM,
  SET_ROOM_ID,
  SET_ROOM_CHILDREN,
  SET_SURVEYS,
  SET_SURVEY_ANSWERS,
  SET_ON_VIDEO_CALL,
  SET_BOOTH_VISITORS,
  SET_ATTENDEE_COMPANIONS,
  SET_EVENT_FAQS,
  SET_EVENT_FAQ,
  SET_EVENT_BROADCAST_MESSAGE_TEMPLATES,
  SET_EVENT_RAFFLE_FILTERS,
  SET_EVENT_RAFFLE_PRIZES,
  SET_EVENT_RAFFLE,
  SET_EVENT_RAFFLE_PRIZE,
  SET_EVENT_RAFFLE_WINNERS,
  SET_EVENT_RAFFLE_PRIZE_ID,
  SET_EVENT_ATTENDEE_PRESENCE,
  SET_EVENT_EMAIL_TEMPLATES,
} from "./constants";

const initialState = {
  events: [],
  event: null,
  eventId: null,
  eventFiles: [],
  eventBoothFiles: [],
  booths: [],
  booth: null,
  boothId: null,
  networkingRooms: [],
  networkingRoom: null,
  networkingRoomId: null,
  ticket: null,
  registrationDetail: null,
  attendees: [],
  attendeeCompanions: [],
  tempJoinEvent: null,
  userEvents: [],
  messages: [],
  plenaryMessages: [],
  userFiles: [],
  userPassport: null,
  attendee: null,
  boothTeam: [],
  boothInvites: [],
  userBoothInvites: [],
  userBooths: [],
  boothMessages: {},
  showPassport: false,
  helpMessages: {},
  showChat: false,
  showHelpChat: false,
  boothSurveyAnswers: [],
  eventNotifications: [],
  eventBoothNotifications: [],
  showEventChat: false,
  eventControls: [],
  eventBroadcastMessages: [],
  eventBroadcastMessageTemplates: [],
  musicMuted: false,
  routingLocked: false,
  rooms: [],
  room: null,
  roomId: null,
  roomChildren: [],
  surveys: [],
  surveyAnswers: [],
  onVideoCall: false,
  boothVisitors: [],
  faqs: [],
  faq: null,
  emailTemplates: [],
  raffleFilters: [],
  rafflePrizes: [],
  rafflePrize: null,
  rafflePrizeId: null,
  raffle: {},
  raffleWinners: [],
  attendeePresence: [],
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case SET_EVENTS:
      return {
        ...state,
        events: payload || [],
      };
    case SET_EVENT:
      return {
        ...state,
        event: payload || null,
      };
    case SET_EVENT_ID:
      return {
        ...state,
        eventId: payload,
      };
    case SET_EVENT_FILES:
      return {
        ...state,
        eventFiles: payload,
      };
    case SET_EVENT_BOOTH_FILES:
      return {
        ...state,
        eventBoothFiles: payload || [],
      };
    case ADD_EVENT_FILE: {
      return {
        ...state,
      };
    }
    case SET_BOOTHS: {
      return {
        ...state,
        booths: payload || [],
      };
    }
    case SET_BOOTH: {
      return {
        ...state,
        booth: payload || null,
      };
    }
    case SET_BOOTH_ID: {
      return {
        ...state,
        boothId: payload,
      };
    }
    case SET_NETWORKING_ROOMS: {
      return {
        ...state,
        networkingRooms: payload || [],
      };
    }
    case SET_NETWORKING_ROOM: {
      return {
        ...state,
        networkingRoom: payload || null,
      };
    }
    case SET_NETWORKING_ROOM_ID: {
      return {
        ...state,
        networkingRoomId: payload,
      };
    }
    case SET_TICKET: {
      return {
        ...state,
        ticket: payload,
      };
    }
    case SET_REGISTRATION_DETAIL: {
      return {
        ...state,
        registrationDetail: payload,
      };
    }
    case SET_ATTENDEES: {
      return {
        ...state,
        attendees: payload,
      };
    }
    case SET_ATTENDEE_COMPANIONS: {
      return {
        ...state,
        attendeeCompanions: payload,
      };
    }
    case SET_TEMP_JOIN_EVENT: {
      return {
        ...state,
        tempJoinEvent: payload,
      };
    }
    case SET_USER_EVENTS: {
      return {
        ...state,
        userEvents: payload || [],
      };
    }
    case SET_EVENT_MESSAGES:
      return {
        ...state,
        messages: payload || [],
      };
    case SET_EVENT_PLENARY_MESSAGES:
      return {
        ...state,
        plenaryMessages: payload || [],
      };
    case SET_EVENT_USER_FILES:
      return {
        ...state,
        userFiles: payload || [],
      };
    case SET_EVENT_USER_PASSPORT:
      return {
        ...state,
        userPassport: payload || null,
      };
    case SET_ATTENDEE:
      return {
        ...state,
        attendee: payload || null,
      };
    case SET_BOOTH_TEAM:
      return {
        ...state,
        boothTeam: payload || [],
      };
    case SET_BOOTH_INVITES:
      console.log("SET_BOOTH_INVITES", payload);
      return {
        ...state,
        boothInvites: payload || [],
      };
    case SET_USER_BOOTH_INVITES:
      return {
        ...state,
        userBoothInvites: payload || [],
      };
    case SET_USER_BOOTHS:
      return {
        ...state,
        userBooths: payload || [],
      };
    case SET_BOOTH_MESSAGES:
      return {
        ...state,
        boothMessages: {
          ...state.boothMessages,
          ...payload,
        },
      };
    case TOGGLE_SHOW_PASSPORT:
      return {
        ...state,
        showPassport: !state.showPassport,
      };
    case SET_HELP_MESSAGES:
      return {
        ...state,
        helpMessages: {
          ...state.helpMessages,
          ...payload,
        },
      };
    case TOGGLE_SHOW_CHAT:
      return {
        ...state,
        showChat: !state.showChat,
      };
    case TOGGLE_SHOW_HELP_CHAT:
      return {
        ...state,
        showHelpChat: !state.showHelpChat,
      };
    case SET_BOOTH_SURVEY_ANSWERS:
      return {
        ...state,
        boothSurveyAnswers: payload || [],
      };
    case SET_EVENT_NOTIFICATIONS:
      return {
        ...state,
        eventNotifications: payload || [],
      };
    case SET_EVENT_BOOTH_NOTIFICATIONS:
      return {
        ...state,
        eventBoothNotifications: payload || [],
      };
    case TOGGLE_SHOW_EVENT_CHAT:
      return {
        ...state,
        showEventChat: !state.showEventChat,
      };
    case SET_EVENT_CONTROLS:
      return {
        ...state,
        eventControls: payload || [],
      };
    case SET_EVENT_BROADCAST_MESSAGES:
      return {
        ...state,
        eventBroadcastMessages: payload || [],
      };
    case SET_EVENT_BROADCAST_MESSAGE_TEMPLATES:
      return {
        ...state,
        eventBroadcastMessageTemplates: payload || [],
      };
    case TOGGLE_EVENT_MUSIC_MUTED:
      console.log("TOGGLE_EVENT_MUSIC_MUTED", payload);
      return {
        ...state,
        musicMuted: payload === undefined ? !state.musicMuted : payload,
      };
    case SET_ROUTING_LOCKED:
      return {
        ...state,
        routingLocked: payload,
      };
    case SET_ROOMS:
      return { ...state, rooms: payload };
    case SET_ROOM:
      return { ...state, room: payload };
    case SET_ROOM_ID:
      return { ...state, roomId: payload };
    case SET_ROOM_CHILDREN:
      return { ...state, roomChildren: payload };
    case SET_SURVEYS:
      return { ...state, surveys: [] };
    case SET_SURVEY_ANSWERS:
      return { ...state, surveyAnswers: [] };
    case SET_ON_VIDEO_CALL:
      return { ...state, onVideoCall: payload };
    case SET_BOOTH_VISITORS:
      return { ...state, boothVisitors: payload };
    case SET_EVENT_FAQS:
      return { ...state, faqs: payload };
    case SET_EVENT_FAQ:
      return { ...state, faq: payload };
    case SET_EVENT_RAFFLE_FILTERS:
      return { ...state, raffleFilters: payload };
    case SET_EVENT_RAFFLE_PRIZES:
      return { ...state, rafflePrizes: payload };
    case SET_EVENT_RAFFLE_PRIZE:
      return { ...state, rafflePrize: payload };
    case SET_EVENT_RAFFLE_PRIZE_ID:
      return { ...state, rafflePrizeId: payload };
    case SET_EVENT_RAFFLE:
      return { ...state, raffle: payload };
    case SET_EVENT_RAFFLE_WINNERS:
      return { ...state, raffleWinners: payload };
    case SET_EVENT_ATTENDEE_PRESENCE:
      return { ...state, attendeePresence: payload };
    case SET_EVENT_EMAIL_TEMPLATES:
      return { ...state, emailTemplates: payload };
    default:
      return state;
  }
}
