import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import { SIGN_OUT_USER } from "../../modules/Auth/constants";

import app from "../../modules/App/reducer";
import auth from "../../modules/Auth/reducer";
import user from "../../modules/User/reducer";
import organization from "../../modules/Organization/reducer";
import event from "../../modules/Events/reducer";
import twilio from "../../modules/Twilio/reducer";
import webinar from "../../modules/Webinar/reducer";

const mainReducer = (state, action, history) => {
  const allReducers = combineReducers({
    router: connectRouter(history),
    app,
    auth,
    user,
    organization,
    event,
    twilio,
    webinar,
  });
  if (action.type === SIGN_OUT_USER) {
    state = {
      app: state.app,
    };
  }
  return allReducers(state, action);
};

export default mainReducer;
