import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getDeviceId, getFileExtension } from "..";
import { asyncActionError, asyncActionFinish, asyncActionStart } from "../../../modules/App/actions";
import firebase from "../../configs/firebase";
import cuid from "cuid";
import { toast } from "react-toastify";
import _ from "lodash";
import axios from "axios";
import FormData from "form-data";
import { firebaseConfig } from "../../configs";

export function firebaseObjectToArray(snapshot) {
  if (snapshot) {
    return Object.entries(snapshot).map((e) => Object.assign({}, e[1], { id: e[0] }));
  }
}

export async function dataFromSnapshot(snapshot, populate = []) {
  if (!snapshot.exists) return undefined;
  const data = snapshot.data();

  let _populate = populate.map((a) => a.path);

  // console.log("data", data);
  for (const prop in data) {
    if (data.hasOwnProperty(prop)) {
      if (data[prop] instanceof firebase.firestore.Timestamp) {
        data[prop] = data[prop].toDate();
      } else if (_populate.includes(prop)) {
        let _ref = populate.find((a) => a.path === prop);
        if (_ref) {
          let _get = await getFirebase().firestore().doc(data[prop]).get();
          let _tempData = _get?.data();
          if (_ref.select) {
            data[prop] = _.pick(_tempData, _ref.select.split(" "));
            data[prop].id = _get.id;
          } else {
            data[prop] = { ..._tempData, id: _get.id };
          }
          Object.keys(data[prop]).forEach((key) => {
            if (data[prop][key] instanceof firebase.firestore.Timestamp) {
              data[prop][key] = data[prop][key].toDate();
            }
          });
        }
      }
    }
  }

  return {
    ...data,
    id: snapshot.id,
  };
}

export function useFirestoreDoc({ query, data, deps = [] }, shouldExecute = true, actionStart = true) {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!shouldExecute) return;

    if (actionStart) dispatch(asyncActionStart());
    try {
      const unsubscribe = query().onSnapshot(
        async (snapshot) => {
          data(await dataFromSnapshot(snapshot));
          dispatch(asyncActionFinish());
        },
        (error) => dispatch(asyncActionError(error, "useFirestoreDoc1", query))
      );
      return () => {
        dispatch(asyncActionFinish());
        if (unsubscribe) unsubscribe();
      };
    } catch (error) {
      dispatch(asyncActionError(error, "useFirestoreDoc2", query));
    } // eslint-disable-next-line
  }, deps);
}

export function useFirestoreCollection({ query, data, deps = [], populate = [], snapshot = true }, shouldExecute = true, actionStart = true) {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!shouldExecute) return;

    if (actionStart) dispatch(asyncActionStart());
    try {
      if (snapshot) {
        const unsubscribe = query().onSnapshot(
          async (snapshot) => {
            // console.log("snapshot.docs", snapshot.docs, query);

            if (snapshot.docs.length) {
              const promises = snapshot.docs.map(async (doc) => await dataFromSnapshot(doc, populate));
              const docs = await Promise.all(promises);
              data(docs);
            } else {
              data([]);
            }
            if (actionStart) dispatch(asyncActionFinish());
          },
          (error) => dispatch(asyncActionError(error, "useFirestoreCollection1", query))
        );
        return () => {
          if (actionStart) dispatch(asyncActionFinish());
          if (unsubscribe) unsubscribe();
        };
      } else {
        query()
          .get()
          .then(
            async (snapshot) => {
              // console.log("snapshot.docs", snapshot.docs, query);

              if (snapshot.docs.length) {
                const promises = snapshot.docs.map(async (doc) => await dataFromSnapshot(doc, populate));
                const docs = await Promise.all(promises);
                data(docs);
              } else {
                data([]);
              }
              if (actionStart) dispatch(asyncActionFinish());
            },
            (error) => dispatch(asyncActionError(error, "useFirestoreCollection1", query))
          );
        return () => {
          if (actionStart) dispatch(asyncActionFinish());
        };
      }
    } catch (error) {
      dispatch(asyncActionError(error, "useFirestoreCollection2", query));
    } // eslint-disable-next-line
  }, deps);
}

export function useFirestorePresence({ event, attendee, deps = [], onOnline = () => {} }, shouldExecute = true) {
  const dispatch = useDispatch();

  let isOfflineForDatabase = {
    state: "offline",
    last_changed: firebase.database.ServerValue.TIMESTAMP,
  };

  let isOnlineForDatabase = {
    state: "online",
    last_changed: firebase.database.ServerValue.TIMESTAMP,
  };

  let isOfflineForFirestore = {
    state: "offline",
    last_changed: firebase.firestore.FieldValue.serverTimestamp(),
  };

  let isOnlineForFirestore = {
    state: "online",
    last_changed: firebase.firestore.FieldValue.serverTimestamp(),
  };

  useEffect(() => {
    console.log("shouldExecute", shouldExecute);
    if (!shouldExecute) return;

    let key = `/status/${event}/attendees/${attendee?.id}`;
    console.log("key", key);
    let userStatusDatabaseRef = firebase.database().ref(key);
    let userStatusFirestoreRef = firebase.firestore().doc(key);

    let timeout = null;

    firebase
      .database()
      .ref(".info/connected")
      .on("value", function (snapshot) {
        if (snapshot.val() === false) {
          // Instead of simply returning, we'll also set Firestore's state
          // to 'offline'. This ensures that our Firestore cache is aware
          // of the switch to 'offline.'
          userStatusFirestoreRef.set({
            ...isOfflineForFirestore,
            email: attendee?.email || "",
            displayName: attendee?.displayName || "",
            deviceId: getDeviceId(),
          });
          return;
        }

        userStatusDatabaseRef
          .onDisconnect()
          .update({
            ...isOfflineForDatabase,
            email: attendee?.email || "",
            displayName: attendee?.displayName || "",
            deviceId: getDeviceId(),
          })
          .then(function () {
            userStatusDatabaseRef.update({
              ...isOnlineForDatabase,
              email: attendee?.email || "",
              displayName: attendee?.displayName || "",
              deviceId: getDeviceId(),
            });

            // We'll also add Firestore set here for when we come online.
            userStatusFirestoreRef.update({
              ...isOnlineForFirestore,
              email: attendee?.email || "",
              displayName: attendee?.displayName || "",
              deviceId: getDeviceId(),
            });

            // update last login
            (async () => {
              let date = firebase.firestore.FieldValue.serverTimestamp();
              firebase.firestore().collection("events").doc(event).collection("attendees").doc(attendee?.id).update({ lastLogin: date, updatedAt: date });
            })();
          });
      });

    userStatusFirestoreRef.onSnapshot(function (doc) {
      if (doc && doc.data()) {
        let isOnline = doc.data().state === "online";
        // console.log("isOnline", isOnline, doc.data());
        if (isOnline) {
          if (timeout) {
            clearTimeout(timeout);
          }
          timeout = setTimeout(() => {
            onOnline({ ...doc.data(), id: doc.id });
          }, 400);
        }
      }

      // ... use isOnline
    });
  }, deps);

  const setOffline = (_event, _attendee) => {
    let key = `/status/${_event}/attendees/${_attendee?.id}`;
    console.log("setOffline key", key);
    let userStatusDatabaseRef = firebase.database().ref(key);
    let userStatusFirestoreRef = firebase.firestore().doc(key);

    userStatusFirestoreRef.update(isOfflineForFirestore);
    userStatusDatabaseRef.update(isOfflineForDatabase);
  };

  return {
    setOffline,
  };
}

export function uploadToFirebaseStorage(fbStorage = true, file, filename, onProgress, onFinished, path, fileExtension = "") {
  console.log("uploadToFirebaseStorage", file, filename, /*onProgress, onFinished,*/ path, fileExtension);
  filename = filename || cuid();
  if (fileExtension) {
    filename += fileExtension;
  } else {
    fileExtension = getFileExtension(file.name);
    if (!filename.includes(fileExtension, filename.length - fileExtension.length)) {
      filename += "." + fileExtension;
    }
  }

  if (fbStorage) {
    const user = firebase.auth().currentUser;

    const storageRef = firebase.storage().ref();
    const uploadTask = storageRef.child(`${path}/${filename}`).put(file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        // console.log("Upload is " + progress + "% done");
        if (onProgress) {
          onProgress(progress);
        }
      },
      (error) => {
        toast.error(error.message);
      },
      () => {
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          if (onFinished) {
            onFinished(downloadURL);
          }
        });
      }
    );
  } else {
    onProgress(10);
    let formData = new FormData();
    formData.append("file", file);

    onProgress(50);
    axios
      .post(`https://us-central1-${firebaseConfig.projectId}.cloudfunctions.net/uploadFile`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
        params: {
          fileName: filename,
          path,
          fileExtension,
        },
      })
      .then((response) => {
        console.log("response", response.data);
        onProgress(100);
        if (onFinished) {
          onFinished(response.data.url);
        }
      })
      .catch((error) => {
        return error;
      });
  }
}

export function uploadFile(path, file, filename) {
  const storageRef = firebase.storage().ref();
  return storageRef.child(`${path}/${filename}`).put(file);
}

export function deleteFileByUrl(url) {
  url = decodeURIComponent(url);
  firebase.storage().refFromURL(url).delete();
}

export function uploadToUserFirebaseStorage(fbStorage = true, file, filename, onProgress, onFinished, documents = true) {
  filename = filename || cuid();
  filename = filename.replace("." + getFileExtension(file.name), "");
  filename += "." + getFileExtension(file.name);
  const user = firebase.auth().currentUser;
  if (fbStorage) {
    const storageRef = firebase.storage().ref();
    const uploadTask = storageRef.child(`user_files/${user.uid}/${documents ? "documents" : "images"}/${filename}`).put(file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        // console.log("Upload is " + progress + "% done");
        if (onProgress) {
          onProgress(progress);
        }
      },
      (error) => {
        toast.error(error.message);
      },
      () => {
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          if (onFinished) {
            onFinished(downloadURL);
          }
        });
      }
    );
  } else {
    onProgress(10);
    let formData = new FormData();
    formData.append("file", file);

    onProgress(50);
    axios
      .post(`https://us-central1-${firebaseConfig.projectId}.cloudfunctions.net/uploadFile`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
        params: {
          fileName: filename,
          path: `user_files/${user.uid}/${documents ? "documents" : "images"}/${filename}`,
        },
      })
      .then((response) => {
        console.log("response", response.data);
        onProgress(100);
        if (onFinished) {
          onFinished(response.data.url);
        }
      })
      .catch((error) => {
        return error;
      });
  }
}

// auth
export function signInWithEmail(email, password) {
  return firebase.auth().signInWithEmailAndPassword(email, password);
}

export function signOutFirebase() {
  return firebase.auth().signOut();
}

export function signUpWithEmail(email, password) {
  return firebase.auth().createUserWithEmailAndPassword(email, password);
}

export function signInWithPopup(provider) {
  return firebase.auth().signInWithPopup(provider);
}

const PUSH_CHARS = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";

// Timestamp of last push, used to prevent local collisions if you push twice in one ms.
let lastPushTime = 0;

// We generate 72-bits of randomness which get turned into 12 characters and appended to the
// timestamp to prevent collisions with other clients.  We store the last characters we
// generated because in the event of a collision, we'll use those same characters except
// "incremented" by one.
const lastRandChars = [];

export function generateUid() {
  let now = new Date().getTime();
  let duplicateTime = now === lastPushTime;
  lastPushTime = now;
  let timeStampChars = new Array(8);
  for (let i = 7; i >= 0; i--) {
    timeStampChars[i] = PUSH_CHARS.charAt(now % 64);
    // NOTE: Can't use << here because javascript will convert to int and lose the upper bits.
    now = Math.floor(now / 64);
  }
  if (now !== 0) throw new Error("We should have converted the entire timestamp.");

  let id = timeStampChars.join("");

  if (!duplicateTime) {
    for (let i = 0; i < 12; i++) {
      lastRandChars[i] = Math.floor(Math.random() * 64);
    }
  } else {
    // If the timestamp hasn't changed since last push, use the same random number, except incremented by 1.
    let i;
    for (i = 11; i >= 0 && lastRandChars[i] === 63; i--) {
      lastRandChars[i] = 0;
    }
    lastRandChars[i]++;
  }
  for (let i = 0; i < 12; i++) {
    id += PUSH_CHARS.charAt(lastRandChars[i]);
  }
  let i = 0;
  while (id.length < 28) {
    id += PUSH_CHARS.charAt(lastRandChars[i]);
    i++;
  }
  // console.log("length", id.length);

  return id;
}

// export firebase
export const getFirebase = () => firebase;
