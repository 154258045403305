import React, { Suspense, useEffect, useMemo, useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./App.scss";

import CssBaseline from "@material-ui/core/CssBaseline";
import Routes from "./main/routes";
import { useDispatch, useSelector } from "react-redux";
import Loader from "./components/Loader";
import AppAlertDialog from "./components/AppAlertDialog";
import * as routes from "./main/routes/constants";
import { createTheme, ThemeProvider } from "@material-ui/core";
import qs from "query-string";
import { getCurrentIp, getDomain } from "./library/utilities";
import { setAppSettings } from "./modules/App/actions";
import { getPartners, getPartnerByDomain } from "./modules/Events/actions";

function App() {
  const { initialized, loading, showProgress, progress, alertDialog, theme, appSettings } = useSelector((state) => state.app);

  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const [checkedCustom, setCheckedCustom] = useState(false);

  const defaultTheme = createTheme({
    palette: {
      primary: {
        main: appSettings.primary || "#1155b0",
        // contrastText: "#C79248",
      },
      secondary: {
        main: appSettings.secondary || "#f1202b",
      },
    },
  });

  function getFaviconEl() {
    return document.getElementById("favicon");
  }

  useEffect(() => {
    const _getHomePage = async () => {
      let domain = getDomain(window.location.href, false);
      console.log("domain", domain);
      let partner = (await getPartnerByDomain(domain).get()).docs[0]?.data();
      console.log("partner", partner);
      if (partner) {
        document.title = partner.name;
        const favicon = getFaviconEl();
        favicon.href = partner.favicon;
        await dispatch(
          setAppSettings({
            REACT_APP_WEBSITE_NAME: partner.name,
            REACT_APP_LOGO: partner.logo,
            primary: partner.themePrimary,
            secondary: partner.themeSecondary,
            partnerName: partner.name || "",
            partnerDomain: partner.domain || domain,
          })
        );
      }
      setCheckedCustom(true);
    };
    _getHomePage();
  }, [dispatch]);

  useEffect(() => {
    if (user) {
      (async () => {
        let currentIp = await getCurrentIp();
        console.log("currentIp with user", currentIp);

        let key = `${currentIp}:${user.uid}`;
        let identity = localStorage.getItem(key);
        try {
          if (identity) {
            identity = JSON.parse(identity);
          } else {
            identity = {
              event: false,
              lobby: false,
              plenary: false,
              expo: false,
              booths: [],
              rooms: [],
              networkingRooms: [],
            };
            localStorage.setItem(key, JSON.stringify(identity));
          }
        } catch (error) {}
      })();
    }
  }, [user]);

  if (!initialized || !checkedCustom) return <Loader progress={progress} showProgress={showProgress} />;

  return (
    <ThemeProvider theme={theme || defaultTheme}>
      <Suspense fallback={<Loader progress={progress} showProgress={showProgress} />}>
        <CssBaseline />
        <ToastContainer position="bottom-center" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        <Loader open={loading} progress={progress} showProgress={showProgress} />
        <AppAlertDialog {...alertDialog} open={alertDialog !== null && !window.location.pathname.includes(routes.ORGANIZERS)} />
        <Routes />
      </Suspense>
    </ThemeProvider>
  );
}

export default App;
