import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";

import IconMenu from "@material-ui/icons/Menu";
import { useHistory } from "react-router-dom";
import * as routes from "../../main/routes/constants";
import { useSelector } from "react-redux";
import { sanitizeUrl } from "../../library/utilities";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  appLogo: {
    height: "100%",
    padding: 5,
    maxHeight: 48,
    cursor: "pointer",
  },
}));

const HomeNavbar = ({ position, className }) => {
  const classes = useStyles();
  const history = useHistory();
  const { appSettings } = useSelector((state) => state.app);

  return (
    <AppBar position={position} className={className}>
      <Toolbar variant="dense">
        {/* <IconButton
          edge="start"
          className={classes.menuButton}
          color="inherit"
          aria-label="menu"
        >
          <IconMenu />
        </IconButton> */}
        {appSettings.REACT_APP_LOGO || process.env.REACT_APP_LOGO ? (
          <img
            alt="App Logo"
            src={sanitizeUrl(appSettings.REACT_APP_LOGO || process.env.REACT_APP_LOGO)}
            className={classes.appLogo}
            onClick={() => {
              history.push(routes.ACCOUNT);
            }}
          />
        ) : (
          <Button
            color="inherit"
            onClick={() => {
              history.push(routes.ACCOUNT);
            }}
          >
            <Typography variant="h6" noWrap>
              {appSettings.REACT_APP_WEBSITE_NAME ||
                process.env.REACT_APP_WEBSITE_NAME}
            </Typography>
          </Button>
        )}
        {/* <div className={classes.title} />
        <Button color="inherit" href={routes.SIGN_IN}>
          Log In
        </Button>
        <Button color="secondary" variant="contained" href={routes.SIGN_UP}>
          Sign Up
        </Button> */}
      </Toolbar>
    </AppBar>
  );
};
export default HomeNavbar;
