export const options = [
  {
    key: "plenary",
    label: "Plenary Hall",
    value: true,
  },
  {
    key: "rooms",
    label: "Rooms",
    value: true,
  },
  {
    key: "networking",
    label: "Networking",
    value: true,
  },
  {
    key: "expo",
    label: "Expo",
    value: true,
  },
  {
    key: "arcade",
    label: "Arcade",
    value: true,
  },
];

export const categories = [
  "technology",
  "education",
  "career fair",
  "professional development",
  "health & lifestyle",
  "business & entrepreneurship",
  "marketing & advertising",
  "entertainment",
];
export const types = [
  {
    key: "public",
    label: "Public",
    description: `Available to everyone and listed in ${process.env.REACT_APP_WEBSITE_NAME}'s event marketplace`,
  },
  {
    key: "private",
    label: "Private",
    description: "Only available to people with the link and the event password.",
  },
  {
    key: "hidden",
    label: "Hidden",
    description: "Only available to people with the link.",
  },
];

export const SET_EVENTS = "SET_EVENTS";
export const SET_EVENT = "SET_EVENT";
export const SET_EVENT_ID = "SET_EVENT_ID";
export const SET_EVENT_FILES = "SET_EVENT_FILES";
export const SET_EVENT_BOOTH_FILES = "SET_EVENT_BOOTH_FILES";
export const ADD_EVENT_FILE = "ADD_EVENT_FILE";
export const SET_BOOTHS = "SET_BOOTHS";
export const SET_BOOTH = "SET_BOOTH";
export const SET_BOOTH_ID = "SET_BOOTH_ID";
export const SET_NETWORKING_ROOMS = "SET_NETWORKING_ROOMS";
export const SET_NETWORKING_ROOM = "SET_NETWORKING_ROOM";
export const SET_NETWORKING_ROOM_ID = "SET_NETWORKING_ROOM_ID";
export const SET_TICKET = "SET_TICKET";
export const SET_REGISTRATION_DETAIL = "SET_REGISTRATION_DETAIL";
export const SET_ATTENDEES = "SET_ATTENDEES";
export const SET_ATTENDEE_COMPANIONS = "SET_ATTENDEE_COMPANIONS";
export const SET_TEMP_JOIN_EVENT = "SET_TEMP_JOIN_EVENT";
export const SET_USER_EVENTS = "SET_USER_EVENTS";
export const SET_EVENT_MESSAGES = "SET_EVENT_MESSAGES";
export const SET_EVENT_PLENARY_MESSAGES = "SET_EVENT_PLENARY_MESSAGES";
export const SET_EVENT_USER_FILES = "SET_EVENT_USER_FILES";
export const SET_EVENT_USER_PASSPORT = "SET_EVENT_USER_PASSPORT";
export const SET_ATTENDEE = "SET_ATTENDEE";
export const SET_BOOTH_TEAM = "SET_BOOTH_TEAM";
export const SET_BOOTH_INVITES = "SET_BOOTH_INVITES";
export const SET_USER_BOOTH_INVITES = "SET_USER_BOOTH_INVITES";
export const SET_USER_BOOTHS = "SET_USER_BOOTHS";
export const SET_BOOTH_MESSAGES = "SET_BOOTH_MESSAGES";
export const TOGGLE_SHOW_PASSPORT = "TOGGLE_SHOW_PASSPORT";
export const SET_HELP_MESSAGES = "SET_HELP_MESSAGES";
export const TOGGLE_SHOW_CHAT = "TOGGLE_SHOW_CHAT";
export const TOGGLE_SHOW_HELP_CHAT = "TOGGLE_SHOW_HELP_CHAT";
export const SET_BOOTH_SURVEY_ANSWERS = "SET_BOOTH_SURVEY_ANSWERS";
export const SET_EVENT_NOTIFICATIONS = "SET_EVENT_NOTIFICATIONS";
export const SET_EVENT_BOOTH_NOTIFICATIONS = "SET_EVENT_BOOTH_NOTIFICATIONS";
export const TOGGLE_SHOW_EVENT_CHAT = "TOGGLE_SHOW_EVENT_CHAT";
export const SET_EVENT_CONTROLS = "SET_EVENT_CONTROLS";
export const SET_EVENT_BROADCAST_MESSAGES = "SET_EVENT_BROADCAST_MESSAGES";
export const SET_EVENT_BROADCAST_MESSAGE_TEMPLATES = "SET_EVENT_BROADCAST_MESSAGE_TEMPLATES";
export const TOGGLE_EVENT_MUSIC_MUTED = "TOGGLE_EVENT_MUSIC_MUTED";
export const SET_ROUTING_LOCKED = "SET_ROUTING_LOCKED";
export const SET_ROOMS = "SET_ROOMS";
export const SET_ROOM = "SET_ROOM";
export const SET_ROOM_ID = "SET_ROOM_ID";
export const SET_ROOM_CHILDREN = "SET_ROOM_CHILDREN";
export const SET_SURVEYS = "SET_SURVEYS";
export const SET_SURVEY_ANSWERS = "SET_SURVEY_ANSWERS";
export const SET_ON_VIDEO_CALL = "SET_ON_VIDEO_CALL";
export const SET_EVENT_ANALYTICS = "SET_EVENT_ANALYTICS";
export const SET_EVENT_ANALYTICS_BOOTH = "SET_EVENT_ANALYTICS_BOOTH";
export const SET_BOOTH_VISITORS = "SET_BOOTH_VISITORS";
export const SET_EVENT_FAQS = "SET_EVENT_FAQS";
export const SET_EVENT_FAQ = "SET_EVENT_FAQ";
export const SET_EVENT_RAFFLE_FILTERS = "SET_EVENT_RAFFLE_FILTERS";
export const SET_EVENT_RAFFLE_PRIZES = "SET_EVENT_RAFFLE_PRIZES";
export const SET_EVENT_RAFFLE_PRIZE = "SET_EVENT_RAFFLE_PRIZE";
export const SET_EVENT_RAFFLE_PRIZE_ID = "SET_EVENT_RAFFLE_PRIZE_ID";
export const SET_EVENT_RAFFLE = "SET_EVENT_RAFFLE";
export const SET_EVENT_RAFFLE_WINNERS = "SET_EVENT_RAFFLE_WINNERS";
export const SET_EVENT_ATTENDEE_PRESENCE = "SET_EVENT_ATTENDEE_PRESENCE";
export const SET_EVENT_EMAIL_TEMPLATES = "SET_EVENT_EMAIL_TEMPLATES";
