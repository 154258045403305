import { SET_PROFILE } from "./constants";

const initialState = {
  profile: null,
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case SET_PROFILE:
      return { ...state, profile: payload || null };
    default:
      return state;
  }
}
